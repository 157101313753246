import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from './directives/directives.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

const MaterialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
];

import { CovalentDataTableModule } from '@covalent/core/data-table';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentMediaModule } from '@covalent/core/media';
import { CovalentPagingModule } from '@covalent/core/paging';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentStepsModule } from '@covalent/core/steps';

const CovalentModules = [
  CovalentDataTableModule,
  CovalentDialogsModule,
  CovalentLayoutModule,
  CovalentMediaModule,
  CovalentPagingModule,
  CovalentSearchModule,
  CovalentStepsModule
];

import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { ManageFoldersDialogComponent } from './components/manage-folders-dialog/manage-folders-dialog.component';
import { MoveItemsDialogComponent } from './components/move-items-dialog/move-items-dialog.component';
import { NameDialogComponent } from './components/name-dialog/name-dialog.component';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { SwitchDialogComponent } from './components/switch-dialog/switch-dialog.component';
import { UserRoleAbilityDialogComponent } from './components/user-role-ability-dialog/user-role-ability-dialog.component';
const DIALOGS = [
  EmptyPageComponent,
  ManageFoldersDialogComponent,
  MoveItemsDialogComponent,
  NameDialogComponent,
  SelectDialogComponent,
  SwitchDialogComponent,
  UserRoleAbilityDialogComponent
];



import { CardItemActionsComponent } from './components/card-item-actions/card-item-actions.component';
import { SelectionActionsComponent } from './components/selection-actions/selection-actions.component';
const COMPONENTS = [
  CardItemActionsComponent,
  SelectionActionsComponent
]

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCanPipe } from './pipes/pure-can.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { RemainingTimePipe } from './pipes/remaining-time.pipe';
import { SortPipe } from './pipes/sort.pipe';
const PIPES = [
  CapitalizePipe, 
  CustomCanPipe, 
  MarkdownPipe,
  RemainingTimePipe, 
  SortPipe, 
];

import { environment } from '@env/environment';
import { JsonViewerModule } from '@app/feature-modules/json-viewer/json-viewer.module';

const DevToolsModules = environment.devTools ? [ JsonViewerModule ] : [];


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DirectivesModule,
    ...MaterialModules,
    ...CovalentModules,
    ...DevToolsModules
  ],
  declarations: [
    ...DIALOGS,
    ...COMPONENTS,
    ...PIPES,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CommonModule,
    ...MaterialModules,
    ...CovalentModules,
    ...DevToolsModules,
    ...DIALOGS,
    ...COMPONENTS,
    ...PIPES,
  ]
})
export class SharedModule { }
