<div mat-dialog-title>
  <span *ngIf="folderEntity === 'folder'">
    Manage {{ kind }} folders
  </span>
  <span *ngIf="folderEntity === 'category'">
    Manage snippet categories
  </span>
</div>

<mat-dialog-content>
  <div class="tc-grey-600" *ngIf="!!folders && folders.length > 0; else noFolders">
    <div class="folder" *ngFor="let folder of folders; let index = index" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <span class="space-left-10">
        <mat-icon>folder</mat-icon>
      </span>
      <mat-form-field fxFlex floatLabel="never">
        <input matInput type="text" [ngClass]="'folder' + index" placeholder="{{ folderEntity | ihCapitalize }} name" [(ngModel)]="folder.label" (ngModelChange)="validate()">
      </mat-form-field>
      <button *ngIf="folderEntity === 'folder'" mat-icon-button matTooltip="Remove folder" (click)="removeFolder(index)" [disabled]="folder.id === 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <button *ngIf="folderEntity === 'category'" mat-icon-button matTooltip="Remove category" (click)="removeCategory(index)">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>

    <!-- <p class="header mat-caption"> -->
    <p class="mat-caption">
      <strong>Note:</strong>
      <span *ngIf="folderEntity === 'folder'">
        If you delete a folder all {{ kind }}s in that folder will be moved to the <strong>'{{ unfiledFolder?.label || 'Unfiled'}}'</strong> folder. They will <strong>not</strong> be deleted.
      </span>
      <span *ngIf="folderEntity === 'category'">
        If you delete a category all the snippets in that category will become <strong>'Uncategorized'</strong>. They will <strong>not</strong> be deleted.
      </span>
    </p>
  </div>
  
  <ng-template #noFolders>
    <p class="header tc-grey-600">
      <span *ngIf="folderEntity === 'folder'">
        Keep your {{ kind }}s organized with "folders". Create your first folder here.
      </span>
      <span *ngIf="folderEntity === 'category'">
        Keep your snippets organized with "categories". Create your first category here.
      </span>
      </p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
  <button *ngIf="folderEntity === 'folder'" mat-button matTooltip="New folder" (click)="addFolder()">
    <mat-icon>add_circle</mat-icon>
    <span class="space-left-5">New folder</span>
  </button>
  <button *ngIf="folderEntity === 'category'" mat-button matTooltip="New category" (click)="addCategory()">
    <mat-icon>add_circle</mat-icon>
    <span class="space-left-5">New category</span>
  </button>
  <div fxFlex></div>
  <button mat-button (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="update()" [disabled]="!validFolders">
    Update {{ folderEntity === 'folder' ? 'folders' : 'categories' }}
  </button>
</mat-dialog-actions>