<div mat-dialog-title>
  Forgot your password?
</div>

<mat-dialog-content class="no-actions">
  <p class="mat-body-1 space-bottom-30">
    Yep! It happens to all of us. If you forgot your password please request a password reset here. You will then receive an email with a 
      <em>temporary password</em> which you can use to log in once and create a new safe password.
  </p>

  <div fxLayout="column" fxLayoutAlign="start center">
    <form class="full-width" #userForm="ngForm">
      <mat-form-field class="full-width">
        <input matInput placeholder="Email" type="email" name="email" [(ngModel)]="email" [pattern]="emailPattern" #emailInput="ngModel" required>
        <mat-hint align="end">
          <span class="tc-red-600">
            <span [hidden]="emailInput.pristine || !emailInput.errors?.required">
              Email is required
            </span>
            <span [hidden]="emailInput.pristine || !emailInput.errors?.pattern">
              Email must be valid
            </span>
          </span>
        </mat-hint>
      </mat-form-field>
    </form>
    <button class="full-width" color="primary" mat-raised-button (click)="requestPasswordReset()" [disabled]="!userForm.valid">
      Reset Password
    </button>
  </div>
</mat-dialog-content>
