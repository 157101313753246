<div class="text-center item-actions">
  <span *ngIf="selectionOnly">
    <button class="select-button" mat-raised-button matTooltip="Select {{itemType}}" (click)="select()">Select</button>
  </span>

  <span *ngIf="trashMode">
    <button class="restore-button" mat-icon-button matTooltip="Restore {{itemType}}" (click)="restore()">
      <mat-icon>open_in_browser</mat-icon>
    </button>
    <button class="delete-button" mat-icon-button matTooltip="Delete {{itemType}}" (click)="delete()">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </span>

  <span *ngIf="!trashMode && !selectionOnly">
    <button class="focus-button" mat-icon-button matTooltip="Select {{itemType}}" (click)="focus()">
      <ng-container [ngSwitch]="item?.selected == true">
        <mat-icon *ngSwitchCase="true">check_box</mat-icon>
        <mat-icon *ngSwitchCase="false">check_box_outline_blank</mat-icon>
      </ng-container>
    </button>
    <button class="edit-button" mat-icon-button matTooltip="Edit {{itemType}}" (click)="edit()">
      <mat-icon>mode_edit</mat-icon>
    </button>
    <button class="copy-button" mat-icon-button matTooltip="Copy {{itemType}}" (click)="copy()">
      <mat-icon>content_copy</mat-icon>
    </button>
    <button class="delete-button" mat-icon-button matTooltip="Remove {{itemType}}" (click)="delete()">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</div>