import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { Ability } from '@casl/ability';

@Pipe({name: 'can'})
export class CustomCanPipe implements PipeTransform {
  constructor(
    protected ability: Ability
  ) { }
    
  transform(subject: string, action: 'none' | 'view' | 'create' | 'manage'): any {
    return this.ability.can(action, subject);
  }
}
