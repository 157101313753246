import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, from as observableFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppStateService } from '@app/app.service';
import { LoggerService } from '@core/services/logger.service';

import { ViewConfig, IEmailConfig, ISmsConfig } from '@shared/models/view-config.model';
import { FlowNode } from '@shared/models/node.model';
import { LOADERS } from '@shared/models/loaders.model';

export interface IIngredientIds {
  subject?: number[];
  preheader?: number[];
  from?: number[];
  to?: number[];
  body?: number[];
  filename?: number[];
  sync?: number[];
}

export interface IIngredients {
  subject?: IIngredient[];
  preheader?: IIngredient[];
  from?: IIngredient[];
  to?: IIngredient[];
  body?: IIngredient[];
  filename?: IIngredient[];
  sync?: IIngredient[];
}

export interface IIngredient {
  id?: any;
  campaign_id?: number;
  node_id?: any;
  created_at?: Date;
  created_by?: string;
  modified_at?: Date;
  modified_by?: string;
  kind: string;
  content?: IIngredientContent;
  autosave?: {json: any, time: string};
}

export interface IIngredientContent {
  name?: string;
  value?: string;
  type?: string;
  email?: string;
  string?: string;
  html?: string;
  json?: string | object;
  thumb?: string;
}

export interface IIngredientPatch {
  op: 'create' | 'update' | 'delete',
  ingredient: IIngredient
}


@Injectable()
export class IngredientsService implements OnDestroy {
  private endpoint: string = '/ingredients';

  private ingredientsMap: any = {};
  private _ingredientsMap$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  private nodeSubscriptions: any[] = [];

  private emailConfig: IEmailConfig;
  private smsConfig: ISmsConfig;

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private logger: LoggerService
  ) {
    this.subscribeToEmailAndSms();
  }


  // NODE INGREDIENTS RETRIEVALE FOR FLOW TREE NODES ------------------------------------

  /**
   * Obtain all ingredients for particular node id
   * @param fn
   * @param nodeId
   */
  getNodeIngredients(fn, nodeId) {
    const sub = this._ingredientsMap$
      .subscribe((ingredients) => {
        if (ingredients) {
          fn(ingredients[nodeId]);
        } else {
          fn(undefined);
        }
      });
    this.nodeSubscriptions.push(sub);
  }

  /**
   * Load all ingredients for particular node
   * Organize ingredients according to node content kinds (e.g. body: [], from: [])
   * @param node
   */
  loadNodeIngredients(node: FlowNode) {
    this.logger.log('Load ingredients for node', node.id);
    const loader = this.state.registerLoader(LOADERS.nodes, node.id);

    const requestBatch: any[] = [];
    const kinds: string[] = Object.keys(node.content.ingredient_ids);

    const nodeIngredients: IIngredients = {};

    // prepare load requests in accordance with node's ingredient Ids
    kinds.forEach((kind: string) => {
      nodeIngredients[kind] = [];
      node.content.ingredient_ids[kind].forEach((ingredientId: number) => {
        requestBatch.push( this.loadRequest(ingredientId) );
      });
    });

    // only load ingredients if there are requests
    if (requestBatch.length > 0) {

      // load and organize result according to node content kinds
      const sub = forkJoin(requestBatch).subscribe(
        (ingredients: IIngredient[]) => {
          console.log('Ingredients result', ingredients);

          ingredients.forEach((ingredient: IIngredient) => {
            if (ingredient.kind) {
              nodeIngredients[ingredient.kind].push(ingredient);
            }
          });

          // push new set of ingrededients to to map
          this.ingredientsMap[node.id] = nodeIngredients;
          this._ingredientsMap$.next(this.ingredientsMap);

          this.state.resolveLoader(loader, node.id);
        });

      this.nodeSubscriptions.push(sub);
    } else {
      this.state.resolveLoader(loader, node.id);
    }
  }


  // HANDLE NODE INGREDIENTS -----------------------------

  /**
   * Keep first ingredient of each kind.
   * Delete the rest
   */
  resetNodeIngredients(node: FlowNode): void {
    const kinds = Object.keys(this.ingredientsMap[node.id]);

    kinds.forEach((kind: string) => {
      const excessIngredients: IIngredient[] = this.ingredientsMap[node.id][kind].splice(1);
      if (excessIngredients.length > 0) {
        excessIngredients.forEach((ingredient: IIngredient) => {
          this.httpClient
            .delete(`${this.endpoint}/${ingredient.id}`)
            .subscribe(
              () => this.logger.log(ingredient.id, `${ingredient.kind} deleted`),
              (error: any) => this.logger.handleError('ingredient delete', error)
            );
        });
      }
    });

    this._ingredientsMap$.next(this.ingredientsMap);
  }


  /**
   * Creates and saves a new set of ingredients for a Node
   * and maps them to the Node in the ingredientsMap
   */
  freshIngredients(node: FlowNode): Observable<IIngredient[]> {
    this.logger.log('Fresh node ingredients according to channel', node.content.channel);
    const ingredients: IIngredients = node.content.freshIngredients();

    // map fresh (empty) ingredients to node
    this.ingredientsMap[node.id] = ingredients;
    this._ingredientsMap$.next(this.ingredientsMap);

    if (node.content.channel !== 'facebook') {
      const kinds: string[] = Object.keys(ingredients);
      const requestBatch: any[] = kinds.map(kind => this.saveRequest(this.newIngredient(node, kind)));
  
      return forkJoin(requestBatch).pipe(map(
        (ingredients: IIngredient[]) => {
          console.log(this.ingredientsMap);
          this._ingredientsMap$.next(this.ingredientsMap);
          return ingredients;
        }
      ));

    } else {
      return observableFrom([]);
    }
  }

  /**
   * Returns new "empty" ingredient
   * @param node Receiving node
   * @param kind of ingredient to be returned
   */
  newIngredient(node: FlowNode, kind: string): IIngredient {
    const channel: string = node.content.channel;
    const key: string = `${channel}_${kind}`;

    const ingredient: IIngredient = {
      node_id: node.id,
      kind: kind,
      content: undefined
    };

    switch (key) {
      case 'email_from':
      case 'internal_email_from':
        ingredient.content = this.getDefaultEmailSender();
        break;
      case 'internal_email_to':
        ingredient.content = { name: '', type: '', value: '' };
        break;
      case 'email_body':
      case 'internal_email_body':
        ingredient.content = { html: '', json: ''};
        break;
      case 'sms_from':
        ingredient.content = this.getDefaultSmsSender();
        break;
      case 'email_subject':
      case 'email_preheader':
      case 'internal_email_subject':
      case 'internal_email_preheader':
      case 'sms_body':
      case 'list_filename':
        ingredient.content = { string: '' };
        break;
      case 'facebook_sync':
        ingredient.content = { name: '', type: '', value: '' };
        break;
      default:
        return undefined;
    }

    return ingredient;
  }

  getDefaultEmailSender() {
    return {
      name: this.emailConfig ? this.emailConfig.from_name : '',
      email: this.emailConfig ? this.emailConfig.from_email : ''
    }
  }

  getDefaultSmsSender() {
    return {
      string: this.smsConfig ? this.smsConfig.sender_name : ''
    }
  }


  // INGREDIENT OPERATIONS ----------------------------------------

  /**
   * Remove (delete) all node ingredients
   * @param node
   * @param partial keep an empty object for each deleted ingredient kind
   */
  removeCollection(node: FlowNode, partial: boolean = true) {
    const sub = this.deleteAllRequest(node.id).subscribe(response => {
      this.logger.log('response: delete all node ingredients', response);

      if (partial && node.content) {
        const kinds: string[] = Object.keys(node.content.ingredient_ids);
        kinds.forEach((kind: string) => {
          this.ingredientsMap[node.id][kind] = [];
        });
      }
    });

    this.nodeSubscriptions.push(sub);
  }

  /**
   * Deletes all node ingredients from the node and all associated child nodes
   * @param node
   */
  removeCollectionRecursively(node: FlowNode) {
    this.removeCollection(node, false);
    if (node.nodes && node.nodes.length > 0) {
      node.nodes.forEach(childNode => this.removeCollectionRecursively(childNode));
    }
  }


  loadRequest(ingredientId: number) {
    return this.httpClient
      .get(`${this.endpoint}/${ingredientId}`)
      .pipe(
        catchError(error => this.logger.handleError('ingredient load', error))
      );
  }

  saveRequest(ingredient: IIngredient): Observable<IIngredient> {
    const focusIngredients = this.ingredientsMap[ingredient.node_id][ingredient.kind];

    return this.httpClient
      .post<IIngredient>(this.endpoint, ingredient)
      .pipe(
        map(savedIngredient => {
          focusIngredients.push(savedIngredient);
          return savedIngredient;
        }),
        catchError(error => this.logger.handleError('ingredient save', error))
      );
  }

  updateRequest(ingredient: IIngredient) {
    const focusIngredients = this.ingredientsMap[ingredient.node_id][ingredient.kind];
    const index = focusIngredients.findIndex(item => item.id === ingredient.id);
    
    return this.httpClient
      .put(`${this.endpoint}/${ingredient.id}`, ingredient).pipe(
        map(updatedIngredient => {
          if (index > -1) {
            focusIngredients[index] = updatedIngredient;
          }
          return updatedIngredient;
        }),
        catchError(error => this.logger.handleError('ingredient update', error))
      );
  }

  deleteRequest(ingredient: IIngredient) {
    const focusIngredients = this.ingredientsMap[ingredient.node_id][ingredient.kind];
    const index = focusIngredients.findIndex(item => item.id === ingredient.id);

    return this.httpClient
      .delete(`${this.endpoint}/${ingredient.id}`).pipe(
        map(() => delete focusIngredients[index]),
        catchError(error => this.logger.handleError('ingredient delete', error))
      );
  }

  deleteAllRequest(nodeId: string) {
    return this.httpClient
      .delete(`${this.endpoint}/0?node_id=${nodeId}`).pipe(
        catchError(error => this.logger.handleError('ingredients delete', error))
      );
  }

  patchRequest(ingredientId: number, updates: any): Observable<IIngredient> {
    return this.httpClient
      .patch<IIngredient>(`${this.endpoint}/${ingredientId}`, updates).pipe(
        catchError(error => this.logger.handleError('ingredient patch', error))
      );
  }

  patch(ingredientId: number, updates: any) {
    this.patchRequest(ingredientId, updates)
      .subscribe((ingredient: IIngredient) => {
        const focusIngredients = this.ingredientsMap[ingredient.node_id][ingredient.kind];
        const index = focusIngredients.findIndex(item => item.id === ingredient.id);
        if (index > -1) {
          focusIngredients[index] = ingredient;
        }
      });
  }


  /**
   * Add new ingredient to node (nodeIngredients)
   * @param node
   * @param kind
   */
  addIngredient(node: FlowNode, kind: string): void {
    const ingredient: IIngredient = this.newIngredient(node, kind);

    this.httpClient
      .post(this.endpoint, ingredient)
      .subscribe(
        (savedIngredient: IIngredient) => {
          this.ingredientsMap[node.id][kind].push(savedIngredient);
          this._ingredientsMap$.next(this.ingredientsMap);
        },
        (error: any) => this.logger.handleError('ingredient add', error)
      );
  }

  /**
   * Remove ingredient from node (nodeIngredients)
   * @param kind e.g. 'subject', 'from', 'body', etc.
   */
  removeIngredient(node: FlowNode, kind: string) {
    const ingredient = this.ingredientsMap[node.id][kind].pop();

    this.httpClient
      .delete(`${this.endpoint}/${ingredient.id}`)
      .subscribe(
        () => this.logger.log(ingredient.id, `${ingredient.kind} deleted`),
        (error: any) => this.logger.handleError('ingredient delete', error)
      );
    this._ingredientsMap$.next(this.ingredientsMap);
  }


  bunchIngredientChanges(nodeId: string, kind: string, patches: IIngredientPatch[]): Observable<IIngredient[]> {
    if (patches.length > 0) {
      const focusIngredients = this.ingredientsMap[nodeId][kind];

      const requestBatch = patches.map(patch => {
        switch (patch.op) {
          case 'create': return this.saveRequest(patch.ingredient);
          case 'update': return this.updateRequest(patch.ingredient);
          case 'delete': return this.deleteRequest(patch.ingredient);
        }
      })
  
      return forkJoin(requestBatch).pipe(
        map(() => {
          this.ingredientsMap[nodeId][kind] = focusIngredients.filter(ingredient => !!ingredient);
          this._ingredientsMap$.next(this.ingredientsMap);
          return this.ingredientsMap[nodeId][kind];
        })
      );

    } else {
      return observableFrom([this.ingredientsMap[nodeId][kind]]);
    }
  }


  subscribeToEmailAndSms() {
    this.state.state$('view_config')
      .subscribe((viewConfig: ViewConfig) => {
        if (viewConfig) {
          this.emailConfig = viewConfig.email;
          this.smsConfig = viewConfig.sms;
        }
      });
  }

  unsubscribeFromNodeIngredients() {
    this.logger.log('Unsubscribe from all node ingredients');
    this.nodeSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnDestroy(): void {
    this.unsubscribeFromNodeIngredients();
  }

}
