import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CategoryConfig, FilterConfig } from '@shared/models/filters-selector-config.model';


export function getAdminHeaders(dev: boolean) {
  return { headers: new HttpHeaders({'usergroup': dev ? 'admin_dev' : 'admin', 'view': 'all'}) };
}


/**
 * returns either 'organizations' or 'contacts' if included in url
 */
export function getActiveSubsection(router: Router): 'contacts'|'organizations'|'products' {
  switch (true) {
    case router.url.includes('profiles/contacts'): return 'contacts';
    case router.url.includes('profiles/organizations'): return 'organizations';
    case router.url.includes('products'): return 'products';
  }
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


export function parseDate(dateString: string) {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);
  if (date.valueOf()) {
    return date;
  }
  const parts = dateString.split(/[- : . +]/).map(part => Number.parseInt(part, 10));
  return new Date(parts[0], parts[1], parts[2], parts[3], parts[4], parts[5]);
}


export function findRecursively(data: any[], value: any, field: string = 'id', childrenProp: string = 'children') {
  function iter(item) {
    if (item[field] === value) {
        result = item;
        return true;
    }
    return Array.isArray(item[childrenProp]) && item[childrenProp].some(iter);
  }

  let result;
  data.some(iter);
  return result;
}

export function mapRecursively(data: any, field: string, childrenProp: string = 'children', result: any = []) {
  result = [...result, ...data.map(item => item[field])];
  data.forEach(item => {
    result = [
      ...result,
      ...(Array.isArray(item[childrenProp] && item[childrenProp].length > 0)
        ? mapRecursively(item[childrenProp], field, childrenProp, result) : [])
    ];
  });
  return result;
}



export const filterRecursively = (data: any[], predicate, childrenProp: string = 'children') => data.reduce((a, o) => {
  // if predicate fails don't include the object and it's children
  if (!predicate(o)) {
    return a;
  }

  const obj = Array.isArray(o[childrenProp]) ? // if there is a sub array
    Object.assign({}, o, { // create a new object from the original properties with the filtered sub array
      [childrenProp]: filterRecursively(o[childrenProp],  predicate, childrenProp)
    })
    :
    o; // or use the original object

  a.push(obj);

  return a;
}, []);


export function mapCategoryConfig(categoryConfig): Map<string, CategoryConfig> {
  const categoryConfigMap = new Map<string, CategoryConfig>();

  for (let [key, config] of Object.entries(categoryConfig)) {
    categoryConfigMap.set(key, new CategoryConfig(config, key));
  }

  return categoryConfigMap;
}


export function mapFilterConfig(location: string, filterConfig): Map<string, FilterConfig> {
  const filterConfigMap = new Map<string, FilterConfig>();

  for (let [key, config] of Object.entries(filterConfig)) {
    const filter = new FilterConfig(config, key, location);
    filterConfigMap.set(key, filter);
  }

  return filterConfigMap;
}


// deprecated
// keep if we should use lists in configs instead of keys
export function getFilterConfigMap(filterConfigs: FilterConfig[]): Map<string, FilterConfig> {
  const map = new Map<string, FilterConfig>();
  filterConfigs.forEach(item => map.set(item.name, new FilterConfig(item)));
  return map;
}

// deprecated
// keep if we should use lists in configs instead of keys
export function getCategoryConfigMap(categoryConfigs: CategoryConfig[]): Map<string, CategoryConfig> {
  const map = new Map<string, CategoryConfig>();
  categoryConfigs.forEach(item => map.set(item.name, new CategoryConfig(item)));
  return map;
}


// builds condition which adheres sparkpost syntax and is ready to use in email editor
export function editorCondition(kind: string, field: string, operator: any, value: any): string {
  switch (kind) {
    case 'category': {
      switch (operator) {
        case 'any': return value.map(category => `${field} == "${category}"`).join(' or ');
        case '!any': return value.map(category => `${field} != "${category}"`).join(' and ');
        default: return '';
      }
    }

    case 'number': {
      switch (operator) {
        case 'between': return `${field} >= ${value.min} and ${field} <= ${value.max}`;
        case '!between': return `${field} < ${value.min} or ${field} > ${value.max}`;
        default: return `${field} ${operator} ${value.min}`;
      }
    }

    case 'date': {
      switch (operator.value) {
        case 'between': return `${field} >= ${value.min} and ${field} <= ${value.max}`;
        case '!between': return `${field} < ${value.min} or ${field} > ${value.max}`;
        default: return `${field} ${operator.value} ${value.min}`;
      }
    }

    case 'range': {
      return `${field} >= ${value.min} and ${field} <= ${value.max}`;
    }

    case 'string': {
      return `${field} ${operator} "${value}"`;
    }

    default: {
      const stringOperator: string = (typeof operator === 'object') ? JSON.stringify(operator) : operator;
      const stringValue: string = (typeof value === 'object')
        ? JSON.stringify(value)
        : (typeof value === 'string') ? `"${value}"` : value;
      return `${field} ${stringOperator} ${stringValue}`;
    }
  }
}
