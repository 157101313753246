import { BEE_PLUGIN_EMPTY_JSON } from './template.model';

interface IInitialize<T> {
  initialize(input: any): T;
}

export type HtmlSnippetKind = 'email'|'webpage';

export class HtmlSnippet implements IInitialize<HtmlSnippet> {
  id: number;
  view_id: string;

  kind: HtmlSnippetKind;
  category_id: string;
  thumbnail: string;
  html: string;
  json: HtmlSnippetJson;
  page_json: any;

  selected?: boolean;
  deleted?: boolean;
  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  get name(): string {
    return (!!this.json && !!this.json.metadata) ? this.json.metadata.name : '';
  }

  initialize(input): HtmlSnippet {
    input = input || {};

    if (input && input.id) {
      this.id = input.id;
      this.view_id = input.view_id;
    }

    this.kind = input.kind;
    this.category_id = input.category_id;
    this.thumbnail = input.thumbnail || '';
    this.html = input.html || '<!DOCTYPE HTML><html></html>';
    this.json = input.json || this.getEmptyJson('bee');

    // After backend property renaming replace it with single row below
    const page_json = (!!input.page_json && Object.keys(input.page_json).length > 0)
      ? input.page_json
      : input.partial_json;
    this.page_json = page_json || this.getEmptyPageJson('bee');
    // this.page_json = input.page_json || this.getEmptyJson('bee');

    if (input.created_at) {
      this.created_at = input.created_at;
    }
    if (input.created_by) {
      this.created_by = input.created_by;
    }
    if (input.modified_at) {
      this.modified_at = input.modified_at;
    }
    if (input.modified_by) {
      this.modified_by = input.modified_by;
    }

    return this;
  }

  private getEmptyJson(type: string): HtmlSnippetJson {
    switch (type) {
      case 'bee':
        const row = BEE_PLUGIN_EMPTY_JSON.page.rows[0];
        const rowCopy = JSON.parse(JSON.stringify(row));
        return Object.assign(rowCopy, { metadata: { id: undefined, name: 'New Snippet', category_id: undefined }});
      default: 
        return { metadata: { id: undefined, name: 'New Snippet', category_id: undefined }};
    }
  }

  private getEmptyPageJson(type: string) {
    switch (type) {
      case 'bee':
        return JSON.parse(JSON.stringify(BEE_PLUGIN_EMPTY_JSON));
      default: 
        return {};
    }
  }
}

export interface HtmlSnippetJson {
  metadata: HtmlSnippetMeta;
  [key: string]: any;
}

/**
 * This object is part of the html snippet's "json" property
 */
export interface HtmlSnippetMeta {
  id: number;
  name: string;
  category_id: any;
  tags?: string[];
}
