import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

import { AppStateService } from '@app/app.service';
import { LoggerService } from '@core/services/logger.service';
import { NotificationService } from '@core/services/notification.service';
import { LOADERS } from '@shared/models/loaders.model';
import { User } from '@shared/models/user';


@Injectable()
export class UsersService {
  private endpoint: string = '/users';

  private _users: User[] = [];

  private _user$ = new Subject<User>();
  private _users$ = new Subject<User[]>();

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private logger: LoggerService,
    private notify: NotificationService,
    private router: Router
  ) { }

  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  get users$(): Observable<User[]> {
    return this._users$.asObservable();
  }


  loadAll(emails: string[] = []) {
    const usersLoader = this.state.registerLoader(LOADERS.users);
    this.httpClient.get( this.endpoint )
      .subscribe(
        (users: User[]) => {
          const requestedUsers = (emails.length > 0)
            ? users.filter(user => emails.indexOf(user.email) > -1 )
            : users;

          this._users = requestedUsers.map(user => new User(user));
          this._users$.next(this._users);
        },
        (error) => this.logger.handleError('users load', error),
        () => this.state.resolveLoader(usersLoader)
      );
  }

  loadViewMembers$(usergroup_id: string, view_id: string): Observable<User[]> {
    const ids = JSON.stringify({ usergroup: usergroup_id, view: view_id });
    const queryParams = new HttpParams().append('view_members', ids);
    return this.httpClient.get<User[]>(this.endpoint, { params: queryParams }).pipe(
      skipWhile(users => !users),
      map((users: any[]) => users.map(user => new User(user)))
    );
  }

  
  loadCurrentViewMembers$() {
    const url = `${this.endpoint}/current-account-members`;
    return this.httpClient.get<User[]>(url).pipe(
      skipWhile(users => !users),
      map((users: any[]) => users.map(user => new User(user)))
    );

  }

  load(userId: number) {
    const userLoader = this.state.registerLoader(LOADERS.user);
    this.httpClient.get(`${this.endpoint}/${userId}`)
      .subscribe(
        (user: User) => {
          this.logger.log('loaded user', user);
          this._user$.next(new User(user));
        },
        (error) => this.logger.handleError('user load', error),
        () => this.state.resolveLoader(userLoader)
      );
  }

  getAll(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.endpoint}`).pipe(
      catchError(error => this.logger.handleError('users get', error))
    );
  }

  getUserByEmail(email: string) {
    return this.httpClient.get<User>(`${this.endpoint}?email=${email}`).pipe(
      catchError(error => this.logger.handleError('user get', error))
    );
  }

  save$(user: User) {
    return this.httpClient.post(`${this.endpoint}`, user);
  }

  save(user: User, closeBuilder: boolean = false) {
    const loader = this.state.registerLoader(LOADERS.user);
    return this.httpClient.post(`${this.endpoint}`, user)
      .subscribe(
        (savedUser: User) => {
          this._users.push(savedUser);
          this._users$.next(this._users);

          if (savedUser.status.received_welcome === true) {
            this.notify.success('User was created');
          } else {
            this.notify.info('User was created but notification email could not be sent');
          }
          closeBuilder ? this.closeBuilder() : this.edit(savedUser.id);
        },
        (error: any) => this.logger.handleError('user save', error),
        () => this.state.resolveLoader(loader)
      );
  }

  edit(id?: number) {
    this.router.navigate([id ? `/admin/users/editor/${id}` : `/admin/users/new`]);
  }

  update(user: User, message: string = '', closeBuilder: boolean = false) {
    const loader = this.state.registerLoader(LOADERS.user);
    return this.httpClient.put(`${this.endpoint}/${user.id.toString()}`, user)
      .subscribe(
        (updatedUser: User) => {
          this.logger.log('updated user', updatedUser);

          const index = this._users.findIndex(item => item.id === updatedUser.id);
          index >= 0
            ? this._users[index] = new User(updatedUser)
            : this._users.push(new User(updatedUser));
          this._users$.next(this._users);
          this.notify.success(message);
        },
        (error: any) => this.logger.handleError('user update', error),
        () => {
          this.state.resolveLoader(loader);
          if (closeBuilder) {
            this.closeBuilder();
          }
        }
      );
  }

  closeBuilder() {
    this.router.navigate([`/admin/users`]);
  }

  patch$(id: number, updates: any): Observable<User> {
    return this.httpClient
      .patch(`${this.endpoint}/${id}`, updates).pipe(
        catchError(error => this.logger.handleError('user patch', error)),
        map(user => new User(user))
      );
  }

  delete(id: number, message: string = '') {
    const loader = this.state.registerLoader(LOADERS.deleteUser);
    return this.httpClient.delete(`${this.endpoint}/${id}`)
      .subscribe(
        () => {
          const index = this._users.findIndex(item => item.id === id);
          if (index >= 0) {
            this._users.splice(index, 1);
          }
          this._users$.next(this._users);
          this.notify.success(message);
        },
        (error: any) => this.logger.handleError('user delete', error),
        () => this.state.resolveLoader(loader)
      );
  }

}
