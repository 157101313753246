<div class="empty-page" [ngSwitch]="mode">
  <div class="text-center" *ngSwitchCase="'default'">
    <div class="mat-title">{{ header }}</div>
    <div>{{ explanation }}</div>
  </div>

  <ng-container *ngSwitchCase="'list'">
    <div *ngIf="listLength > 0" class="text-center">
      <div class="mat-title">No {{ listItem }}s match your filter criteria</div>
      <div>Change or remove filters to see the existing {{ listItem }}s</div>
    </div>
    <div *ngIf="listLength === 0" class="text-center">
      <div class="mat-title">You have no {{ listItem }}s</div>
      <div *ngIf="!listCreation">Press "Create {{ listItem }}" button to build the first one</div>
      <div *ngIf="listCreation">{{ listCreation }}</div>
    </div>
  </ng-container>

  <span *ngSwitchCase="'loading'">
    <i class="fa fa-spinner fa-pulse space-right-15"></i>
    Loading {{ loading }}...
  </span>
</div>
