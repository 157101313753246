import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[ihTemplateBind]'
})
export class TemplateBindDirective {
  @Input() id: string;

  constructor(
    public ref: TemplateRef<any>
  ) { }
}
