import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'ihRemainingTime'})
export class RemainingTimePipe implements PipeTransform {

  transform(value: number) {
    const s = Math.floor((value / 1000) % 60);
    const m = Math.floor((value / (1000 * 60)) % 60);
    const h = Math.floor((value / (1000 * 60 * 60)) % 24);
    const d = Math.floor(value / (1000 * 24 * 60 * 60));
    return d > 0
      ? `${d}day${d % 10 === 1 && d !== 11 ? '' : 's'} ${h}h ${m}m ${s}s`
      : (h > 0 ? `${h}h ${m}m ${s}s` : `${m}m ${s}s`);
  }

}
