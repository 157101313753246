import { Subject } from 'rxjs';
import { ViewConfigService } from '@core/services/view-config.service';
import { MainModelTypes } from '@shared/models/index';
import { ViewConfig, Folder, SnippetCategoryConfig, ItemGroup } from '@shared/models/view-config.model';
import { AppStateService } from '@app/app.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import * as crypto from 'randombytes';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'ih-move-items-dialog',
  templateUrl: './move-items-dialog.component.html',
  styleUrls: ['./move-items-dialog.component.sass']
})
export class MoveItemsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('foldersList') foldersList: MatSelectionList;

  kind: MainModelTypes;
  view: ViewConfig;
  folderEntity: 'folder' | 'category';
  newFolder = { selected: false, label: '' };
  folders: ItemGroup[];
  selectedId: number;
  canSelect: boolean = false;

  private unsubscribe$ = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { kind: MainModelTypes, listLength: number },
    public dialogRef: MatDialogRef<MoveItemsDialogComponent>,
    private state: AppStateService,
    private viewConfigService: ViewConfigService
  ) { }


  getFolders(): ItemGroup[] {
    if (this.folderEntity == 'folder') {
      return this.view.getFolders(this.kind);
    } else {
      const snippetType = this.kind.replace('_snippet', '');
      return (this.view.editor.snippetCategories)
        ? this.view.editor.snippetCategories.filter(category => category.kind == snippetType)
        : [];
    }
  }


  toggleNewFolder() {
    this.newFolder.selected = !this.newFolder.selected;
    if (this.newFolder.selected) {
      this.validateFolder(false);
    } else {
      this.folders = this.getFolders();
      this.validateFolder(true);
    }
  }

  /**
   * Deselect all other list items if current list item is selected
   * @param event
   */
  updateSelection(event: MatSelectionListChange) {
    if (event.option.selected) {
      event.source.deselectAll();
      event.option._setSelected(true);
      this.selectedId = event.option.value;
    } else {
      this.selectedId = undefined;
    }
    console.log('US', event);
    console.log('SI', this.selectedId);

    this.validateFolder(true);
  }

  validateFolder(existing: boolean) {
    if (existing) {
      // Set short timeout to allow rendering of list
      setTimeout(() => {
        this.canSelect = (
          this.foldersList.selectedOptions.selected.length === 1 &&
          this.selectedId !== undefined
        );
      }, 1);

    } else {
      this.canSelect = this.newFolder.label.length > 0;
    }
  }


  createFolder(): number {
    const folder: Folder = {
      id: parseInt(crypto(5).join(''), 10),
      kind: this.kind,
      label: this.newFolder.label
    };
    this.view.folders.push(folder);
    this.viewConfigService.update(this.view);

    return folder.id;
  }

  createCategory(): string {
    if (!this.view.editor.snippetCategories) {
      this.view.editor.snippetCategories = [];
    }
    const categories: SnippetCategoryConfig[] = this.view.editor.snippetCategories;
    const category: SnippetCategoryConfig = {
      id: this.uniqueCategoryId(this.newFolder.label, categories),
      kind: (this.kind.replace('_snippet', '') === 'email') ? 'email' : 'webpage',
      label: this.newFolder.label
    };
    categories.push(category);
    this.viewConfigService.update(this.view);

    return category.id;
  }

  uniqueCategoryId(label: string, categories: SnippetCategoryConfig[]) {
    let uniqueId = label.toLowerCase().replace(' ', '_');
    for (let i = 1; categories.findIndex(category => category.id === uniqueId) > -1; i++) {
      uniqueId = label.toLowerCase().replace(' ', '_') + `_${i}`;
    }
    return uniqueId;
  }


  cancel() {
    this.dialogRef.close();
  }

  move() {
    const folderId = this.newFolder.selected
      ? (this.folderEntity == 'folder' ? this.createFolder() : this.createCategory())
      : this.selectedId;

    this.dialogRef.close(folderId);
  }


  subscribeToFolders() {
    this.state.getStoreSubject().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(store => {
      this.view = store['AppState'].view_config;
      this.folders = this.getFolders();
    });
  }

  ngOnInit() {
    this.kind = this.data.kind;
    this.folderEntity = (this.kind == 'email_snippet') ? 'category' : 'folder';
    this.subscribeToFolders();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
