
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppStateService } from '@app/app.service';
import { LoggerService } from '@core/services/logger.service';
import { NotificationService } from '@core/services/notification.service';
// import { ViewConfig, IViewConfigIds } from '@shared/models/view-config.model';
import { ViewConfig, IViewConfigIds } from './../../shared/models/view-config.model';

@Injectable()
export class ViewConfigService {
  private endpoint: string = '/view_config';

  private _views: ViewConfig[] = [];

  private _view$ = new Subject<ViewConfig>();
  private _views$ = new Subject<ViewConfig[]>();

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private logger: LoggerService,
    private notify: NotificationService
  ) { }

  get view$(): Observable<ViewConfig> {
    return this._view$.asObservable();
  }

  get views$(): Observable<ViewConfig[]> {
    return this._views$.asObservable();
  }


  getAsync(): Promise<ViewConfig> {
    const ids = this.state.getIds();
    const url = `${this.endpoint}/${ids.usergroup}/${ids.view}`;
    return this.httpClient.get<ViewConfig>(url).pipe(
      map(config => {
        const viewConfig = new ViewConfig(config);
        this.state.setViewConfig(viewConfig);
        return viewConfig;
      })
    ).toPromise();
  }

  /**
   * Retrieves all view_configs. This is an admin only call require admin headers
   */
  getAll() {
    this.httpClient.get<ViewConfig[]>(`${this.endpoint}`)
      .subscribe(
        (views: ViewConfig[]) => {
          this._views = views;
          this._views$.next(this._views);
        },
        (error: any) => this.logger.handleError('view configs load', error)
      );
  }


  getPublic(usergroupId: string, viewId: string): void {
    this.httpClient.get(`${this.endpoint}/${usergroupId}/${viewId}/public`)
      .subscribe(
        (viewConfig: ViewConfig) => {
          this.logger.log('Public view', viewConfig);
          this.state.setViewConfig(viewConfig);
        },
        (error) => this.logger.handleError('public view config load', error)
      );
  }

  get$(usergroupId?: string, viewId?: string): Observable<ViewConfig> {
    return this.httpClient.get<ViewConfig>(`${this.endpoint}/${usergroupId}/${viewId}`);
  }

  get(usergroupId?: string, viewId?: string, setState: boolean = true): void {
    if (setState) {
      this.logger.log('GET config', `${this.endpoint}/${usergroupId}/${viewId}`);
    }
    this.get$(usergroupId, viewId)
      .pipe(map(result => new ViewConfig(result)))
      .subscribe(
        (viewConfig: ViewConfig) => {
          if (setState) {
            this.logger.log('config is', viewConfig);
            this.state.setViewConfig(viewConfig);
          } else {
            this._view$.next(viewConfig);
          }
        },
        (error) => this.logger.handleError('view config load', error)
      );
  }


  create(viewConfig: ViewConfig, message: string = ''): void {
    const url = `${this.endpoint}/${viewConfig.usergroup_id}/${viewConfig.view_id}`;
    this.httpClient.post(url, viewConfig)
      .subscribe(
        (createdViewConfig: ViewConfig) => {
          const view = new ViewConfig(createdViewConfig);
          this._view$.next(view);
          this._views.push(view);
          this._views$.next(this._views);
          this.notify.success(message);
        },
        (error) => this.logger.handleError('view config create', error)
      );
  }

  update(viewConfig: ViewConfig, message: string = ''): void {
    const url = `${this.endpoint}/${viewConfig.usergroup_id}/${viewConfig.view_id}`;
    this.httpClient.put(url, viewConfig)
      .subscribe(
        (updatedViewConfig: ViewConfig) => {
          this.state.setViewConfig(new ViewConfig(updatedViewConfig));
          this.notify.success(message);
        },
        (error) => this.logger.handleError('view config update', error)
      );
  }

  delete(viewConfig: ViewConfig, message: string = ''): void {
    const url = `${this.endpoint}/${viewConfig.usergroup_id}/${viewConfig.view_id}`;
    this.httpClient.delete(url)
      .subscribe(
        () => {
          const viewIndex = this._views.findIndex(view => 
            view.usergroup_id === viewConfig.usergroup_id && view.view_id === viewConfig.view_id);
          if (viewIndex > -1) {
            this._views.splice(viewIndex, 1);
            this._views$.next(this._views);
            this.notify.success(message);
          }
        },
        (error) => this.logger.handleError('view config delete', error)
      );
  }

  patch(viewConfig: ViewConfig, updates: any[], message: string = ''): void {
    const url = `${this.endpoint}/${viewConfig.usergroup_id}/${viewConfig.view_id}`;
    this.httpClient.patch<ViewConfig>(url, updates)
      .subscribe(
        (resultViewConfig: ViewConfig) => {
          this.state.setViewConfig(new ViewConfig(resultViewConfig));
          this.notify.success(message);
        },
        (error) => this.logger.handleError('view config patch', error)
      );
  }


  patch$(viewConfig: IViewConfigIds, updates: any[]): Observable<ViewConfig> {
    const url = `${this.endpoint}/${viewConfig.usergroup_id}/${viewConfig.view_id}`;
    return this.httpClient
      .patch<ViewConfig>(url, updates).pipe(
        catchError(error => this.logger.handleError('view config patch', error))
      );
  }

}
