<div mat-dialog-title>
  {{ title }}
</div>

<mat-dialog-content class="no-actions" fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="info" class="switch-info">
    {{ info }}
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="switch-item card-clickable" *ngFor="let item of items" (click)="selectItem(item)">
      <i ihMatIcon [iconName]="item.icon" size="60"></i>
      <h4 class="mat-headline">{{ item.label }}</h4>
      <div *ngIf="item.sublabel" class="sublabel">{{ item.sublabel }}</div>

      <ng-container *ngIf="multiline(item.description); else singleline">
        <div class="description" *ngFor="let paragraph of item.description">{{ paragraph }}</div>
      </ng-container>
      <ng-template #singleline>
        <div class="description">{{ item.description }}</div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>