import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ih-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.sass']
})
export class LegalComponent implements OnDestroy {
  legalPage: boolean;

  policies = POLICIES;

  private unsubcribed$ = new Subject();

  constructor(private router: Router) { 
    this.router.events
      .pipe(
        takeUntil(this.unsubcribed$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.legalPage = (event.url === '/legal');
      })
  }

  ngOnDestroy() {
    this.unsubcribed$.next();
    this.unsubcribed$.complete();
  }

}


const POLICIES = [
  {
    key: "privacy",
    label: "Privacy Policy",
    description: "Our policy about the information we collect and it is being used used."
  },
  {
    key: "terms",
    label: "Terms of Service",
    description: "The terms you agree to when using the TEYB marketing platform."
  },
  {
    key: "security",
    label: "Security Policy",
    description: "Our rules and procedures for accessing and using all relevant IT assets and resources."
  },
]