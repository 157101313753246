<div mat-dialog-title>
  Move {{ data.listLength }} selected {{ kind === 'email_snippet' ? 'snippet' : kind }} 
</div>

<mat-dialog-content>
  <ng-container *ngIf="newFolder.selected; else existingFolders">
    <h3 class="mat-subheading-2">Create a new {{ folderEntity }}</h3>
    <form *ngIf="newFolder.selected" #folderForm>
      <mat-form-field class="full-width">
        <input matInput type="text"  name="label" required="true" #labelControl placeholder="{{ folderEntity | ihCapitalize }} name" 
        [(ngModel)]="newFolder.label" (ngModelChange)="validateFolder(false)">
      </mat-form-field>
    </form>
  </ng-container>
  
  <ng-template #existingFolders>
    <mat-selection-list #foldersList class="full-width" (selectionChange)="updateSelection($event)">
      <ng-container *ngIf="folders && folders.length > 0; else noFolders">
        <mat-list-option *ngFor="let folder of folders" [value]="folder.id">
          <span class="folder-list-item space-left-5">{{ folder.label }}</span>
          <mat-icon matListIcon>folder</mat-icon>
        </mat-list-option>    
      </ng-container>
      <ng-template #noFolders>
        <div>You have not created any {{ folderEntity }}. Click 'New {{ folderEntity | ihCapitalize }}' to create one.</div>  
      </ng-template>
    </mat-selection-list>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="start center">
  <button id="toggle-button" mat-button (click)="toggleNewFolder()">
    <mat-icon>{{ newFolder.selected ? 'list' : 'add_circle' }}</mat-icon>
    <span class="space-left-5">{{ newFolder.selected ? 'Existing' : 'New' }} {{ folderEntity | ihCapitalize }}</span>
  </button>
  <span fxFlex></span>
  <button class="cancel-button" mat-button (click)="cancel()">Cancel</button>
  <button id="move-button" mat-raised-button color="primary" (click)="move()" [disabled]="!canSelect">
    Move to {{ folderEntity | ihCapitalize }}
  </button>
</mat-dialog-actions>
