import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


interface BroadcastEvent {
  key: any;
  data?: any;
}

@Injectable()
export class BroadcastService {
  private broadcastEvents: Subject<BroadcastEvent>;

  constructor() {
    this.broadcastEvents = new Subject<BroadcastEvent>();
  }

  send(key: any, data?: any) {
    this.broadcastEvents.next({key, data});
  }

  recieve<T>(key: any): Observable<T> {
    return this.broadcastEvents.asObservable().pipe(
      filter( (event) => event.key === key ),
      map( (event) => <T> event.data ),);
  }

}
