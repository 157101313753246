import { ConditionCategory } from './condition-category.model';

export class ConditionGroup {
  position: number;
  relation: 'and'|'or';
  is_valid: boolean;
  categories: ConditionCategory[];
  default: boolean;
  remove?: boolean;

  constructor(input: any = {}) {
    this.position = input.position || 0;
    this.relation = input.relation || 'and';
    this.is_valid = input.is_valid || false;

    this.categories = [];
    if (input.categories && input.categories.length >= 1) {
      input.categories.forEach(category => {
        this.categories.push(new ConditionCategory(category));
      });
    }

    this.default = input.default || false;
  }

  toggleRelation() {
    this.relation = this.relation === 'and' ? 'or' : 'and';
  }

  validate() {
    this.is_valid = 
      this.default === true ||
      this.categories.every(category => category.is_valid === true)
  }

  addCategory(input: any, after?: number): ConditionCategory {
    const category = new ConditionCategory(input);
    (after !== undefined && this.categories.length > after + 1)
      ? this.categories.splice(after + 1, 0, category)
      : this.categories.push(category);
    this.updatePositions();
    return category;
  }

  removeCategory(position: number) {
    if (position !== undefined && this.categories.length > position) {
      this.categories.splice(position, 1);
    }
    this.updatePositions();
  }

  private updatePositions() {
    this.categories.forEach((category, index) => category.position = index);
    this.validate();
  }

}
