import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

  emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

  passwordPattern = '^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  filenamePattern = /^[\w]*$/;

  // emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  constructor() { }

  getError(error, stdErrorMsg) {
    return error === false
      ? error
      : error || stdErrorMsg;
  }

  isValidDate(checkDate: any): boolean {
    const doNotAccept = [''];
    if (this.exists(checkDate, doNotAccept)) {
      const date = new Date(checkDate);
      return Object.prototype.toString.call(date) === '[object Date]'
        ? isNaN(date.getTime()) ? false : true
        : false;
    } else {
      return false;
    }
  }

  isLaterDateTimeThan(last, first) {
    return this.isValidDate(last) && this.isValidDate(first)
      ? new Date(last) > new Date(first)
      : false;
  }

  /**
   * Checks if one date is later than the other
   * Also makes sure that checked date is not null, undefined, or ''
   * @param last date (or string date)
   * @param first date (or string date)
   */
  isLaterDateThan(last, first) {
    let lastDate: any;
    let firstDate: any;

    const exclude = [''];
    if (this.exists(last, exclude) && this.exists(first, exclude)) {
      lastDate = new Date(last);
      firstDate = new Date(first);

      lastDate = lastDate.setHours(23, 59, 59);
      firstDate = firstDate.setHours(23, 59, 59);
      return lastDate > firstDate;
    } else {
      return false;
    }
  }

  isValidTime(checkTime: string): boolean {
    const elements = checkTime.split(':');
    return (
      elements.length === 2 &&
      Number(elements[0]) >= 0 && Number(elements[0]) < 24 &&
      Number(elements[1]) >= 0 && Number(elements[0]) < 60
    );
  }

  isLaterTimeThan(last, first) {
    const exclude = [''];
    if (this.exists(last, exclude) && this.exists(first, exclude)) {
      const lastDt = `2000-01-01 ${last}`;
      const firstDt = `2000-01-01 ${first}`;
      return new Date(lastDt) > new Date(firstDt);
    } else {
      return false;
    }
  }

  isValidEmail(input: string): boolean {
    const check = input.trim();
    return check.match(this.emailPattern) !== null;
  }

  isValidHtml(checkHtml: string): boolean {
    const regexp = /<[a-z][\s\S]*>/i;
    return regexp.test(checkHtml) && checkHtml.length > 500;
  }


  isValidJson(checkJson: string | object): boolean {
    try {
      const test = typeof checkJson === 'string' ? JSON.parse(checkJson) : checkJson;
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Checks if input is valid integer (accepts spaces and '-' sign)
   * @param input string or number
   */
  isValidInteger(input, error?: string|boolean): string|boolean {
    const stdErrorMsg = 'must be an integer';
    let valid = false;

    if (this.exists(input)) {
      const integerPattern = /^[-]?[0-9]+$/;
      let check;
      typeof(input) === 'string'
        ? check = input.trim()
        : check = input.toString();

      valid = (
        check !== undefined &&
        check.length > 0 &&
        check.match(integerPattern) !== null
      );
    }

    return valid || this.getError(error, stdErrorMsg);
  }

  isPositive(input, error?: string|boolean): string|boolean {
    const stdErrorMsg = 'must be positive';
    let valid = false;


    if (input) {
      const number = Number(input);
      valid = number > 0;
    } 

    return valid || this.getError(error, stdErrorMsg);
  }


  isBetween0And100(input, error?: string|boolean): boolean|string {
    const stdErrroMsg = 'must be between 0 and 100';
    return input
      ? input >= 0 && input <= 100
      : this.getError(error, stdErrroMsg);
  }

  isPositiveInteger(input): boolean {
    if (this.exists(input)) {
      const integerPattern = /^[1-9]\d*$/;
      let check;
      typeof(input) === 'string'
        ? check = input.trim()
        : check = input.toString();
      return (
        check !== undefined &&
        check.length > 0 &&
        check.match(integerPattern) !== null &&
        parseInt(check, 10) > 0
      );
    } else {
      return false;
    }
  }


  /**
   * Checks if input is valid float (accepts spaces and '-' sign)
   * @param input string or float
   */
  isValidFloat(input, error?: string|boolean): string|boolean {
    const stdErrorMsg = 'must be an integer or decimal';
    const floatPattern = /^[-]?[0-9.]+$/;

    let check;
    typeof(input) === 'string'
      ? check = input.trim()
      : check = input.toString();

    const valid = (
      check !== undefined &&
      check.length > 0 &&
      check.match(floatPattern) !== null
    );

    return valid || this.getError(error, stdErrorMsg);
  }


  isValidIntegerRange(input): string|boolean {
    const parts = this.getRangeParts(input);
    switch (parts.length) {
      case 1:
        return this.isValidInteger(parts[0]);
      case 2:
        return (
          parts.every(part => this.isValidInteger(part) === true) &&
          Number(parts[1]) > Number(parts[0])
        );
      default:
        return false;
    }
  }

  isValidFloatRange(input): string|boolean {
    const parts = this.getRangeParts(input);
    switch (parts.length) {
      case 1:
        return this.isValidFloat(parts[0]);
      case 2:
        return (
          parts.every(part => this.isValidFloat(part) === true) &&
          Number(parts[1]) > Number(parts[0])
        );
      default:
        return false;
    }
  }


  isValidFilename(input: string): boolean {
    if (this.isValidString(input)) {
      return input.match(this.filenamePattern) !== null;
    } else {
      return false;
    }
  }

  isValidString(checkString: string): boolean {
    return (
      checkString !== undefined &&
      checkString !== null &&
      checkString !== '' &&
      checkString.length > 0
    );
  }

  isValidOperator(operator: string, operators: any[], property?: string): boolean {
    if (property) {
      return operators.map((item) => item[property]).indexOf(operator) > -1;
    } else {
      return operators.indexOf(operator) > -1;
    }
  }

  // helpers

  /**
   * returns list of input splitted by '-'
   * @param input
   */
  getRangeParts(input) {
    const check = (typeof(input) === 'string') ? input : input.toString();
    return check.trim().split('-');
  }

  /**
   * Makes sure that only 'null' and 'undefined' are not accepted
   * E.g. values like 0, False, etc. will be accepted
   * @param check
   * @param exclude array of values that should considered 'not existing', e.g. ''
   */
  exists(check, exclude?: any[]): boolean {
    switch (check) {
      case null:
      case undefined:
        return false;
      default:
        if (exclude && exclude.length > 0) {
          const requirements = [];
          exclude.forEach(item => {
            requirements.push(check === item);
          });
          return requirements.every(item => item === false);
        } else {
          return true;
        }
    }
  }

}
