import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError} from 'rxjs/operators';
import { LoggerService } from '@core/services/logger.service';
import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { environment } from '@env/environment';
import { IhApiError } from '@shared/models/error';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  externalApis: { name: string, url: string }[];

  constructor(
    private logger: LoggerService,
    private injector: Injector,
    private router: Router
  ) {
    this.externalApis = [
      { name: 'external', url: 'https://rsrc.getbee.io/api/templates/m-bee' },
      { name: 'external', url: 'https://auth.getbee.io/apiauth' },
      { name: 'assets', url: '/assets/policies/privacy.md' },
      { name: 'assets', url: '/assets/policies/terms.md' },
      { name: 'assets', url: '/assets/policies/security.md' },
      { name: 'innohead', url: 'https://track.innohead.com' }
    ];
  }

  getIds() {
    const auth = this.injector.get(AuthService);
    return {
      token: `JWT ${auth.getToken()}`,
      usergroup: auth.currentUsergroup ? auth.currentUsergroupId : undefined,
      view: auth.currentView ? auth.currentViewId : undefined,
    };
  }

  /**
   * All platform requests needs a JWT token
   * Models are loaded by usergorup and view ids set in header
   * Header usergroup and view can be set explicityly (e.g. if we need to load "admin"/"all" template standards)
   */
  platformRequest(request: HttpRequest<any>): HttpRequest<any> {
    const ids = this.getIds();
    const headers = { Authorization: ids.token };

    // set usergroup and view ids as part of header
    // Note: headers that are set explicily on httpClient request object (e.g. from admin calls) take presedence
    if (ids.usergroup && ids.view) {
      headers['usergroup'] = request.headers.get('usergroup') || ids.usergroup;
      headers['view'] = request.headers.get('view') || ids.view;
    }

    // platform request url depends on Development / Production setup
    const url = environment.apiUrl + request.url;

    return request.clone({ url: url, setHeaders: headers });
  }


  /**
   * "External" innohead api, e.g. for basic flow and node performance
   * Should always include token, usergroup and view id as part of the header
   * Note: usergroup is used as "namespace" on receiving end (use _dev in "development")
   */
  innoheadApiRequest(request: HttpRequest<any>): HttpRequest<any> {
    const ids = this.getIds();
    const headers = { Authorization: ids.token };

    if (ids.usergroup && ids.view) {
      headers['Usergroup'] = ids.usergroup + `${environment.production ? '' : '_dev'}`;
      headers['View'] = ids.view;
    }

    return request.clone({ url: request.url, setHeaders: headers });
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let finalRequest: HttpRequest<any>;

    const externalRequest = this.externalApis.find(api => request.url.indexOf(api.url) >= 0);
    if (externalRequest) {
      switch (externalRequest.name) {
        case 'innohead':
          finalRequest = this.innoheadApiRequest(request);
          break;
        default:
          finalRequest = request;
          break;
      }
    } else {
      finalRequest = this.platformRequest(request);
    }

    // console.log('request', finalRequest);
    
    return next.handle(finalRequest).pipe(
      catchError(res => {
        if (res instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>res).status) {
            case 0: // unknown or server down
              return this.catch0(res);
            case 401: // unauthorized
              return this.catch401(res);
            case 403: // forbidden
              return this.catch403(res);
            case 404: // not found
              return this.catch404(res);
            case 409: // conflict
              return this.catch409(res);
            case 500: // server error
              return this.catch500(res, finalRequest);
            default:
              return observableThrowError(res);
          }
        }
      }));
//    }), timeout(20000));
  }

  // Unknown error or server down
  private catch0(res: HttpErrorResponse): Observable<any> {
    this.injector.get(ToastrService).error(res.error.message, 'Server is down');
    return observableThrowError(res);
  }

  // Unauthorized requests
  private catch401(res: HttpErrorResponse): Observable<any> {
    if (res.error.message && res.error.message === 'Signature has expired') {
      this.injector.get(AuthService).logout();
      this.injector.get(ToastrService).error('Please log in again', 'Expired Session');
    } else if (res.error.message && res.error.message.indexOf('Signature verification') >=0) {
      this.injector.get(AuthService).logout();
      this.injector.get(ToastrService).error('Please log in again', 'New key needed');
    } else {
      this.injector.get(ToastrService).error(res.error.message, 'No access');
    }
    return observableThrowError(res);
  }

  // Forbidden
  private catch403(res: HttpErrorResponse): Observable<any> {
    this.injector.get(AuthService).logout();
    this.injector.get(ToastrService).error(res.error.message, 'Could not log in');
    return observableThrowError(res);
  }

  // Not Found
  private catch404(res: HttpErrorResponse): Observable<any> {
    const action = 'notFound';

    // ToDo: move specific handlers to specific services
    if (res.url.includes(PATTERNS.audiences.record) && !this.router.url.includes(PATTERNS.campaigns.route)) {
      this.displayMessageAndNavigateToMain(action, 'audiences');
    }

    return observableThrowError(res);
  }

  private catch409(res: HttpErrorResponse): Observable<any> {
    const patterns = {
      conflictFlow: '/flows/'
    };
    if (res.url.includes(patterns.conflictFlow)) {
      this.injector.get(ToastrService).error('The campaign has been edited and cannot be saved. Please reload this page to obtain the most recent version', 'Could not save');
    }

    return observableThrowError(res);
  }

  // Handle Server Error (write payload to StackDriver)
  private catch500(res: HttpErrorResponse, finalRequest): Observable<any> {
    const ids = this.getIds();
    const errorObject = { usergroup: ids.usergroup, view: ids.view, error: res, payload: finalRequest.body };
    this.logger.logError(errorObject);

    const userMessage = new IhApiError(res).message + '. Please contact support@innohead.com';
    this.injector.get(ToastrService).error(userMessage, 'We are sorry!');
    return observableThrowError(res);
  }

  displayMessageAndNavigateToMain(action, model) {
    const title = this.getDisplayMessageTitle(action);
    this.injector.get(ToastrService).error(PATTERNS[model].messages[action], title);
    this.router.navigate([PATTERNS[model].route]);
  }

  getDisplayMessageTitle(action) {
    switch (action) {
      case 'notFound': return 'Not Found';
      default: return 'Error';
    }
  }

}




const PATTERNS = {
  audiences: {
    route: 'platform/audiences',
    record: 'platform/audiences/builder',
    messages: {
      notFound: 'The audience could not be found'
    }
  },
  campaigns: {
    route: 'platform/campaigns',
    record: 'platform/campaigns/flow',
    messages: {
      notFound: 'The campaign could not be found'
    }
  },
  contacts: {
    route: 'platform/profiles/contacts/search',
    record: 'platform/profiles/contacts/show',
    messages: {
      notFound: 'The contact could not be fount'
    }
  }
};
