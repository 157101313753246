import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JsonViewerDialogComponent } from './json-viewer-dialog/json-viewer-dialog.component';


@Component({
  selector: 'ih-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.sass'],
})
export class JsonViewerComponent {
  @Input() object: Object;
  @Input() styled: boolean = false;

  constructor(
    private dialog: MatDialog
  ) { }

  showJson() {
    this.dialog.open(JsonViewerDialogComponent, { 
      data: { jsonString: JSON.stringify(this.object) },
      height: '80vh',
      width: '80vw',
      panelClass: ['ih-dialog', 'json-viewer-dialog']
    });
  }
}
