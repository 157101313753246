import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IhApiError } from '@shared/models/error';

@Injectable()
export class LoggerService {

  enabled = true;

  constructor(private httpClient: HttpClient) {
    if (environment.production === true) {
      this.enabled = false;
    }
  }

  noop = () => { };

  get debug(): any {
    if (this.enabled) { return console.debug.bind(console); }
    return this.noop;
  }

  get error(): any {
    if (this.enabled) { return console.error.bind(console); }
    return this.noop;
  }

  get log(): any {
    if (this.enabled) { return console.log.bind(console); }
    return this.noop;
  }

  get info(): any {
    if (this.enabled) { return console.info.bind(console); }
    return this.noop;
  }

  get warn(): any {
    if (this.enabled) { return console.warn.bind(console); }
    return this.noop;
  }

  logError(error) {
    this.httpClient.post('/log_error', error).pipe(
      take(1))
      .subscribe();
  }

  handleError(source: string, error: HttpErrorResponse) {
    const apiError = new IhApiError(error);
    this.error(source + ' error', error);
    return throwError(`- ${source.toUpperCase()}: ` + apiError.message);
  }

}
