import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '@core/services/auth.service';
import { AppStateService } from '@app/app.service';
import { NotificationService } from '@core/services/notification.service';

import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { User } from '@shared/models/user';
import { LOADERS } from '@shared/models/loaders.model';


@Component({
  selector: 'ih-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnDestroy {
  input: { email: string, password: string };
  user: User;
  firstName: string;
  loadingUser = false;
  updatingUser = false;
  setupPlatform = false;

  private unsubsribe$: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    private state: AppStateService,
    private authService: AuthService,
    private notify: NotificationService,
    private router: Router
  ) {
    this.input = { email: '', password: '' };
  }

  showForgotPasswordDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      data: this.input.email,
      panelClass: ['ih-dialog', 'minimal'],
      maxWidth: '400px'
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.unsubsribe$))
      .subscribe((email: string) => {
        if (email) { this.resetUserPassword(email); }
      });
  }


  resetUserPassword(email: string) {
    this.updatingUser = true;
    this.authService.resetPassword(email).pipe(
      takeUntil(this.unsubsribe$))
      .subscribe((userUpdated) => this.onResetPasswordSuccess(userUpdated))
      .add(() => this.updatingUser = false);
  }

  onResetPasswordSuccess(userUpdated) {
    userUpdated
      ? this.notify.success('Please check your email', 'Password reset')
      : this.notify.error('Password could not be reset');
  }


  login(): void {
    const loader = LOADERS.login;
    this.state.registerLoader(loader);
    this.authService.login(this.input)
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe((user: User) => {
        this.firstName = user.name.split(' ')[0];
        this.onLoginSuccess();
      })
      .add(() => this.state.resolveLoader(loader));
  }

  onLoginSuccess() {
    const usergroupId = this.authService.currentUsergroupId;
    if (usergroupId === undefined) {
      this.router.navigate(['/logout']);
    } else {
      (usergroupId === 'admin' || usergroupId === 'admin_dev')
        ? this.router.navigate(['/admin'])
        : this.router.navigate(['/platform']);
    }
  }

  ngOnInit() {
    this.state.state$('loaders').pipe(
      takeUntil(this.unsubsribe$))
      .subscribe((loaders) => {
        this.loadingUser = loaders[LOADERS.login];
        this.setupPlatform = loaders[LOADERS.setupPlatform];
      });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

}
