import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import { EMPTY } from 'rxjs';

import { AppStateService } from '@app/app.service';
import { RawUsergroupConfig } from '@shared/models/usergroup-config.model';

@Injectable()
export class UsergroupConfigResolver implements Resolve<Observable<RawUsergroupConfig>> {
  constructor(
    private state: AppStateService,
    private http: HttpClient
  ) {}

  resolve() {
    const usergroupId = this.state.getState('usergroupId');
    if (['admin', 'admin_dev'].includes(usergroupId)) {
      return EMPTY;
    } else {
      return this.http.get<RawUsergroupConfig>(`/usergroup_config/${usergroupId}`);
    }
  }
}
