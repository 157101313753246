import { Directive, ElementRef, Input, OnInit, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ihPluralize]'
})
export class PluralizeDirective implements OnChanges {
  @Input() value: number;
  @Input() label: string;
  @Input() onlyLabel: boolean = false;
  @Input() showParts: string[] = ['value', 'label'];

  operator: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  depluralize(): string {
    return this.value > 1
      ? this.label
      : this.label.slice(0, -1);
  }

  pluralize(): string {
    return this.value > 1
      ? `${this.label}s`
      : this.label;
  }

  getLabel(): string {
    switch (this.operator) {
      case 'pluralize': return this.pluralize();
      case 'depluralize': return this.depluralize();
      default: return 'unknown';
    }
  }

  getOperator() {
    const lastCharacter: string = this.label.substring(this.label.length - 1);
    if (lastCharacter === 's') {
      return 'depluralize';
    } else {
      return 'pluralize';
    }
  }

  ngOnChanges() {
    this.operator = this.getOperator();
    const showLabel = this.getLabel();

    const htmlParts = [];
    this.showParts.forEach(part => {
      if (part === 'value' && !this.onlyLabel) {
        htmlParts.push(this.value);
      }
      if (part === 'label') {
        htmlParts.push(showLabel);
      }
    });

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', htmlParts.join(' '));
  }

}
