interface PlatformButton {
  label: string;
  route: string;
  ability?: string;
  action?: string;
}

export interface PlatformRoute {
  value: string; // name of platform route
  label: string; // item label
  icon: string; // item icon
  show: { 
    production: boolean, // show in production
    menu: boolean, // show in main menu
    overview: boolean // show on overview page
  };
  text?: string; // overview page text
  buttons?: PlatformButton[]; // overview page buttons
  subroutes?: {label: string, value: string}[]; // subroutes for the item
}

export const PLATFORM_ROUTES: PlatformRoute[] = [
  {
    label: 'Overview',
    value: 'overview',
    icon: 'mat explore',
    show: { production: true, menu: true, overview: false }
  },
  {
    label: 'Contacts',
    value: '',
    icon: 'fas fa-users',
    text: 'Search your contacts and build targeted customer audiences',
    show: { production: true, menu: true, overview: true },
    buttons: [
      { label: 'Search', ability: 'view', route: 'profiles' },
      { label: 'Audiences', ability: 'view', route: 'audiences' }
    ],
    subroutes: [
      { label: 'Search Contacts', value: 'profiles' },
      { label: 'Audiences', value: 'audiences' }
    ]
  },
  {
    label: 'Products',
    value: '',
    icon: 'fas fa-shopping-cart',
    text: 'Search your products and define targeted product groups',
    show: { production: false, menu: true, overview: true },
    buttons: [
      { label: 'Search', ability: 'view', route: 'products' },
      { label: 'Groups', ability: 'view', route: 'product-groups' }
    ],
    subroutes: [
      { label: 'Search Products', value: 'products' },
      { label: 'Groups', value: 'product-groups' }
    ]
  },
  {
    label: 'Emails',
    value: 'templates',
    icon: 'fas fa-envelope',
    show: { production: true, menu: true, overview: true },
    text: 'Create and design email templates for your campaigns',
    buttons: [
      { label: 'View', ability: 'view', route: '' },
      { label: 'Create', ability: 'create', route: 'list', action: 'new' }
    ]
  },
  { 
    label: 'Campaigns',
    value: 'campaigns',
    icon: 'fas fa-bullhorn',
    show: { production: true, menu: true, overview: true },
    text: `Set up automated campaign flows that respond to customers' behavor` ,
    buttons: [
      { label: 'View', ability: 'view', route: '' },
      { label: 'Create', ability: 'create', route: 'list', action: 'new' }
    ]
  },
  {
    label: 'Reports',
    value: 'reports',
    icon: 'fas fa-chart-pie', 
    show: { production: true, menu: true, overview: true },
    text: 'View statistics for your customers, campaigns and email performance',
    buttons: [
      { label: 'Customers', ability: 'view', route: 'customers' },
      { label: 'Campaigns', ability: 'view', route: 'campaign-list' }
    ],
    subroutes: [
      // { label: 'Leads', value: 'leads' },
      { label: 'Customers', value: 'customers' },
      { label: 'Campaigns', value: 'campaign-list' },
      { label: 'Emails', value: 'emails' }
    ]
  }
];
