interface IInitialize<T> {
  initialize(input: any): T;
}

export class Template implements IInitialize<Template> {
  id: number;
  view_id: string;

  name: string;
  description: string;
  folder: number;
  theme: string;
  tags: string[];
  thumbnail: string;
  html: string;
  json: any;

  selected?: boolean;
  deleted?: boolean;
  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  autosave?: {json: any, time: string}; 

  initialize(input?: any): Template {
    input = input || {};

    if (input && input.id) {
      this.id = input.id;
      this.view_id = input.view_id;
    }

    this.name = input.name || '';
    this.description = input.description || '';
    this.folder = input.folder || 1;
    this.theme = input.theme || '';
    this.tags = input.tags || [];
    this.thumbnail = input.thumbnail || '';
    this.html = input.html || '<!DOCTYPE HTML><html></html>';
    this.json = input.json || this.getEmptyJson('bee');

    if (input.created_at) {
      this.created_at = input.created_at;
    }
    if (input.created_by) {
      this.created_by = input.created_by;
    }
    if (input.modified_at) {
      this.modified_at = input.modified_at;
    }
    if (input.modified_by) {
      this.modified_by = input.modified_by;
    }

    if (input.autosave) {
      this.autosave = input.autosave;
    }

    return this;
  }

  private getEmptyJson(type: string) {
    switch (type) {
      case 'bee':
        return JSON.parse(JSON.stringify(BEE_PLUGIN_EMPTY_JSON));
      default: 
        return {};
    }
  }
}


export const BEE_PLUGIN_EMPTY_JSON = {
  "page":{  
    "body":{  
      "content":{  
        "style":{  
          "color":"#000000",
          "font-family":"Arial, 'Helvetica Neue', Helvetica, sans-serif"
        },
        "computedStyle":{  
          "messageBackgroundColor":"transparent",
          "linkColor":"#0068A5",
          "messageWidth":"500px"
        }
      },
      "container":{  
        "style":{  
          "background-color":"#FFFFFF"
        }
      },
      "type":"mailup-bee-page-properties",
      "webFonts":[]
    },
    "rows":[  
      {  
        "content":{  
          "style":{  
            "background-image":"none",
            "background-repeat":"no-repeat",
            "color":"#000000",
            "width":"500px",
            "background-position":"top left",
            "background-color":"transparent"
          },
          "computedStyle":{  
            "rowColStackOnMobile":true
          }
        },
        "type":"one-column-empty",
        "container":{  
          "style":{  
            "background-image":"none",
            "background-position":"top left",
            "background-repeat":"no-repeat",
            "background-color":"transparent"
          }
        },
        "columns":[  
          {  
            "grid-columns":12,
            "modules":[  
              {  
                "descriptor":{  

                },
                "type":"mailup-bee-newsletter-modules-empty"
              }
            ],
            "style":{  
              "padding-bottom":"5px",
              "border-bottom":"0px solid transparent",
              "border-top":"0px solid transparent",
              "padding-right":"0px",
              "border-left":"0px solid transparent",
              "border-right":"0px solid transparent",
              "padding-top":"5px",
              "background-color":"transparent",
              "padding-left":"0px"
            }
          }
        ]
      }
    ],
    "description":"",
    "template":{  
      "version":"2.0.0",
      "type":"basic",
      "name":"template-bee"
    },
    "title":""
  }
}