import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { Usergroup, View } from '@shared/models/usergroup';

@Component({
  selector: 'ih-select-view-dialog',
  templateUrl: './select-view-dialog.component.html',
  styleUrls: ['./select-view-dialog.component.sass']
})
export class SelectViewDialogComponent implements OnInit {
  @Output() selected = new EventEmitter();

  processing: boolean = false;
  loginGroups: Usergroup[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { processing: boolean, groups?: Usergroup[] },
    public dialogRef: MatDialogRef<SelectViewDialogComponent>
  ) { }

  close() {
    this.dialogRef.close();
  }

  process(usergroup: Usergroup, view: View) {
    this.selected.emit({usergroup: usergroup, view: view });
    this.processing = true;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.processing = this.data.processing;
    this.loginGroups = this.data.groups ? this.data.groups : []
  }

}
