import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app-state.model';
import { setLoginStatus, setLoginGroups } from '@store/app-state.actions';

import { AuthService, Authentication } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { User } from '@shared/models/user';
import { Usergroup } from '@app/shared/models/usergroup';


@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private store: Store<AppState>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    const ADMIN_USERGROUP = (environment.production === true) ? 'admin' : 'admin_dev';

    return this.authService.authenticateUser().pipe(
      map((response: Authentication) => {

        if (response.authenticated && this.isAdmin(response.user)) {
          this.authService.setUser(response.user);
          this.authService.setCurrentUsergroup(ADMIN_USERGROUP);
          this.authService.setCurrentUserView(ADMIN_USERGROUP);

          const groups = response.usergroups
            ? response.usergroups.map(usergroup => new Usergroup(usergroup))
            : [];
          this.store.dispatch(setLoginGroups(groups));
          return true;
        }

        this.storageService.deleteItem('innohead_login');
        this.router.navigateByUrl('/login');
        return false;
      }));
  }

  isAdmin(user: any): boolean {
    return user ? new User(user).isAdmin() : false;
  }

}

// http://embed.plnkr.co/TIPmK3/?show=preview
