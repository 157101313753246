import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@core/services/auth.interceptor';


// GUARDS
import { AdminGuard } from '@core/guards/admin.guard';
import { AbilityGuard } from '@core/guards/ability.guard';
import { PermissionsGuard } from '@core/guards/permissions.guard';
import { PlatformGuard } from '@core/guards/platform.guard';
const GUARDS = [AdminGuard, AbilityGuard, PermissionsGuard, PlatformGuard];


// RESOLVERS
import { UsergroupConfigResolver } from '@core/resolvers/usergroup-config.resolver';
const RESOLVERS = [UsergroupConfigResolver];


// SERVICES
import { AuthService } from '@core/services/auth.service';
import { BroadcastService } from '@core/services/broadcast.service';
import { DialogService } from '@core/services/dialog.service';
import { FlowsService } from '@core/services/flows.service';
import { HtmlSnippetsService } from '@core/services/html_snippets.service';
import { IngredientsService } from '@core/services/ingredients.service';
import { LoggerService } from '@core/services/logger.service';
import { NotificationService } from '@core/services/notification.service';
import { PlatformDefaultsService } from '@core/services/platform-defaults.service';
import { PluginsService } from '@core/services/plugins.service';
import { StorageService } from '@core/services/storage.service';
import { TagsService } from '@core/services/tags.service';
import { TemplatesService } from '@core/services/templates.service';
import { UsergroupConfigService } from '@core/services/usergroup-config.service';
import { UsersService } from '@core/services/users.service';
import { ValidationService } from '@core/services/validation.service';
import { ViewConfigService } from '@core/services/view-config.service';

const SERVICES = [
  AuthService,
  BroadcastService,
  DialogService,
  FlowsService,
  HtmlSnippetsService,
  IngredientsService,
  LoggerService,
  NotificationService,
  PlatformDefaultsService,
  PluginsService,
  StorageService,
  TagsService,
  TemplatesService,
  UsergroupConfigService,
  UsersService,
  ValidationService,
  ViewConfigService
];


// USER MESSAGING
const toastConfig = {
  timeOut: 4000,
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
};
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot(toastConfig),
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    ...GUARDS,
    ...RESOLVERS,
    ...SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
