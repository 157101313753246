import { CategoryConfig, FilterConfig } from '@shared/models/filters-selector-config.model';
import { INITIAL_LOADERS } from '@shared/models/loaders.model';
import { ListSorting, User, UsergroupAccess, ViewAccess } from '@shared/models/user';
import { ViewConfig } from '@shared/models/view-config.model';
import { Usergroup } from '@shared/models/usergroup';
import { TablesConfig } from '@shared/models/usergroup-config.model';
import { ActiveMenu } from '@shared/models/menu.model';
import { Flow } from '@shared/models/flow.model';
import { FlowNode } from '@shared/models/node.model';


export interface ActivePage {
  title?: string;
  header: string;
  subsection?: string;
  subsections?: any[];
}

export class AppState {

  // login
  public user: User;
  public usergroup: UsergroupAccess;
  public view: ViewAccess;
  public usergroupId: string;
  public userviewId: string;
  public loggedIn: boolean;
  public login_groups: Usergroup[];

  // config
  public view_config: ViewConfig;
  public list_sorting: ListSorting;
  public tables: TablesConfig;

  // new configs
  public audience_categories: Map<string, CategoryConfig>;
  public audience_filters: Map<string, FilterConfig>;
  public trigger_categories: Map<string, CategoryConfig>;
  public trigger_filters: Map<string, FilterConfig>;
  public product_categories: Map<string, CategoryConfig>;
  public product_filters: Map<string, FilterConfig>;

  // platform
  public activePage: ActivePage;
  public activeMenu: ActiveMenu;
  public activeFlow: Flow;
  public activeNode: FlowNode;

  // loaders
  public loaders: any;
}

export const INITIAL_APP_STATE: AppState = {

  // login
  user: undefined,
  usergroup: undefined,
  view: undefined,
  usergroupId: '',
  userviewId: '',
  loggedIn: false,
  login_groups: undefined,

  // config
  view_config: undefined,
  list_sorting: undefined,
  tables: undefined,
  audience_categories: undefined,
  audience_filters: undefined,
  trigger_categories: undefined,
  trigger_filters: undefined,
  product_categories: undefined,
  product_filters: undefined,

  // platform
  activePage: { header: undefined },
  activeMenu: { context: undefined, item: undefined },
  activeFlow: undefined,
  activeNode: undefined,

  // loaders
  loaders: INITIAL_LOADERS
};
