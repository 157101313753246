import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ihSort'
})
export class SortPipe implements PipeTransform {

  transform(array: any[], args?: any): any[] {
    return array.sort((a: any, b: any) => {
      if (a[args.field] < b[args.field]) {
        return -1 * args.direction;
      } else if ( a[args.field] > b[args.field]) {
        return 1 * args.direction;
      }
      return 0;
    });
  }

}
