import { FlowNode } from '@shared/models/node.model';
import { EventConditions } from '@shared/models/event-conditions.model';
import { ValidationService } from '@core/services/validation.service';
import { IValidate, Requirement } from '@shared/models/requirements.model';

interface IInitialize<T> {
  initialize(input: any): T;
}

const confirm = new ValidationService();


// PERIOD ========================================================================

export class FlowPeriod implements IInitialize<FlowPeriod>, IValidate<FlowPeriod> {
  static minDate: Date = new Date('2000-01-01');
  static maxDate: Date = new Date('2100-01-01');

  start: Date;
  stop: Date;
  day_limit: number;

  valid_dates?: boolean;
  valid_day_limit?: boolean;
  is_valid?: boolean;

  initialize(period: any): FlowPeriod {
    const input = period ? period : { start: undefined, stop: undefined };

    this.start = input.start;
    this.stop = input.stop;
    input.day_limit === undefined
      ? this.day_limit = 300
      : this.day_limit = input.day_limit;

    input.is_valid === undefined
      ? this.validate()
      : this.is_valid = input.is_valid;

    return this;
  }

  validateDates() {
    const requirements: boolean[] = [];

    const exclude = [''];
    const hasStart = confirm.exists(this.start, exclude);
    const hasStop = confirm.exists(this.stop, exclude);

    if (hasStart) {
      requirements.push(confirm.isValidDate(this.start));
    }
    if (hasStop) {
      requirements.push(confirm.isValidDate(this.stop));
    }
    if (hasStart && hasStop) {
      requirements.push(confirm.isLaterDateThan(this.stop, this.start));
    }

    requirements.length > 0
      ? this.valid_dates = requirements.every(item => item === true)
      : this.valid_dates = true;
  }

  validateDayLimit() {
    const requirements = [];

    if (confirm.exists(this.day_limit, [''])) {
      requirements.push(confirm.isPositiveInteger(this.day_limit));
    }

    this.valid_day_limit = requirements.every(item => item === true);
  }

  validate(): Requirement {
    this.validateDates();
    this.validateDayLimit();

    this.is_valid = this.valid_dates && this.valid_day_limit;
    return new Requirement('active period', this.is_valid).withData({link: 'root/setup'});
  }
}


// PRIORITY ========================================================================

export class FlowPriority implements IInitialize<FlowPriority>, IValidate<FlowPriority> {
  enabled: boolean;
  value: number;
  is_valid?: boolean;

  initialize(priority: any): FlowPriority {
    const input = priority ? priority : { };

    this.enabled = input.enabled;
    this.value = input.value || 100;

    if (this.enabled === undefined) {
      this.enabled = true;
    }

    input.is_valid === undefined
      ? this.validate()
      : this.is_valid = input.is_valid;

    return this;
  }

  validate(): Requirement {
    const requirements: boolean[] = [];

    if (this.enabled === true) {
      requirements.push(confirm.isPositiveInteger(this.value));
    } else {
      requirements.push(true);
    }

    this.is_valid = requirements.every(item => item === true);
    return new Requirement('flow priority', this.is_valid).withData({link: 'root/setup'});
  }
}


// TRIGGER WINDOW ===============================================================

export class FlowTriggerWindow implements IInitialize<FlowTriggerWindow>, IValidate<FlowTriggerWindow> {
  static timeUnits: string[] = ['minutes', 'hours', 'days'];
  time_value: number;
  time_unit: |'minutes'|'hours'|'days';
  is_valid: boolean;

  initialize(triggerWindow: any): FlowTriggerWindow {
    const input = triggerWindow ? triggerWindow : {};

    this.time_value = input.time_value || 3;
    this.time_unit = input.time_unit || 'days';
    input.is_valid === undefined
      ? this.validate()
      : this.is_valid = input.is_valid;

    return this;
  }

  validate(): Requirement {
    const requirements = [];
    const timeValue = Number(this.time_value);

    requirements.push(timeValue !== NaN);
    requirements.push(timeValue >= 1);
    this.is_valid = requirements.every(item => item === true);
    return new Requirement('trigger_window', this.is_valid, 'trigger window').withData({link: 'root/setup'});;
  }
}


// QUARANTINE =================================================================

export class FlowQuarantine implements IInitialize<FlowQuarantine>, IValidate<FlowQuarantine> {
  static timeUnits: string[] = ['days', 'months', 'years'];
  static frequencyUnits: string[] = ['maximum', 'no_maximum'];

  exposure_limit: boolean;
  exposure_value: number;
  time_value: number;
  time_unit: string;
  enabled: boolean;
  is_valid: boolean;

  initialize(quarantine: any): FlowQuarantine {
    const input = quarantine ? quarantine : {};

    this.exposure_limit = input.exposure_limit;
    this.exposure_value = input.exposure_value;
    this.time_value = input.time_value || 90;
    this.time_unit = input.time_unit || 'days';
    this.enabled = input.enabled;

    if (this.enabled === undefined) {
      this.enabled = true;
    }

    if (this.exposure_limit === undefined) {
      this.exposure_limit = true;
    }

    if (this.exposure_limit === true && !this.exposure_value) {
      this.exposure_value = 2;
    }

    input.is_valid === undefined
      ? this.validate()
      : this.is_valid = input.is_valid;

    return this;
  }

  validate(): Requirement {
    const requirements = [];
    if (this.enabled) {
      if (this.exposure_limit) {
        requirements.push(confirm.isPositiveInteger(this.exposure_value));
      }
      requirements.push(confirm.isPositiveInteger(this.time_value));
    } else {
      requirements.push(true);
    }

    this.is_valid = requirements.every(item => item === true);
    return new Requirement('quarantine', this.is_valid).withData({link: 'root/setup'});
  }
}


// STATUS ========================================================================

export class FlowStatus {
  id: string;
  label: string;
  order: number;
  editable: boolean;
  has_report: boolean;
}

export const FLOW_STATUSES = {
  DRAFT:     { id: 'draft',     label: 'Draft',     order: 0, has_report: false, editable: true  },
  ACTIVE:    { id: 'active',    label: 'Active',    order: 1, has_report: true,  editable: false },
  RUNNING:   { id: 'running',   label: 'Running',   order: 2, has_report: true,  editable: false },
  PAUSED:    { id: 'paused',    label: 'Paused',    order: 3, has_report: true,  editable: true  },
  COMPLETED: { id: 'completed', label: 'Completed', order: 4, has_report: true,  editable: false },
  //ARCHIVED:  { id: 'archived',  label: 'Archived',  order: 5, has_report: false, editable: false },
};

function getFlowStatus(state: string) {
  const flowStatus =  FLOW_STATUSES[state.toUpperCase()];
  return flowStatus ? JSON.parse(JSON.stringify(flowStatus)) : undefined;
}

export const EXECUTION_TYPES = {
  BATCH:      { id: 'batch',  label: 'Date and Time', icon: 'fas fa-clock', order: 0 },
  STREAMING:  { id: 'streaming',  label: 'Behavior', icon: 'fas fa-user-circle', order: 1 }
};


// FLOW ==========================================================================

export class Flow implements IInitialize<Flow> {
  id: number;
  hash: string;
  view_id: string;
  execution_type: string; // 'batch' or 'streaming'

  name_id: string;
  name: string;
  description: string;
  folder: number;
  icon: string;
  theme: string;
  tags: string[];

  state: string;
  log: { state: string, t: string }[];
  
  period: FlowPeriod;
  priority: FlowPriority;
  trigger_window: FlowTriggerWindow;
  quarantine: FlowQuarantine;

  triggers: EventConditions[];
  nodes: FlowNode[];
  // TODO: we need a better way of handling which flows use which audiences
  // possible by creating a flow_audiences join model
  audiences: number[];

  root_valid: boolean;
  is_valid: boolean;
  is_active: boolean;
  
  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  // properties that are not saved on model
  status?: FlowStatus;
  deleted?: boolean;
  selected?: boolean;

  initialize(input: any, validate: boolean = false): Flow {
    if (input && input.id) {
      this.id = input.id;
      this.view_id = input.view_id;
    }

    this.hash = input.hash;
    this.execution_type = input.execution_type;
    this.name_id = 
      (!input.name_id || input.name_id === 'empty')
      ? ((this.execution_type) ? `exec_${this.execution_type}` : undefined)
      : input.name_id
    this.name = input.name;
    this.description = input.description;
    this.folder = input.folder || 1;
    this.icon = input.icon;
    this.theme = input.theme;
    this.tags = input.tags || [];
    this.audiences = input.audiences || [];

    this.period = new FlowPeriod().initialize(input.period);
    this.priority = new FlowPriority().initialize(input.priority);
    this.trigger_window = new FlowTriggerWindow().initialize(input.trigger_window);
    this.quarantine = new FlowQuarantine().initialize(input.quarantine);
    
    const triggers: any[] = input.triggers ? input.triggers : (input.trigger ? [input.trigger] : []);
    this.triggers = (triggers.length > 0 ? triggers : [{}]).map(trigger => 
      new EventConditions().initialize(
        Object.assign({}, trigger, {type: 'trigger', execution_type: input.execution_type })
      ));

    this.nodes = (!!input.nodes && input.nodes.length > 0)
      ? input.nodes.map((node: FlowNode, index: number) => new FlowNode(node, `${index + 1}`))
      : [];

    if (validate) {
      this.validate();
    } else {
      this.root_valid = input.root_valid || false;
      this.is_valid = input.is_valid || false;
    }

    // set status from state
    if (input.state) {
      this.state = input.state;
      this.status = getFlowStatus(this.state);
    }

    this.log = input.log || [];

    if (input.created_at) {
      this.created_at = input.created_at;
    }
    if (input.created_by) {
      this.created_by = input.created_by;
    }
    if (input.modified_at) {
      this.modified_at = input.modified_at;
    }
    if (input.modified_by) {
      this.modified_by = input.modified_by;
    }

    return this;
  }

  activate() {
    if (!this.period.start) {
      this.period.start = new Date();
    }
    // if 'stop' date is smaller than now - remove it and make the flow indefinitely running
    const start = this.period.start ? new Date(this.period.start) : undefined;
    const stop = this.period.stop ? new Date(this.period.stop) : undefined;
    if (!!stop && !!start && stop.getTime() < start.getTime()) {
      this.period.stop = undefined;
    }
    this.is_active = true;
    this.state = FLOW_STATUSES.ACTIVE.id;
    this.status = FLOW_STATUSES.ACTIVE;
  }

  pause() {
    this.deactivate();
    this.state = FLOW_STATUSES.PAUSED.id;
    this.status = FLOW_STATUSES.PAUSED;
  }

  complete() {
    this.deactivate();
    this.state = FLOW_STATUSES.COMPLETED.id;
    this.status = FLOW_STATUSES.COMPLETED;
  }

  lockStatus() {
    const status: FlowStatus = JSON.parse(JSON.stringify(this.status));
    status.editable = false;
    this.status = status;
  }

  newStatus(newStatus?: FlowStatus) {
    this.status = JSON.parse(JSON.stringify(newStatus));
  }


  deactivate() {
    if (!this.period.stop) {
      this.period.start = undefined;
    }
    this.is_active = false;
  }

  validate(validateNodes: boolean = false): Requirement {
    const rootValidation = new Requirement('root', undefined, 'Overall flow check');
    rootValidation.addChild(this.period.validate());
    rootValidation.addChild(this.priority.validate());
    rootValidation.addChild(this.trigger_window.validate());
    rootValidation.addChild(this.quarantine.validate());
    rootValidation.addChild(this.triggers[0].validate());
    rootValidation.validate();
    this.root_valid = rootValidation.is_valid;

    const nodeValidation = new Requirement('node', undefined, 'Action check');
    const nodeRequirements: Requirement[] = [];
    const validNodes = this.recursiveNodeValidations(this.nodes, nodeRequirements, validateNodes);
    nodeValidation.addChildren(nodeRequirements);
    nodeValidation.setValidity(validNodes);

    const flowValidation = new Requirement('flow', undefined, 'Full flow check');
    flowValidation.addChild(rootValidation);
    flowValidation.addChild(nodeValidation);
    flowValidation.validate();

    this.is_valid = flowValidation.is_valid;
    return flowValidation;
  }

  recursiveNodeValidations(nodes: FlowNode[], results: Requirement[], revalidate: boolean): boolean {
    let validBranch = true;

    nodes.forEach((node) => {
      if (revalidate) {
        node.validate();
      }
      validBranch = node.is_valid && validBranch;
      const nodeName = node.name || node.short_label;
      const requirementInfo = nodeName.length > 20 ? `'${nodeName.substring(0,17)}...'` : `'${nodeName}'`
      const requirementData = (node.channel)
        ? {link: `node/${node.id}/content`}
        : {missing_node: node.id}
      const requirement = new Requirement('node_' + node.id, node.is_valid, requirementInfo).withData(requirementData);
      results.push(requirement);
      if (node.nodes && node.nodes.length > 0) {
        validBranch = this.recursiveNodeValidations(node.nodes, requirement.children, revalidate) && validBranch;
      }
    });
    return validBranch;
  }

  findNode(nodeId: string, nodes: FlowNode[] = this.nodes): FlowNode {
    // checking if array exists and isn't empty
    if (!nodes || nodes.length === 0) {
      return undefined;
    }

    // checking current level
    let foundNode: FlowNode;
    foundNode = nodes.find(node => node.id === nodeId);
    if (foundNode) {
      console.log('Found node' , foundNode);
      return foundNode;
    }

    // checking child nodes recursively 
    for (let node of nodes) {
      console.log('Checking child nodes of node', node.id);
      foundNode = this.findNode(nodeId, node.nodes);
      if (foundNode) {
        break;
      }
    }
    return foundNode;
  }

  getAllNodes(): FlowNode[] {
    const nodes: FlowNode[] = [];
    this.nodes.forEach(node => {
      nodes.push(node);
      nodes.push(...node.getAllChildren());
    });
    return nodes;
  }

  getAllChannels(): string[] {
    const channels: string[] = this.getAllNodes().map(node => node.channel);
    return Array.from(new Set(channels));
  }

}
