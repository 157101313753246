import { Injectable } from '@angular/core';
import { Location } from '@angular/common/';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppStateService } from '@app/app.service';
import { NotificationService } from '@core/services/notification.service';
import { HtmlSnippet, HtmlSnippetKind, HtmlSnippetJson } from '@app/shared/models/html_snippet.model';
import { LOADERS } from '@shared/models/loaders.model';


@Injectable()
export class HtmlSnippetsService {
  private endpoint: string = '/html_snippets';
  private navUrl: string = '/platform';

  private _snippets: HtmlSnippet[] = [];

  private _snippet$ = new BehaviorSubject<HtmlSnippet>(undefined);
  private _snippets$ = new BehaviorSubject<HtmlSnippet[]>([]);

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private notify: NotificationService,
    private router: Router,
    private location: Location
  ) { }

  get snippet$(): Observable<HtmlSnippet> {
    return this._snippet$.asObservable();
  }

  get snippets$(): Observable<HtmlSnippet[]> {
    return this._snippets$.asObservable();
  }


  // -------------------------------------- LOADING --------------------------------------

  loadAll(kind: HtmlSnippetKind = 'email') {
    const loader = this.state.registerLoader(LOADERS.snippets);
    this.httpClient.get(`${this.endpoint}/kinds/${kind}`).subscribe(
      (snippets: HtmlSnippet[]) => {
        this._snippets = snippets ? snippets.map(snippet => new HtmlSnippet().initialize(snippet)) : [];
        this._snippets$.next(this._snippets);
        console.log(this._snippets);
      },
      (error: any) => console.log('snippets load', error),
      () => this.state.resolveLoader(loader)
    );
  }

  load$(id: number): Observable<HtmlSnippet> {
    return this.httpClient.get<HtmlSnippet>(`${this.endpoint}/${id}`);
  }

  load(id: number): void {
    const loader = LOADERS.snippet;
    this.state.registerLoader(loader);
    this.load$(id).subscribe(
      (loadedSnippet: HtmlSnippet) => {
        this.refreshSnippet(new HtmlSnippet().initialize(loadedSnippet));
      },
      (error: any) => console.log('snippet load', error),
      () => this.state.resolveLoader(loader)
    );
  }


  // -------------------------------------- EDITING --------------------------------------

  prepare(snippet: HtmlSnippet): HtmlSnippet {
    const rows: any[] = snippet.page_json.page.rows;
    if (!!rows && rows.length > 0) {
      Object.keys(snippet.json).filter(property => property !== 'metadata').forEach(property => {
        if (rows[0][property]) {
          snippet.json[property] = rows[0][property];
        }
      });
    }
    return snippet;
  }

  save$(kind: HtmlSnippetKind, snippet: HtmlSnippet): Observable<HtmlSnippet> {
    return this.httpClient.post<HtmlSnippet>(`${this.endpoint}/kinds/${kind}`, snippet);
  }

  save(kind: HtmlSnippetKind, snippet: HtmlSnippet, successMessage: string = '', openBuilder: boolean = true) {
    if (openBuilder) {
      this.edit('empty');
      this.refreshSnippet(snippet);
    }

    const loader = this.state.registerLoader(LOADERS.snippet);
    this.save$(kind, snippet).subscribe(
      (savedSnippet: HtmlSnippet) => {
        const snippet = new HtmlSnippet().initialize(savedSnippet);
        this.refreshSnippet(snippet);
        this.refreshSnippetList(snippet, successMessage, true);

        if (openBuilder) {
          const currentUrl = this.location.path();
          this.location.replaceState(currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + savedSnippet.id);
        }
      },
      (error: any) => console.log('snippet save', error),
      () => this.state.resolveLoader(loader)
    );
  }

  edit(id: number | 'empty') {
    this.router.navigate([`${this.navUrl}/snippets/builder/${id}`]);
  }

  update$(snippet: HtmlSnippet): Observable<HtmlSnippet> {
    return this.httpClient.put<HtmlSnippet>(`${this.endpoint}/${snippet.id}`, this.prepare(snippet));
  }

  update(snippet: HtmlSnippet, successMessage: string = '', closeBuilder: boolean = false) {
    const loader = LOADERS.template;
    this.state.registerLoader(loader);
    this.update$(snippet).subscribe(
      (updatedSnippet: HtmlSnippet) => {
        const snippet = new HtmlSnippet().initialize(updatedSnippet);
        this.refreshSnippet(snippet);
        this.refreshSnippetList(snippet, successMessage);
      },
      (error: any) => console.log('snippet update', error),
      () => { 
        this.state.resolveLoader(loader);
        if (closeBuilder) {
          this.closeBuilder();
        }
      }
    );
  }

  closeBuilder() {
    this.router.navigate([`${this.navUrl}/snippets/list`]);
  }


  // -------------------------------------- PATCHES --------------------------------------

  patch$(id: number, updates: any[]): Observable<HtmlSnippet> {
    return this.httpClient.patch<HtmlSnippet>(`${this.endpoint}/${id}`, updates);
  }

  patch(id: number, updates: any[], message?: string) {
    const loader = this.state.registerLoader(LOADERS.snippet);
    this.patch$(id, updates).subscribe(
      (patchedSnippet: HtmlSnippet) => {
        const initSnippet = new HtmlSnippet().initialize(patchedSnippet);
        this.refreshSnippetList(initSnippet, message);
      },
      (error: any) => console.log('snippet patch', error),
      () => this.state.resolveLoader(loader)
    );
  }

  patchCategory(categoryId: string, snippets: HtmlSnippet[]): Observable<HtmlSnippet[]> {
    const requestBatch: Observable<HtmlSnippet>[] = [];

    snippets.forEach(snippet => {
      const updatedJSON: HtmlSnippetJson = JSON.parse(JSON.stringify(snippet.json));
      updatedJSON.metadata.category_id = categoryId;
      const updates = [
        { op: 'replace', field: 'json', value: updatedJSON },
        { op: 'replace', field: 'category_id', value: categoryId }
      ];
      requestBatch.push(this.patch$(snippet.id, updates));
    });

    return forkJoin(requestBatch).pipe(take(1));
  }


  // -------------------------------------- REFRESH --------------------------------------

  refreshSnippet(snippet: HtmlSnippet) {
    this._snippet$.next(snippet);
  }

  refreshSnippetList(updatedSnippet: HtmlSnippet, message = '', pushNew: boolean = false) {
    const index = this._snippets.findIndex(item => item.id === updatedSnippet.id);
    if (index >= 0) {
      this._snippets[index] = updatedSnippet;
      this._snippets$.next(this._snippets);
    } else if (pushNew) {
      this._snippets.push(updatedSnippet);
      this._snippets$.next(this._snippets);
    }

    this.notify.success(message);
  }


  // -------------------------------------- DELETING --------------------------------------

  delete$(id: number): Observable<any> {
    return this.httpClient.delete(`${this.endpoint}/${id}`);
  }

  delete(id: number, message?: string) {
    const loader = this.state.registerLoader(LOADERS.snippet);
    this.delete$(id).subscribe(
      () => {
        this._snippets = this._snippets.filter(snippet => snippet.id !== id);
        this._snippets$.next(this._snippets);

        this.notify.success(message);
      },
      (error: any) => console.log('snippet delete', error),
      () => this.state.resolveLoader(loader)
    );
  }

}
