/**
 * Usergroup represents information relevant to a client's data
 * @id id of usergroup (unique), e.g. 'bl'
 * @name name of usergorup, e.g. 'Billetlugen'
 */
export class Usergroup {
  id: string;
  env: 'dev' | 'prod';
  name: string;
  campaigns_default?: boolean;
  views: View[];
  config: UsergroupConfig;

  users?: any[];
  deleted?: boolean;

  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  constructor(input: any = {}) {
    this.id = input.id;
    this.env = input.env || 'prod';
    this.name = input.name || '';
    this.campaigns_default = this.campaigns_default;
    this.users = input.users;
    this.views = (!!input.views && input.views.length > 0)
      ? input.views.map(view => new View(view))
      : [];
    this.config = new UsergroupConfig(input.config);

    this.created_at = input.created_at;
    this.created_by = input.created_by;
    this.modified_at = input.modified_at;
    this.modified_by = input.modified_by;
  }

  get production(): boolean {
    return this.env === 'prod';
  }

  get dataset(): string {
    return this.config ? this.config.bigquery_dataset : undefined;
  }

  getAllViews(): View[] {
    return this.views.reduce((finalArray, view) => [...finalArray, view, ...view.getAllChildren()], []);
  }
}

export class View {
  id: string;
  string_id?: string; // remove after replacing it in all places
  name: string;
  children: View[];

  constructor(input: any = {}) {
    this.id = input.id || input.string_id;;
    this.string_id = this.id;
    this.name = input.name || '';
    this.children = (!!input.children && input.children.length > 0)
      ? input.children.map(child => new View(child))
      : [];
  }

  getAllChildren(): View[] {
    return this.children.reduce((finalArray, child) => [...finalArray, child, ...child.getAllChildren()], []);
  }
}

export class UsergroupConfig {
  bigquery_dataset: string;
  primary_accounts: string;
  sales_merge_key: string;

  constructor(input: any) {
    input = input || {};
    this.bigquery_dataset = input.bigquery_dataset;
    this.primary_accounts = input.primary_accounts || 'contacts';
    this.sales_merge_key = input.sales_merge_key || 'cid';
  }
}
