import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectableItem {
  label: string,
  sublabel?: string,
  selected?: boolean
}

export interface SelectDialogData {
  title: string,
  descriptions?: string[],
  items: SelectableItem[],
  multiselect: boolean
}


@Component({
  selector: 'ih-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.sass']
})
export class SelectDialogComponent implements OnInit {
  title: string;
  descriptions: string[];
  items: SelectableItem[];
  multiselect: boolean;
  selectedCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogData,
    public dialogRef: MatDialogRef<SelectDialogComponent>,
  ) { }

  cancel() {
    this.dialogRef.close();
  }

  selectItem(item: SelectableItem) {
    if (this.multiselect) {
      item.selected = !item.selected;
      this.selectedCount = this.items.filter(field => field.selected).length;
    } else {
      this.dialogRef.close(item);
    }
  }

  selectItems() {
    const selectedItems = this.items.filter(field => field.selected);
    this.dialogRef.close(selectedItems);
  }

  ngOnInit() {
    this.title = this.data.title;
    this.descriptions = this.data.descriptions || [];
    this.items = this.data.items || [];
    this.multiselect = this.data.multiselect;
    this.selectedCount = this.multiselect ? this.items.filter(field => field.selected).length : undefined;
  }

}
