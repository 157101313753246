import { BehaviorSubject, Subject, Observable, forkJoin } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common/';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AppStateService } from '@app/app.service';
import { LoggerService } from '@core/services/logger.service';
import { NotificationService } from '@core/services/notification.service';

import { Template } from '@shared/models/template.model';
import { LOADERS } from '@shared/models/loaders.model';
import { getAdminHeaders } from '@platform/platform.utils';

export class TestEmailPayload {
  subject?: string;
  preheader?: string;
  body?: string;
  item?: 'template'|'ingredient';
  id?: number;
}

export class PreviewEmailPayload extends TestEmailPayload {
  contact: any;
  data: any;
  from: string; 
}

export class SendTestPayload {
  kind: 'email'|'internal_email';
  test_recipients: Array<string>;
  actual_contacts: Array<string>;
  mock_contacts: any[];
  permission: string;
  email?: TestEmailPayload;
}

export interface TemplateDynamicData { dynamic: any, errors: string[] }


@Injectable()
export class TemplatesService {
  private endpoint: string = '/templates/emails';
  private navUrl: string = '/platform';
  private admin: boolean = false;

  private _templates: Template[] = [];

  private _template$ = new Subject<Template>();
  private _templates$ = new BehaviorSubject<Template[]>([]);
  private _standardTemplates$ = new BehaviorSubject<Template[]>([]);

  private _testEmailPayload: TestEmailPayload = {};
  private _testEmailPayload$ = new BehaviorSubject<TestEmailPayload>(undefined);

  private _templateData$ = new BehaviorSubject<TemplateDynamicData>(undefined);
  private testContactsSelection = [];
  private _testContactsSelection$ = new BehaviorSubject<any[]>([]);

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private logger: LoggerService,
    private notify: NotificationService,
    private router: Router,
    private location: Location
  ) { }

  get template$(): Observable<Template> {
    return this._template$.asObservable();
  }

  get templates$(): Observable<Template[]> {
    return this._templates$.asObservable();
  }

  get standardTemplates$(): Observable<Template[]> {
    return this._standardTemplates$.asObservable();
  }

  get testContactsSelection$(): Observable<any[]> {
    return this._testContactsSelection$.asObservable();
  }

  get templateData$(): Observable<TemplateDynamicData> {
    return this._templateData$.asObservable();
  }

  get testEmailPayload(): TestEmailPayload {
    return this._testEmailPayload$.getValue();
  }

  
  /**
   * Sets the mode of the template user
   * @param admin
   */
  setMode(admin: boolean = false): void {
    this.admin = admin;
    this.navUrl = admin ? '/admin' : '/platform';
    this.logger.log('templates for admin', this.admin);
  }


  // -------------------------------------- LOADING --------------------------------------

  loadStandardRequest$(templateId: number, dev: boolean): Observable<Template> {
    return this.httpClient
      .get<Template>(`${this.endpoint}/${templateId}`, getAdminHeaders(dev));
  }

  /**
   * Loads standard templates
   * and sets admin headers explicily
   */
  loadStandards(dev: boolean): void {
    const loader = LOADERS.templateStandards;
    this.state.registerLoader(loader);
    this.httpClient.get(`${this.endpoint}`, getAdminHeaders(dev))
      .subscribe(
        (templates: any) => this._standardTemplates$.next(templates),
        (error) => this.logger.handleError('standard template load', error),
        () => this.state.resolveLoader(loader)
      );
  }
  
  /**
   * Loads all templates for a given usergroup_id and view_id
   * and updates templates$ observable
   */
  loadAll(): void {
    const loader = LOADERS.templates;
    this.state.registerLoader(loader);
    this.httpClient.get<Template[]>(`${this.endpoint}`)
      .subscribe(
        (templates: any) => {
          this._templates = templates.map(template => new Template().initialize(template));
          this._templates$.next(this._templates);
        },
        (error) => this.logger.handleError('templates load', error),
        () => this.state.resolveLoader(loader)
      );
  }

  loadRequest$(templateId: number): Observable<Template> {
    return this.httpClient
      .get<Template>(`${this.endpoint}/${templateId}`);
  }

  /**
   * Loads a single template by Id and updates template$ observable
   */
  load(templateId: number): void {
    const loader = LOADERS.template;
    this.state.registerLoader(loader);
    this.httpClient
      .get(`${this.endpoint}/${templateId}`)
      .subscribe(
        (loadedTemplate) => {
          this.logger.log('loaded template', loadedTemplate);
          this.refreshTemplate(new Template().initialize(loadedTemplate));
        },
        (error: any) => this.logger.handleError('template load', error),
        () => this.state.resolveLoader(loader)
      );
  }


  // -------------------------------------- EDITING --------------------------------------

  save(template: Template, successMessage: string = '', openBuilder: boolean = true): void {
    if (openBuilder) {
      // Open builder for new template with empty id
      this.edit('empty');
      this.refreshTemplate(template);
    }

    const loader = LOADERS.template;
    this.state.registerLoader(loader);

    this.httpClient
      .post(`${this.endpoint}`, template)
      .subscribe(
        (savedTemplate: Template) => {
          this.logger.log('Saved template', savedTemplate);
          const initTemplate = new Template().initialize(savedTemplate);
          this.refreshTemplate(initTemplate);
          this.refreshTemplateList(initTemplate, successMessage, true);

          // Replace url path with real id
          if (openBuilder) {
            const currentUrl = this.location.path();
            this.location.replaceState(currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + savedTemplate.id);
          }
        },
        (error: any) => this.logger.handleError('template save', error),
        () => this.state.resolveLoader(loader)
      );
  }

  edit(templateId: number | 'empty') {
    this.router.navigate([`${this.navUrl}/templates/builder/${templateId}`]);
  }

  update(template: Template, successMessage: string = '', closeBuilder: boolean = false) {
    const loader = LOADERS.template;
    this.state.registerLoader(loader);
    this.httpClient
      .put( `${this.endpoint}/${template.id}`, template)
      .subscribe(
        (updatedTemplate: Template) => {
          this.refreshTemplateList(updatedTemplate, successMessage);
        },
        (error: any) => this.logger.handleError('template update', error),
        () => { 
          this.state.resolveLoader(loader);
          if (closeBuilder) {
            this.closeBuilder();
          }
        }
      );
  }

  closeBuilder() {
    this.router.navigate([`${this.navUrl}/templates/list`]);
  }


  // -------------------------------------- PATCHES --------------------------------------

  patch$(templateId: number, updates: any): Observable<Template> {
    return this.httpClient
      .patch<Template>(`${this.endpoint}/${templateId}`, updates).pipe(
        catchError(error => this.logger.handleError('template patch', error))
      );
  }

  patch(templateId: number, updates: any, message: string = '', updateCurrent: boolean = true) {
    const loader = LOADERS.template;
    this.state.registerLoader(loader);
    this.patch$(templateId, updates)
      .subscribe(
        (template: Template) => {
          const initTemplate = new Template().initialize(template);
          this.refreshTemplateList(initTemplate, message);
          if (updateCurrent) {
            this.refreshTemplate(initTemplate);
          }
        },
        (error: any) => this.logger.handleError('template patch', error),
        () => this.state.resolveLoader(loader)
      );
  }

  patchFolder(template: Template, folderId: number, message: string = '') {
    const updates = [{ op: 'replace', field: 'folder', value: folderId }];
    this.patch(template.id, updates, message, false);
  }

  patchFolderList$(templates: Template[], folderId: number): Observable<Template[]> {
    const requestBatch: Observable<Template>[] = [];
    templates.forEach(template => {
      const updates = [{ op: 'replace', field: 'folder', value: folderId }];
      requestBatch.push(this.patch$(template.id, updates));
    });
    return forkJoin(requestBatch).pipe(take(1));
  }


  // -------------------------------------- REFRESH --------------------------------------

  refreshTemplate(template: Template) {
    this._template$.next(template);
  }

  refreshTemplateList(updatedTemplate: Template, message = '', pushNew: boolean = false) {
    const index = this._templates.findIndex(item => item.id === updatedTemplate.id);
    if (index >= 0) {
      this._templates[index] = updatedTemplate;
      this._templates$.next(this._templates);
    } else if (pushNew) {
      this._templates.push(updatedTemplate);
      this._templates$.next(this._templates);
    }

    this.notify.success(message);
  }


  // -------------------------------------- DELETING --------------------------------------

  delete(templateId: number, message: string = '') {
    const loader = LOADERS.template;
    this.state.registerLoader(loader);
    this.httpClient
      .delete(`${this.endpoint}/${templateId}`)
      .subscribe(
        () => {
          const index = this._templates.findIndex(item => item.id === templateId);
          if (index >= 0) {
            this._templates.splice(index, 1);
          }
          this._templates$.next(this._templates);
          this.notify.success(message);
        },
        (error: any) => this.logger.handleError('template delete', error),
        () => this.state.resolveLoader(loader)
      );
  }

  
  // --------------------------- TEST EMAIL TEMPLATES (PAYLOAD, PREVIEW, SEND) --------------------------------------


  /**
   * Sets the test email payload and updates any 'dynamic' data included in the payload
   * (e.g. subject, preheader, body)
   */
   setTestEmailPayload(payload: TestEmailPayload) {
    this._templateData$.next(undefined); // reset templateData (if any), make sure dialogs obtain neweest
    
    Object.keys(payload).forEach(key => this._testEmailPayload[key] = payload[key] || '');
    this._testEmailPayload$.next(this._testEmailPayload);

    // update dynamic template data if we have an email body
    if (!!this._testEmailPayload && !!this._testEmailPayload.body) {
      this.getTemplateDynamicData$(this._testEmailPayload)
        .pipe(take(1))
        .subscribe((data) => {
          console.log(data);
          this._templateData$.next(data);
        });
    }
  }

  clearTestEmailPayload() {
    this._templateData$.next(undefined);
    this._testEmailPayload = {};
    this._testEmailPayload$.next(this._testEmailPayload);
  }

  
  // PREVIEW EMAIL

  /**
   * takes contact data and active data 
   * requests and returns SparkPost email preview given testEmailPayload (or current set payload)
   */
  getTemplatePreview$(contactData: any, activeData: any, testEmailPayload?: TestEmailPayload) {
    const loader = this.state.registerLoader(LOADERS.templateEmailPreview)
  
    const previewEmailPayload: PreviewEmailPayload = (!!testEmailPayload)
      ? testEmailPayload as PreviewEmailPayload
      : this._testEmailPayload$.getValue() as PreviewEmailPayload;

    // copy paylod to be sure not to overwirte
    const payload = JSON.parse(JSON.stringify(previewEmailPayload));
    payload.contact = contactData;
    payload.data = activeData;
    payload.from = 'preview@innohead.com'

    const url = '/utils/email-templates/preview';
    return this.httpClient.post(url, payload)
      .pipe(finalize(() => this.state.resolveLoader(loader)))
  }


  // SEND TEST EMAIL

  sendTemplateTest(payload: SendTestPayload) {
    const loader = this.state.registerLoader(LOADERS.templateEmailTest);
    
    // set current test email payload
    payload.email = this._testEmailPayload$.getValue();

    this.httpClient
      .post(`/utils/email-templates/send-test`, payload)
      .pipe(finalize(() => this.state.resolveLoader(loader)))
      .subscribe(
        (result: { success: boolean, message: string, not_found: string[] }) => {
          result.success 
            ? this.notify.success(result.message || 'The test email was sent') 
            : this.notify.error(result.message || 'The test email was not sent');

          if (result.not_found && result.not_found.length > 0) {
            result.not_found.forEach(cid => {
              this.notify.error(`Customer ID: ${cid} was not found`, 'Not Found');
            })
          }
        },
        (error: any) => this.logger.handleError('test template send', error)
      );
  }



  // DYNAMIC TEMPLATE DATA -------------------------------------------------------------------------------

  getTemplateDynamicData$(payload: TestEmailPayload): Observable<TemplateDynamicData> {
    const loader = this.state.registerLoader(LOADERS.templateDynamicData);
    return this.httpClient
      .post<TemplateDynamicData>("/utils/email-templates/dynamic-data", payload)
      .pipe(finalize(() => this.state.resolveLoader(loader)))
  }




  // TEST CONTACTS AND TEST CONTACTS SELECTION -----------------------------------------------------------

  /**
   * adds 'actual' contacts for 'preview' and 'test send' to testContactsSelection
   */
  addToTestContactsSelection(contact): void {
    // see if contact has already been added (existing uid without a testUid)
    const existing = this.testContactsSelection.find(item => item.uid === contact.uid && !item.testUid);
    if (!existing) {
      this.testContactsSelection.push(contact);
      this._testContactsSelection$.next(this.testContactsSelection);
    }
    console.log('selection', this.testContactsSelection);
  }

  /**
   * Updates an existing contact data. 
   * This can only take place for 'mock' contacts
   */
  updateTestContactInSelection(contact): void {
    const index = this.testContactsSelection.findIndex(item => item.testUid === contact.testUid);
    if (index >=0) {
      this.testContactsSelection[index] = contact;
      this._testContactsSelection$.next(this.testContactsSelection);
    }
  }

  /**
   * Removes a contact from the test selection
   * If a contact has a testUid (e.g. 'mock' and 'actual' test contacts) remove by 'testUid'
   * Otherwise remove by 'uid'
   */
  removeFromTestContactsSelection(contact): void {
    console.log('remove this contact', contact);    
    const matchKey = !!contact.testUid ? 'testUid' : 'uid';
    const index = this.testContactsSelection.findIndex(item => item[matchKey] === contact[matchKey]);
    console.log('>>', matchKey, index);
    this.testContactsSelection.splice(index, 1);
    this._testContactsSelection$.next(this.testContactsSelection);
    console.log('selection', this.testContactsSelection);
  }


  /**
   * updates testContactsSelection with the user's selected testContacts
   * makes sure that 'actual' contacts that are added are not removed
   */
  filterTestContactsSelection(contactsSelection) {
    // add all testContacts that is not already in the testContacts array
    contactsSelection.forEach(contact => {
      const found = this.testContactsSelection.find(item => item.testUid === contact.testUid)
      if (!found) {
        this.testContactsSelection.push(contact);
      }
    });
    
    // then remove all testContacts (all with existing testCid) that was not in incoming array
    const testUids = contactsSelection.map(testContact => testContact.testUid)
    this.testContactsSelection = this.testContactsSelection.filter((item) => (!item.testUid || testUids.includes(item.testUid)));
    this._testContactsSelection$.next(this.testContactsSelection);
    console.log('selection', this.testContactsSelection);
  }

  
 

}

