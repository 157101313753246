import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject } from 'rxjs';

// Wrap error returned from plugin
export class PluginError {
  title: string;
  error: any;

  constructor(title: string, error: any) {
    this.title = title;
    this.error = error;
  }

  private parseError(): string {
    let message: string = '';
    for (const property in this.error) {
      message += (property.toUpperCase() + ': ' + JSON.stringify(this.error[property]) + '\n');
    }
    return message;
  }

  get message(): string {
    return (this.error.message) 
      ? this.error.message :
      this.error.status 
        ? (this.error.status + ' - ' + this.error.statusText)
        : this.parseError();
  }
}

@Injectable()
export class PluginsService {

  private _beeApiKeys$: BehaviorSubject<any | PluginError>;

  constructor(
    private httpClient: HttpClient
  ) {
    this._beeApiKeys$ = new BehaviorSubject({});
  }

  get beeApiKeys$(): Observable<any> {
    return this._beeApiKeys$.asObservable();
  }

  loadBeeApiKeys() {
    this.httpClient
      .get('/apikeys/beeplugin')
      .subscribe(
        (apiKeys) => this._beeApiKeys$.next(apiKeys),
        (error: any) => this._beeApiKeys$.next(new PluginError('BEE Plugin API keys are not loaded!', error))
      );
  }

}