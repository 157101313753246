import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

declare const require: any;
const yaml = require('js-yaml');

@Component({
  selector: 'ih-json-viewer-dialog',
  templateUrl: './json-viewer-dialog.component.html',
  styleUrls: ['./json-viewer-dialog.component.sass']
})
export class JsonViewerDialogComponent implements OnInit {
  jsonFormatted: string;
  jsonParsed: string[];
  yamlFormatted: string;
  yamlParsed: string[];

  showFormat = 'json';
  copied: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {jsonString: string},
    public dialogRef: MatDialogRef<JsonViewerDialogComponent>,
  ) { }

  emptyContainer(prev: string, curr: string, next: string) {
    const emptyArray = (prev === '[' && curr === ']') || (curr === '[' && next === ']');
    const emptyObj = (prev === '{' && curr === '}') || (curr === '{' && next === '}');
    return emptyArray || emptyObj;
  }

  formatJson(jsonString: string) {
    const chars = jsonString.split('');
    let json = '';
    let inQuotes = false;
    let skipQuotes = 0;
    let level = 0;

    chars.forEach((c, i) => {
      if (inQuotes || this.emptyContainer(chars[i - 1], c, chars[i + 1])) {
        json += (c !== '"') ? c : '';
      } else {
        level += (c === '{' || c === '[') ? 1 : ((c === '}' || c === ']') ? -1 : 0);
        switch (c) {
          case '{':
          case ',':
            skipQuotes = 2;
          case '[':
            json += (c + '\n' + '  '.repeat(level));
            break;
          case '}':
          case ']':
            json += ('\n' + '  '.repeat(level) + c);
            break;
          case ':':
            json += (c + ' ');
            break;
          case '"':
            break;
          default:
            json += c;
        }
      }
      if (c === '"') {
        if (chars[i - 1] === '\\') {
          json += '"';
        } else {
          skipQuotes > 0 ? skipQuotes-- : json += '"';
          inQuotes = !inQuotes;
        }
      } 
    });
    return json;
  }

  parse(jsonFormatted: string) {
    const lines = jsonFormatted.split('\n').map(line => {
      const firstQuote = line.indexOf('"');
      const firstColon = line.indexOf(':');
      if (firstColon > -1 && (firstQuote === -1 || firstQuote > firstColon)) {
        const firstNonSpace = line.search(/\S/);
        const spaces = line.substr(0, firstNonSpace);
        const property = line.substr(firstNonSpace, firstColon - firstNonSpace);
        const rest = line.substr(firstColon);
        return `${spaces}<span class="property">${property}</span>${rest}`;
      } else {
        return line;
      }
    });
    return lines;
  }


  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {    
    this.jsonFormatted = this.formatJson(this.data.jsonString);
    this.jsonParsed = this.parse(this.jsonFormatted);

    const jsonObject = JSON.parse(this.data.jsonString);
    this.yamlFormatted = yaml.dump(jsonObject);
    this.yamlParsed = this.parse(this.yamlFormatted);
  }

}
