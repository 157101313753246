import { Directive, AfterViewInit, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

export interface DropResult {
  source: string,
  destination: string
}

// HOW TO USE:
// It needs a list of holders, each of which contains one draggable item
// Set directive for each holder, set id for each item
// ----------------------------------------------------------------------------------------------------
// When item is dragging its holder gets 'drag-source' class, hovered holder - 'drop-destination' class
// When draggable item is moved from one holder to another, directive emits source and destination ids
@Directive({
  selector: '[ihDraggable]'
})
export class DraggableDirective implements AfterViewInit {
  @Input() itemName: string;
  @Output() moved: EventEmitter<DropResult> = new EventEmitter<DropResult>()

  constructor(private elementRef: ElementRef) { }

  @HostListener('dragstart', ['$event'])
  dragStart(event) {
    event.dataTransfer.setData('id', event.target.id.replace(`${this.itemName}_`, ''));
    this.elementRef.nativeElement.classList.add('drag-source');
  }

  @HostListener('dragend', ['$event'])
  dragEnd(event) {
    this.elementRef.nativeElement.classList.remove('drag-source');
  }

  @HostListener('dragover', ['$event'])
  dragOver(event) {
    event.preventDefault();
    if (!this.elementRef.nativeElement.classList.contains('drop-destination')) {
      this.elementRef.nativeElement.classList.add('drop-destination');
    }
  }

  @HostListener('dragleave', ['$event'])
  dragLeave(event) {
    event.preventDefault();
    if (this.elementRef.nativeElement.classList.contains('drop-destination')) {
      this.elementRef.nativeElement.classList.remove('drop-destination');
    }
  }

  @HostListener('drop', ['$event'])
  drop(event) {
    event.preventDefault();
    if (this.elementRef.nativeElement.classList.contains('drop-destination')) {
      this.elementRef.nativeElement.classList.remove('drop-destination');
    }

    const child = this.elementRef.nativeElement.children[0];
    const currentId = child ? child.id.replace(`${this.itemName}_`, '') : undefined;
    const droppedId = event.dataTransfer.getData('id');

    if (currentId && droppedId) {
      (currentId != droppedId)
      ? this.moved.emit({ source: droppedId, destination: currentId })
      : console.log('Item is dropped out in the same place');
    } else {
      console.log('Id of ' + (currentId ? 'dropped' : 'current') + ' item is missing');
    }
  }

  ngAfterViewInit() {
  }

}