import { CategoryConfig, FilterConfig } from '@shared/models/filters-selector-config.model';
import { createAction, props } from '@ngrx/store';
import { User, UserPreferences, UsergroupAccess, ViewAccess } from '@shared/models/user';
import { TablesConfig } from '@shared/models/usergroup-config.model';
import { Usergroup } from '@shared/models/usergroup';
import { ViewConfig } from '@shared/models/view-config.model';
import { ActiveMenu } from '@shared/models/menu.model';
import { Flow } from '@shared/models/flow.model';
import { FlowNode } from '@shared/models/node.model';
import { ActivePage } from './app-state.model';


export const setUser = createAction(
  'SET_USER', (user: User) => ({user})
);
export const setUserPreferences = createAction(
  'SET_USER_PREFERENCES', (preferences: UserPreferences) => ({preferences})
);
export const setUsergroupAccess = createAction(
  'SET_USERGROUP_ACCESS', props<UsergroupAccess>()
);
export const setViewAccess = createAction(
  'SET_VIEW_ACCESS', props<ViewAccess>()
);
export const setLoginStatus = createAction(
  'SET_LOGIN_STATUS', props<{login: boolean}>()
);
export const setLoginGroups = createAction(
  'SET_LOGIN_GROUPS', (groups: Usergroup[]) => ({groups})
);


export const setViewConfig = createAction(
  'SET_VIEW_CONFIG', (config: ViewConfig) => ({config})
);
export const setListSorting = createAction(
  'SET_LIST_SORTING', props<{ list_name: string, option_name: string }>()
);
export const setTables = createAction(
  'SET_TABLES', (config: TablesConfig) => ({config})
);
export const setTriggerCategories = createAction(
  'SET_TRIGGER_CATEGORIES', (categories: Map<string, CategoryConfig>) => ({categories})
)
export const setTriggerFilters = createAction(
  'SET_TRIGGER_FILTERS', (triggers: Map<string, FilterConfig>) => ({triggers})
);
export const setAudienceCategories = createAction(
  'SET_AUDIENCE_CATEGORIES', (categories: Map<string, CategoryConfig>) => ({categories})
);
export const setAudienceFilters = createAction(
  'SET_AUDIENCE_FILTERS', (filters: Map<string, FilterConfig>) => ({filters})
);
export const setProductCategories = createAction(
  'SET_PRODUCT_CATEGORIES', (categories: Map<string, CategoryConfig>) => ({categories})
);
export const setProductFilters = createAction(
  'SET_PRODUCT_FILTERS', (filters: Map<string, FilterConfig>) => ({filters})
);


export const setActivePage = createAction(
  'SET_ACTIVE_PAGE', props<ActivePage>()
);
export const setActiveMenu = createAction(
  'SET_ACTIVE_MENU', props<ActiveMenu>()
);
export const setActiveFlow = createAction(
  'SET_ACTIVE_FLOW', (flow: Flow) => ({flow})
);
export const setActiveNode = createAction(
  'SET_ACTIVE_NODE', (node: FlowNode) => ({node})
);
export const registerLoader = createAction(
  'REGISTER_LOADER', props<{ name: string, id: any }>()
);
export const resolveLoader = createAction(
  'RESOLVE_LOADER', props<{ name: string, id: any }>()
);