<ng-container *ngIf="selectionLength > 0; else folderManager">
  <span class="font-topbar space-right-5">
    <strong>{{ selectionLength }}</strong> Selected
  </span>
  <button mat-icon-button (click)="move()" matTooltip="Move to folder">
    <mat-icon>folder</mat-icon>
  </button>
  <button mat-icon-button (click)="clear()" matTooltip="Deselect">
    <mat-icon>indeterminate_check_box</mat-icon>
  </button>
  <button mat-icon-button (click)="delete()" matTooltip="Remove">
    <mat-icon>delete</mat-icon>
  </button>
</ng-container>

<ng-template #folderManager>
  <button mat-icon-button (click)="manage()" matTooltip="Manage folders">
    <mat-icon>work_outline</mat-icon>
  </button>
</ng-template>