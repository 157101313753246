<div mat-dialog-title>
  Modify User Permissions
</div>

<mat-dialog-content [@.disabled]="disableAnimation">

    <mat-accordion>
      <mat-expansion-panel *ngFor="let route of routes" (opened)="setCurrentRoute(route)">
        <mat-expansion-panel-header>
          <mat-panel-title [ngClass]="{ 'is-bold': route.value === currentRoute?.value }">
            {{ route.label }}
            <span class="is-normal tc-grey-500 space-left-10">
              {{ permissionLabels[ permissions[route.value].ability ] || 'No access' }}
              {{ permissions[route.value]?.restrictions?.length > 0 ? '(with restrictions)' : '' }}
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
    
        <ng-container>
          <div class="permission" fxLayout="row" fxLayoutAlign="start center">
            <span>User access level</span>
            <span fxFlex></span>
            <mat-form-field floatLabel="never">
              <mat-label>User Role</mat-label>
              <mat-select [(ngModel)]="permissions[route.value].ability" (selectionChange)="updatePermission(route)">
                <mat-option *ngFor="let option of permissionOptions" [value]="option.ability">{{ option.label }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="restrictionOptions">
            <div class="restriction" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of restrictionOptions">
              <span>{{ item.label }}</span>
              <span fxFlex></span>
              <mat-slide-toggle [checked]="permissions[route.value].restrictions.includes(item.ability)" (change)="updateRestrictions($event, route, item)"></mat-slide-toggle>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <div fxFlex></div>
  <button mat-raised-button color="primary" (click)="select()">Select</button>
</mat-dialog-actions>
