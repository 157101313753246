<section class="legal-header" fxLayout="strech" fxLayoutAlign="center center">
  <h1>Legal, Terms and Policies</h1>
</section>

<section class="page-content" *ngIf="legalPage else showMenu" fxLayout="row" fxLayoutAlign="stretch" fxLayoutGap="20px">
  <mat-card *ngFor="let policy of policies" fxFlex="33">
    <mat-card-title>{{ policy.label }}</mat-card-title>
    <mat-card-content>{{ policy.description}}</mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" [routerLink]="[policy.key]">Read more</button>
    </mat-card-actions>
  </mat-card>
</section>

<ng-template #showMenu>
  <mat-toolbar class="legal-menu" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <a *ngFor="let policy of policies" [routerLink]="[policy.key]" routerLinkActive='active'>
      {{ policy.key }}
    </a>
  </mat-toolbar>
</ng-template>
<router-outlet></router-outlet>
