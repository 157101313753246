import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[ihFlowBackground]'
})
export class FlowBackgroundDirective implements OnInit{
  @Input() nameId: string;
  background: string;
  items = [
    { name: 'new_lead', background: '#6202EA' },
    { name: 'cold_lead', background: '#2962FF' },
    { name: 'revived_lead', background: '#C51162' },
    { name: 'signup', background: '#02B8D4' },
    { name: 'first_purchase', background: '#DD2C00' },
    { name: 'purchase_amount', background: '#AEEA01' },
    { name: 'new_products', background: '#FFAB02' },
    { name: 'product_recommendations', background: '#00C853' },
    { name: 'product_recommendations_short', background: '#FF6D00' },
    { name: 'customer_activation', background: '#00BFA5' },
    { name: 'reward', background: '#304FFE' },
    { name: 'anti_churn', background: '#AA02FF' },
    { name: 'abandoned_basket', background: '#0391EA' },
    { name: 'new_category', background: '#304FFE' },
    { name: 'tor', background: '#D50300' },
    { name: 'anti_churn', background: '#0391EA' },
    { name: 'high_inventory', background: '#FFAB02' },
    { name: 'sale', background: '#DD2C00' },
    { name: 'christmas', background: '#C51162' },
    { name: 'specific_date', background: '#2962FF' }
  ];

  constructor(private elementRef: ElementRef) { }

  getBackground(nameId) {
    const target = this.items.find((item) => item.name === nameId);
    return target.background;
  }

  ngOnInit() {
    console.log('>', this.nameId);
    this.background = this.getBackground(this.nameId);
    this.elementRef.nativeElement.style.backgroundColor = this.background;
  }
}
