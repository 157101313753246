<div mat-dialog-title>
  {{ processing ? 'Please wait' : 'Select View' }}
</div>

<mat-dialog-content>
  <ng-container *ngIf="processing; else showSelection">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="changing-views">
        <div class="item-icon mat-raised">
          <i class="material-icons mat-72">exit_to_app</i>
        </div>
        <div class="item-title">
          Switching Views
        </div>
        <div class="item-text tc-grey-600">
          Please wait while your view is being loaded.
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #showSelection>
    <mat-list class="full-width space-bottom-15">
      <ng-container *ngFor="let group of loginGroups">
        <mat-list-item>
          <strong>{{ group.name }}</strong>
        </mat-list-item>
        <mat-list-item *ngFor="let view of group.views">
          <span fxFlex="nogrow" class="space-right-10">&bull;</span>
          <span fxFlex class="tc-grey-600">{{ view.name }}</span>
          <span fxFlex="nogrow">
            <button mat-raised-button (click)="process(group, view)">Select</button>
          </span>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!processing">
  <button mat-button (click)="close()">
    Cancel
  </button>
</mat-dialog-actions>