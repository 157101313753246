
<div fxLayout="column" fxLayoutAlign="center center" fxFlex>

  <!-- need a card container for margin-top (input lines disappear if margin is applied the card) -->
  <section class="card-container">
    
    <mat-card class="card-rounded">
      <mat-card-title>
        {{ setupPlatform ? 'Welcome ' + firstName : 'Please Log In' }}
      </mat-card-title>

      <mat-card-content>
        <form class="login-form" #loginForm="ngForm">
          <mat-form-field class="full-width">
            <input id="email" matInput placeholder="Email" type="email" name="email" [(ngModel)]="input.email" #userControl="ngModel" required [disabled]="setupPlatform">
            <mat-hint align="end">
              <span [hidden]="!userControl.errors?.required || userControl.pristine" class="tc-red-600">
                Required
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width">
            <input id="password" matInput placeholder="Password" type="password" name="password" [(ngModel)]="input.password" #passControl="ngModel" required [disabled]="setupPlatform">
            <mat-hint align="end">
              <span [hidden]="!passControl.errors?.required || passControl.pristine" class="tc-red-600">
                Required
              </span>
            </mat-hint>
          </mat-form-field>
        </form>
      </mat-card-content>

      <mat-card-actions>
        <button class="full-width" mat-raised-button color="primary" flex [disabled]="!loginForm.form.valid" (click)="login()">
          <i *ngIf="loadingUser || setupPlatform" class="fa fa-spinner fa-pulse"></i>
          <mat-icon *ngIf="!loadingUser && !setupPlatform">exit_to_app</mat-icon>
          <span class="space-left-5">
            {{ setupPlatform ? 'PLEASE WAIT' : 'SIGN IN' }}
          </span>
        </button>
      </mat-card-actions>

    </mat-card>
  </section>

  <div class="space-top-15">
    <p *ngIf="updatingUser; else canUpdate">
      <i class="fa fa-spinner fa-pulse space-right-10"></i>
      Resetting Password
    </p>
    <ng-template #canUpdate>
      <a class="text-md text-link" (click)="showForgotPasswordDialog()">Forgot password?</a>
    </ng-template>
  </div>
</div>

