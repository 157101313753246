import { first } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MoveItemsDialogComponent } from '@shared/components/move-items-dialog/move-items-dialog.component';
import { ManageFoldersDialogComponent } from '@shared/components/manage-folders-dialog/manage-folders-dialog.component';
import { Folder } from '@shared/models/view-config.model';

@Component({
  selector: 'ih-selection-actions',
  templateUrl: './selection-actions.component.html',
  styleUrls: ['./selection-actions.component.sass']
})
export class SelectionActionsComponent implements OnInit {
  @Input() kind: string;
  @Input() selectionLength: number;
  @Output() actionSelected = new EventEmitter<IAction>();

  actions: any;

  constructor(
    public dialog: MatDialog
  ) { }

  move() {
    this.dialog.open(MoveItemsDialogComponent, {
      width: '50%',
      data: { kind: this.kind.toLowerCase(), listLength: this.selectionLength },
      panelClass: 'ih-dialog'
    }).afterClosed().pipe(first())
      .subscribe((folderId: number | string) => {
        if (folderId !== undefined) {
          const actionData = { folder_id: folderId };
          this.actionSelected.emit({ action: this.actions.MOVE, data: actionData });
        }
      });
  }

  clear() {
    this.actionSelected.emit({ action: this.actions.CLEAR });
  }

  delete() {
    this.actionSelected.emit({ action: this.actions.DELETE });
  }

  manage() {
    this.dialog.open(ManageFoldersDialogComponent, {
      width: '500px',
      data: { kind: this.kind.toLowerCase() },
      panelClass: 'ih-dialog'
    }).afterClosed()
      .subscribe((deletedFolders: Folder[]) => {
        if (deletedFolders) {
          const actionData = { deleted_folders: deletedFolders };
          this.actionSelected.emit({ action: this.actions.MANAGE, data: actionData });
        }
      });
  }


  ngOnInit() {
    this.actions = ACTIONS;
  }

}

export interface IAction { action: number, data?: any }

export const ACTIONS = { MOVE: 1, CLEAR: 2, DELETE: 3, MANAGE: 4 }