import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ih-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.sass']
})
export class EmptyPageComponent implements OnInit {
  @Input() header: string;
  @Input() explanation: string;
  @Input() listItem: string;
  @Input() listLength: number;
  @Input() listCreation: string;
  @Input() loading: string;

  mode: 'default' | 'list' | 'loading';

  constructor() { }

  ngOnInit() {
    this.mode = (this.listItem)
      ? 'list'
      : (this.loading ? 'loading' : 'default')
  }

}
