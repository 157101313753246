import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@core/services/logger.service';
import { StorageService } from '@core/services/storage.service';


@Component({
  selector: 'ih-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.sass']
})
export class PublicComponent implements OnInit, AfterViewInit, OnDestroy {
  public innoheadLogo = '../../assets/img/innohead-text-logo.png';

  loadingPlatform: boolean = false;
  isLoggedIn: boolean = false;
  
  loginUrl: string = '/login';
  
  private unsubscribe$ = new Subject<any>();
  
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  login() {
    this.router.navigate(['/login']);
  }

  goToPlatform() {
    this.loadingPlatform = true;
    this.router.navigate(['/platform']);
  }

  ngOnInit() {
    this.authService.checkIfLoggedIn();

    this.authService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        
        if (isLoggedIn === true) {
          this.goToPlatform();
        } 
      });
  }

  ngAfterViewInit() {
    const globalPreloader = document.getElementById('global-preloader');
    globalPreloader ? globalPreloader.style.display = 'none' : {};
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
