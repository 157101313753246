<div mat-dialog-title>
  {{ title }}
</div>

<mat-dialog-content>
  <div *ngIf="descriptions.length > 0" class="tc-grey-600">
    <p *ngFor="let description of descriptions">{{ description }}</p>
  </div>

  <mat-selection-list *ngIf="!multiselect">
    <mat-list-option *ngFor="let item of items" (click)="selectItem(item)">
      <div mat-line>{{item.label}}</div>
      <div mat-line *ngIf="!!item.sublabel" class="is-small tc-grey-500">{{item.sublabel}}</div>
    </mat-list-option>
  </mat-selection-list>

  <mat-selection-list *ngIf="multiselect">
    <mat-list-option *ngFor="let item of items" [selected]="item.selected" (click)="selectItem(item)">
      <div mat-line>{{item.label}}</div>
      <div mat-line *ngIf="!!item.sublabel" class="is-small tc-grey-500">{{item.sublabel}}</div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <span fxFlex></span>
  <button mat-raised-button *ngIf="multiselect" [disabled]="selectedCount <= 0" color="primary" (click)="selectItems()">Select</button>
</mat-dialog-actions>