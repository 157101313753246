import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(
    private toastr: ToastrService
  ) { }

  info(message?: string, title?: string) {
    if (message) {
      this.toastr.info(message, title ? title : 'Info');
    }
  }

  success(message?: string, title?: string) {
    if (message) {
      this.toastr.success(message, title ? title : 'Success');
    }
  }

  error(message?: string, title?: string) {
    if (message) {
      this.toastr.error(message, title ? title : 'Error');
    }
  }

  shortInfo(message: string) {
    this.toastr.info(message);
  }

  shortSuccess(message: string) {
    this.toastr.success(message);
  }

  shortError(message: string) {
    this.toastr.error(message);
  }

  persistentError(message?: string, title?: string) {
    if (message) {
      this.toastr.error(message, title ? title : 'Error', { disableTimeOut: true });
    }
  }

  progress(message: string): ProgressNotification {
    return new ProgressNotification(this.toastr, message);
  }

}


/** Progress notification with spinner, has no timeout and is shown until finished directly */
export class ProgressNotification {
  private toast: ActiveToast<any>;

  constructor(toastr: ToastrService, progressMessage: string) {
    this.toast = toastr.info(
      `<i class="fa fa-spinner fa-pulse progress-icon"></i><span>${progressMessage}...</span>`,
      undefined,
      { disableTimeOut: true, enableHtml: true, toastClass: 'ngx-toastr toast-info in-progress' }
    );
  }

  finish(result: 'success' | 'error', resultMessage: string) {
    const component = this.toast.toastRef.componentInstance;
    component.toastClasses = `ngx-toastr toast-${result} in-progress`;
    const iconClass = result === 'success' ? 'fas fa-check' : 'fas fa-times-circle';
    component.message = `<i class="${iconClass} progress-icon"></i><span>${resultMessage}</span>`;
    setTimeout(() => this.toast.toastRef.manualClose(), 3000);
  }

  success(message: string) {
    this.finish('success', message);
  }

  error(message: string) {
    this.finish('error', message);
  }

  // Progress notification usage
  // ================================================================
  // const progress = this.notify.progress('Loading data');
  // this.httpClient.get('request-url').subscribe(
  //   (data) => progress.success('Data loaded successfully'),
  //   (error) => progress.error('Something went wrong')
  // );
}