class Loaders {
  login: any = false;
  user: any = false;
  getUser: any = false;
  saveUser: any = false;
  updateUser: any = false;
  deleteUser: any = false;
  addUser: any = false;
  removeUser: any = false;
  users: any = false;

  usergroup: any = false;
  usergroups: any = false;
  config: any = false;

  accessPoint: any = false;
  accessPoints: any = false;

  audienceSave: any = false;
  audienceLoad: any = false;
  audienceDelete: any = false;
  audienceQuery: any = false;
  audiences: any = false;

  flow: any = false;
  flowDelete: any = false;
  flows: any = false;
  flowStandards: any = false;
  nodes: any = {}; // requires in id

  profilePermissions: any = false;

  query: any = false;
  productGroup: any = false;
  productGroups: any = false;
  productsQuery: any = false;
  productsSimilarQuery: any = false;
  profilesQuery: any = false;
  profileQuery: any = false;
  profileLogs: any = {}; // requires in id

  setupPlatform: any = false;
  help: any = false;

  snippet: any = false;
  snippets: any = false;

  template: any = false;
  templates: any = false;
  templateStandards: any = false;
  templateEmailTest: any = false;
  templateEmailPreview: any = false;
  templateDynamicData: any = false;

  reportFlowSummary: any = false;
  reportNodePerformance: any = false;
  reportNodeActivity: any = {};
  reportNodeLinks: any = false;

  constructor(type?: string) {
    if (type === 'keys') {
      Object.keys(this).forEach(key => {
        this[key] = key;
      });
    }
  }
}

export const INITIAL_LOADERS = new Loaders();
export const LOADERS = new Loaders('keys');
