<div mat-dialog-title>
  View as {{ showFormat | uppercase }}
</div>

<mat-dialog-content>
  <ng-container [ngSwitch]="showFormat">
    <ng-container *ngSwitchCase="'json'">
      <pre *ngFor="let line of jsonParsed" [innerHtml]="line"></pre>
    </ng-container>
    <div *ngSwitchCase="'yaml'">
      <pre *ngFor="let line of yamlParsed" [innerHtml]="line"></pre>
    </div>
  </ng-container>
  
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
  <div>
    <button mat-button (click)="close()">Close</button>
  </div>
  <div fxFlex></div>
  <div>
    <button mat-button (click)="showFormat = 'json'">json</button>
    <button mat-button (click)="showFormat = 'yaml'">yaml</button>
    <button mat-raised-button color="primary" class="copy-button"
      [disabled]="copied" [cdkCopyToClipboard]="showFormat === 'json' ? jsonFormatted : yamlFormatted" (click)="copied = true">
      {{ copied ? 'Copied!' : 'Copy to clipboard' }}
    </button>
  </div>
</mat-dialog-actions>
