import { forkJoin, Observable, BehaviorSubject, from } from 'rxjs';
import { take, skipWhile } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common/';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, PRIMARY_OUTLET } from '@angular/router';

import { AppStateService } from '@app/app.service';
import { LoggerService } from '@core/services/logger.service';
import { NotificationService } from '@core/services/notification.service';

import { Flow } from '@shared/models/flow.model';
import { FlowNode } from '@shared/models/node.model';
import { Requirement } from '@shared/models/requirements.model';
import { LOADERS } from '@shared/models/loaders.model';
import { getAdminHeaders } from '@platform/platform.utils';


@Injectable()
export class FlowsService implements OnDestroy {
  private baseUrl: string = '/platform';
  private mode: 'flow' | 'report' | 'campaign-builder' | 'campaign-viewer' = 'flow';
  private endpoint: string = '/flows';

  private _flow: Flow;
  private _flows: Flow[] = [];
  private _currentFlowHash: string;

  private _flow$ = new BehaviorSubject<Flow>(undefined);
  private _flows$ = new BehaviorSubject<Flow[]>([]);
  private _standardFlows$ = new BehaviorSubject<Flow[]>([]);
  private _currentFlowHash$ = new BehaviorSubject<string>(undefined);
  private _executionType$ = new BehaviorSubject<'batch' | 'streaming'>('batch');
  private _validation$ = new BehaviorSubject<Requirement>(undefined);

  private locationSubscription;

  constructor(
    private state: AppStateService,
    private logger: LoggerService,
    private notify: NotificationService,
    private httpClient: HttpClient,
    private router: Router,
    private location: Location
  ) {
    this.locationSubscription = this.location.subscribe(value => {
      this.refreshView(value.url);
    });
  }

  get flow$(): Observable<Flow> {
    return this._flow$.asObservable();
  }

  get flows$(): Observable<Flow[]> {
    return this._flows$.asObservable();
  }

  get standardFlows$(): Observable<Flow[]> {
    return this._standardFlows$.asObservable();
  }

  get executionType$(): Observable<'batch' | 'streaming'> {
    return this._executionType$.asObservable();
  }

  get validation$(): Observable<Requirement> {
    return this._validation$.asObservable();
  }

  getMode(): string {
    return this.mode;
  }

  /**
   * Modes for routing/menu navigation:
   * - flow - user's flow editor in Campaigns section
   * - report - flow report viewer in Campaigns section
   * - campaign-builder - user's flow editor in Reports section
   * - campaign-viewer - flow report viewer in Reports section
   */
  setMode(mode: 'flow' | 'report' | 'campaign-builder' | 'campaign-viewer') {
    this.mode = mode;
  }

  setFlow(flow: Flow): Flow {
    const currentFlow = new Flow().initialize(flow);
    this._currentFlowHash$ = new BehaviorSubject(currentFlow.hash);

    this._currentFlowHash$.subscribe((updatedFlowHash: string) => {
      this._currentFlowHash = updatedFlowHash;
      console.log('>> current flow hash', updatedFlowHash);
    });

    return currentFlow;
  }

  deactivateNode() {
    this.state.setActiveNode(undefined);
  }

  deactivateFlow(completeCurrent: boolean = true) {
    this._flow = undefined;
    this._flow$.next(this._flow);
    if (completeCurrent) {
      this._currentFlowHash$.next(undefined);
      this._currentFlowHash$.complete();
    }
    this.state.setActiveFlow(undefined);
  }

  getCurrentFlowHash(flow: Flow) {
    return (this._currentFlowHash$) ? (this._currentFlowHash$.value || flow.hash) : flow.hash;
  }

  updateCurrentFlowHash(hash: string) {
    if (this._currentFlowHash) {
      this._currentFlowHash$.next(hash);
    }
  }


  // -------------------------------------- STATUSES --------------------------------------

  activateListening(flow: Flow) {
    const newState = 'active';
    const message = 'This flow is now active and listening';
    this.updateFlowState(flow, newState, message);
  }

  pauseListening(flow: Flow) {
    const newState = 'paused';
    const message = 'The flow has been paused';
    this.updateFlowState(flow, newState, message);
  }

  completeListening(flow: Flow) {
    const newState = 'completed';
    const message = 'The flow has been completed';
    this.updateFlowState(flow, newState, message);
  }

  updateFlowState(flow: Flow, newState: string, message: string) {
    console.assert(['active', 'paused', 'completed'].includes(newState));
    const loader = this.state.registerLoader(LOADERS.flow);

    switch (newState) {
      case 'active': flow.activate(); break;
      case 'paused': flow.pause(); break;
      case 'completed': flow.complete(); break;
    }

    flow.lockStatus();
    this._flow$.next(flow);

    const updates = [{ op: 'change_state', value: newState }];

    this.update$(flow).pipe(skipWhile(response => response === undefined), take(1))
      .subscribe(
        (updateResponse) => {
          const updatedFlow = new Flow().initialize(updateResponse);
          this.updateCurrentFlowHash(updatedFlow.hash);

          this.patch$(updatedFlow, updates).pipe(take(1))
            .subscribe(
              (patchResponse) => {
                const newStateFlow = new Flow().initialize(patchResponse);
                flow.newStatus(newStateFlow.status);

                this.refreshFlowAndHash(newStateFlow);
                this.refreshFlowList(newStateFlow, message);
              },
              (error: any) => this.logger.handleError('flow patch', error),
              () => this.state.resolveLoader(loader)
            );
        },
        (error: any) => {
          this.logger.handleError('flow update', error);
          this.state.resolveLoader(loader);
        }
      );
  }


  // -------------------------------------- LOADING --------------------------------------

  loadStandards(): void {
    const loader = LOADERS.flowStandards;
    this.state.registerLoader(loader);
    this.httpClient.get(`${this.endpoint}`, getAdminHeaders(false))
      .subscribe(
        (flows: any) => this._standardFlows$.next(flows),
        (error) => this.logger.handleError('standard flow load', error),
        () => this.state.resolveLoader(loader)
      );
  }

  loadAll$(states?: string[]): Observable<Flow[]> {
    let queryParams = new HttpParams();
    if (states) {
      states.forEach(state => queryParams = queryParams.append('state[]', state));
    }
    return this.httpClient.get<Flow[]>(`${this.endpoint}`, { params: queryParams });
  }

  loadAll(states?: string[], deferred_states?: string[]): void {
    const loader = this.state.registerLoader(LOADERS.flows);
    this.loadAll$(states).subscribe(
      (flows: Flow[]) => {
        this._flows = flows.map(flow => new Flow().initialize(flow, true));
        this._flows$.next(this._flows);

        // Load and append deferred flows later
        if (!!deferred_states && deferred_states.length > 0) {
          this.loadAll$(deferred_states).subscribe(
            (deferredFlows: Flow[]) => {
              this._flows.push(...deferredFlows.map(flow => new Flow().initialize(flow, true)));
              this._flows$.next(this._flows);
              console.log(`Add ${deferredFlows.length} deferred flows`);
            },
            (error) => this.logger.handleError('deferred flows load', error)
          );
        }
      },
      (error) => this.logger.handleError('flows load', error),
      () => this.state.resolveLoader(loader)
    );
  }

  loadRequest$(flowId: number): Observable<Flow> {
    return this.httpClient.get<Flow>(`${this.endpoint}/${flowId}`);
  }

  load(flowId: number): void {
    const loader = this.state.registerLoader(LOADERS.flow);
    this.loadRequest$(flowId).subscribe(
      (flow) => {
        const flowItem: Flow = new Flow().initialize(flow);
        this._flow = flowItem;
        this._flow$.next(flowItem);
        this.updateCurrentFlowHash(flowItem.hash);
      },
      (error) => this.logger.handleError('flow load', error),
      () => this.state.resolveLoader(loader)
    );

  }


  // -------------------------------------- EDITING --------------------------------------

  save(flow: Flow, successMessage: string = '', openBuilder: boolean = true): void {
    const loader = this.state.registerLoader(LOADERS.flow);
    this._validation$.next(flow.validate());
    this.httpClient
      .post(`${this.endpoint}`, flow)
      .subscribe(
        (response: Flow) => {
          this.logger.log('Saved flow', response);
          const savedFlow = new Flow().initialize(response);

          this.refreshFlowAndHash(savedFlow);
          this.refreshFlowList(savedFlow, successMessage, true);
          if (openBuilder) {
            this.edit(savedFlow.id);
          }
        },
        (error: any) => this.logger.handleError('flow save', error),
        () => this.state.resolveLoader(loader)
      );
  }

  copy(flow: Flow, successMessage: string = '', adminSource: boolean = false): void {
    const loader = this.state.registerLoader(LOADERS.flows);

    this.httpClient.get<Flow>(`${this.endpoint}/${flow.id}/copy`)
      .subscribe(
        (copiedFlow: Flow) => {
          const initCopiedFlow = new Flow().initialize(copiedFlow);
          this.logger.log('Copied flow', initCopiedFlow);
          this.refreshFlowList(initCopiedFlow, successMessage, true);

          if (adminSource) {
            this._flow = initCopiedFlow;
            this._flow$.next(this._flow);
            this.edit(copiedFlow.id);
          }
        },
        (error: any) => this.logger.handleError('flow copy', error),
        () => this.state.resolveLoader(loader)
      );
  }

  // do not use for reports in report list
  edit(flowId: number) {
    this.router.navigate([`${this.baseUrl}/campaigns/${this.mode}/${flowId}/tree`]);
  }

  update$(flow: Flow): Observable<Flow> {
    this._validation$.next(flow.validate());
    flow.hash = this.getCurrentFlowHash(flow);
    return this.httpClient.put<Flow>(`${this.endpoint}/${flow.id}`, flow);
  }

  /**
   * Updates flow
   * If user is editing current flow, apply current flow HASH to flow object before save
   */
  update(flow: Flow, successMessage: string = '', closeBuilder: boolean = false): void {
    console.log('<PUT> flow', flow);
    const loader = this.state.registerLoader(LOADERS.flow);
    flow.hash = this.getCurrentFlowHash(flow);
    this.update$(flow).subscribe(
      (response: Flow) => {
        const updatedFlow = new Flow().initialize(response, true);
        this.refreshFlowAndHash(updatedFlow);
        this.refreshFlowList(updatedFlow, successMessage);
      },
      (error) => this.logger.handleError('flow update', error),
      () => { 
        this.state.resolveLoader(loader);
        if (closeBuilder) {
          this.closeBuilder();
        }
      }
    );
  }

  closeBuilder() {
    if (this.mode === 'campaign-builder') {
      this.router.navigate([`${this.baseUrl}/reports/campaign-list`]);
    } else {
      this.router.navigate([`${this.baseUrl}/campaigns/list`]);
    }
  }


  // -------------------------------------- PATCHES --------------------------------------

  patch$(flow: Flow, updates: any[]): Observable<Flow> {
    const url = (this._currentFlowHash)
      ? `${this.endpoint}/${flow.id}?hash=${this.getCurrentFlowHash(flow)}`
      : `${this.endpoint}/${flow.id}?hash=${flow.hash}`;

    return this.httpClient.patch<Flow>(url, updates);
  }

  patch(flow: Flow, updates: any, message: string = '', updateCurrent: boolean = true): void {
    const loader = this.state.registerLoader(LOADERS.flow);
    this.patch$(flow, updates)
      .subscribe(
        (flow: Flow) => {
          const initFlow = new Flow().initialize(flow);
          this.refreshFlowList(initFlow, message);
          if (updateCurrent) {
            this.refreshFlowAndHash(initFlow);
          }
        },
        (error) => this.logger.handleError('flow patch', error),
        () => this.state.resolveLoader(loader)
      );
  }

  patchFolder(flow: Flow, folderId: number, message: string = '') {
    const updates = [{ op: 'change_folder', value: folderId }];
    // const updates = [{ op: 'replace', field: 'folder', value: folderId }];
    this.patch(flow, updates, message, false);
  }

  patchFolderList$(flows: Flow[], folderId: number): Observable<Flow[]> {
    const requestBatch: Observable<Flow>[] = [];
    flows.forEach(flow => {
      const updates = [{ op: 'change_folder', value: folderId }];
      // const updates = [{ op: 'replace', field: 'folder', value: folderId }];
      requestBatch.push(this.patch$(flow, updates));
    });
    return forkJoin(requestBatch).pipe(take(1));
  }


  // -------------------------------------- REFRESH --------------------------------------

  refreshFlowAndHash(flow: Flow) {
    this._flow = flow;
    this._flow$.next(flow);
    if (this._currentFlowHash$) {
      this._currentFlowHash$.next(flow.hash);
    }
  }

  refreshFlowList(updatedFlow: Flow, message = '', pushNew: boolean = false) {
    const index = this._flows.findIndex(item => item.id === updatedFlow.id);
    if (index >= 0) {
      this._flows[index] = updatedFlow;
      this._flows$.next(this._flows);
    } else if (pushNew) {
      this._flows.push(updatedFlow);
      this._flows$.next(this._flows);
    }

    this.notify.success(message);
  }


  // -------------------------------------- DELETING --------------------------------------

  delete(flowId: number, message: string = ''): void {
    const loader = this.state.registerLoader(LOADERS.flowDelete);
    this.httpClient
      .delete(`${this.endpoint}/${flowId}`)
      .subscribe(
        () => {
          const index = this._flows.findIndex(item => item.id === flowId);
          if (index >= 0) {
            this._flows.splice(index, 1);
          }
          this._flows$.next(this._flows);
          this.notify.success(message);
        },
        (error) => this.logger.handleError('flow delete', error),
        () => this.state.resolveLoader(loader)
      );
  }


  // -------------------------------------- ROUTING --------------------------------------

  goToNode(nodeId: any, action: string): void {
    if (nodeId) {
      const section = (this.mode === 'campaign-viewer' || this.mode === 'campaign-builder') ? 'reports' : 'campaigns';
      this.location.go(`${this.baseUrl}/${section}/${this.mode}/${this._flow.id}/node/${nodeId}/${action}`);
    } else {
      this.goToNodeAction(action);
    }
  }

  goToNodeAction(action: string): void {
    const currentUrl = this.location.path();
    if (this.checkUrlType(currentUrl, 'node')) {
      console.log('Go to node edit action: ' + action);
      this.location.replaceState(currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + action);
    } else {
      console.log('Url type is not "node". Action has no effect');
    }
  }

  goToRoot(action: string): void {
    console.log('Go to root');
    const section = (this.mode === 'campaign-viewer' || this.mode === 'campaign-builder') ? 'reports' : 'campaigns';
    this.location.go(`${this.baseUrl}/${section}/${this.mode}/${this._flow.id}/root/${action}`);
  }

  goToRootAction(action: string): void {
    const currentUrl = this.location.path();
    if (this.checkUrlType(currentUrl, 'root')) {
      console.log('Go to root edit action: ' + action);
      this.location.replaceState(currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + action);
    } else {
      console.log('Url type is not "root". Action has no effect');
    }
  }

  goToTree(): void {
    if (!this.checkUrlType(this.location.path(), 'tree')) {
      console.log('Go to tree');
      const section = (this.mode === 'campaign-viewer' || this.mode === 'campaign-builder') ? 'reports' : 'campaigns';
      this.location.go(`${this.baseUrl}/${section}/${this.mode}/${this._flow.id}/tree`);
    }
  }

  refreshView(url: string = this.location.path()): void {
    console.log('URL changed to: ' + url);

    const urlSegments = this.router.parseUrl(url).root.children[PRIMARY_OUTLET].segments;
    if (urlSegments && urlSegments[2] && urlSegments[2].path === this.mode && urlSegments[4]) {

      // Set the view and check if the user can access it
      let view = urlSegments[4].path;
      const isOverlay: boolean = (view === 'root' || view === 'node');
      if (!!this._flow && !this._flow.status.editable && (this.mode === 'flow' || this.mode === 'campaign-builder') && isOverlay) {
        this.location.replaceState(urlSegments.slice(0, 4).join('/') + '/tree');
        view = 'tree';
        console.error(`Flow "${this._flow.name}" is not editable. Can't open overlay to edit.`);
      }

      // Set active menu/node corresponding to the view
      switch (view) {
        case 'tree':
          this.state.setActiveNode(undefined);
          this.state.setActiveMenu(undefined, undefined);
          break;

        case 'root':
          if (urlSegments[5].path === 'split' && urlSegments[6] && urlSegments[6].path) {
            this.activateSplit(this._flow.nodes, Number.parseInt(urlSegments[6].path));
          }
          this.state.setActiveNode(undefined);
          const rootContext = this.mode === 'flow' ? 'flow' : 'flow_report';
          this.state.setActiveMenu(rootContext, urlSegments[5].path);
          break;

        case 'node':
          const node = this._flow.findNode(urlSegments[5].path);
          if (urlSegments[6].path === 'split' && urlSegments[7] && urlSegments[7].path) {
            this.activateSplit(node.nodes, Number.parseInt(urlSegments[7].path));
          }
          this.state.setActiveNode(node);
          const nodeContext = this.mode === 'flow' ? 'node' : 'node_report';
          this.state.setActiveMenu(nodeContext, urlSegments[6].path);
          break;
      }
    }
  }


  switchExecutionType(type: 'batch' | 'streaming') {
    if (this._executionType$.value !== type) {
      this._executionType$.next(type);
    }
  }


  // -------------------------------------- PRIVATE --------------------------------------

  private checkUrlType(url: string, type: string): boolean {
    const urlSegments = this.router.parseUrl(url).root.children[PRIMARY_OUTLET].segments;
    return (urlSegments && urlSegments[2] && urlSegments[2].path === this.mode && urlSegments[4] && urlSegments[4].path === type);
  }

  private activateSplit(nodes: FlowNode[], split: number) {
    nodes.forEach(node => node.active = false);
    nodes[split].active = true;
    const currentUrl = this.location.path();
    this.location.replaceState(currentUrl.substring(0, currentUrl.lastIndexOf('/')));
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

}
