<section class="welcome" layout="row" layout-align="center center">
  <div class="welcome-text">
    <h1 class="mat-display-4">
      Simple. Automated. Marketing.
    </h1>
    <h2 class="mat-display-4">
      Grow your business with automated marketing.
    </h2>

    <div class="welcome-button">
      <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
        <button class="features-button" layout="column" [routerLink]="['/platform']">
          Please wait...
        </button>
      </ng-container>
          
      <ng-template #notLoggedIn>
        <button class="features-button" layout="column" [routerLink]="['/login']">
          Please Log In
        </button>
      </ng-template>
    </div>

  </div>
</section>
