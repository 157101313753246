import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Ability } from '@casl/ability';
import { PLATFORM_ROUTES } from '@shared/constants/platform_routes';


@Injectable({
  providedIn: 'root'
})
export class AbilityGuard implements CanActivateChild {

  constructor(private ability: Ability) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const activeRoute = PLATFORM_ROUTES.find(item => state.url.includes(`/platform/${item.value}`));
    if (activeRoute) {
      const routeId = activeRoute.value;
      return this.ability.can(routeId === 'config' ? 'manage' : 'view', activeRoute.value);
    } else {
      console.error('unhandled child ability', state.url);
    }
  }

}
