import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Audience } from '@shared/models/audience.model';
import { Flow } from '@app/shared/models/flow.model';
import { Template } from '@shared/models/template.model';

@Component({
  selector: 'ih-card-item-actions',
  templateUrl: './card-item-actions.component.html',
  styleUrls: ['./card-item-actions.component.sass']
})
export class CardItemActionsComponent implements OnInit {
  @Input() item: Audience | Flow | Template;
  @Input() itemType: string;
  @Input() trashMode: boolean = false;
  @Input() selectionOnly: boolean = false;

  @Output() selected = new EventEmitter<boolean>();
  @Output() restored = new EventEmitter<boolean>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() edited = new EventEmitter<boolean>();
  @Output() copied = new EventEmitter<boolean>();
  @Output() deleted = new EventEmitter<boolean>();

  constructor() { }

  select() {
    this.selected.emit(true);
  }

  restore() {
    this.restored.emit(true);
  }

  focus() {
    this.focused.emit(true);
  }

  edit() {
    this.edited.emit(true);
  }

  copy() {
    this.copied.emit(true);
  }

  delete() {
    this.deleted.emit(this.trashMode);
  }

  ngOnInit() {
    if (!this.itemType) {
      this.itemType = this.item.constructor.name.toLowerCase();
    }
  }

}
