import { FilterConfig } from '@shared/models/filters-selector-config.model';


export class Condition implements ISelectionContainer {
  name: string;
  kind: string;
  position: number;
  relation: 'and'|'or';
  selection: ConditionSelection;
  selection_valid: boolean;
  is_valid: boolean;
  subconditions?: Condition[];
  remove?: boolean;
  event_type?: string;
  
  constructor(input: any = {}) {
    this.name = input.name;
    this.kind = input.kind;
    this.position = input.position || 0;
    this.relation = input.relation || 'and';
    this.selection = new ConditionSelection(input);
    this.selection_valid = input.selection_valid || false;
    this.is_valid = input.is_valid || false;
    this.event_type = input.event_type;

    if (input.subconditions) {
      this.subconditions = input.subconditions.map(sub => new Condition(sub));
    }
  }

  toggleRelation() {
    this.relation = this.relation === 'and' ? 'or' : 'and';
  }

  validate() {
    this.is_valid = (!!this.subconditions && this.subconditions.length > 0)
      ? this.subconditions.every(subcondition => subcondition.is_valid === true) && this.selection_valid
      : this.selection_valid;
  }

  addSubcondition(input: any): Condition {
    const subcondition = new Condition(input);
    if (!this.subconditions) {
      this.subconditions = [subcondition];
    } else {
      this.subconditions.push(subcondition);
    }
    this.validate();
    return subcondition;
  }

  removeSubcondition(position: number) {
    if (position !== undefined && !!this.subconditions && this.subconditions.length > position) {
      this.subconditions.splice(position, 1);
    }
    this.subconditions.forEach((subcondition, index) => subcondition.position = index);
    this.validate();
  }
}


interface ISelectionContainer {
  selection: ConditionSelection;
}

export class ConditionSelection {
  field?: string;
  operator?: any;
  value?: any;
  group?: number | string;
  groupOnly?: boolean;

  // TODO: remove bigquery_field after migration
  bigquery_field?: string;

  constructor(input: FilterConfig | ISelectionContainer = { selection: {} }) {
    if (input instanceof FilterConfig) {
      this.operator = input.getDefaultOperator();
      this.value = input.getDefaultValue();
      this.group = input.getDefaultGroup();
      this.groupOnly = input.isDefaultGroupIndependent();
    } else if (input.selection) {
      // TODO: remove bigquery_field after migration
      this.field = input.selection.bigquery_field || input.selection.field;
      this.operator = input.selection.operator;
      this.value = input.selection.value;
      this.group = input.selection.group;
      this.groupOnly = input.selection.groupOnly || false;
    } else {
      this.operator = '';
      this.value = {};
      this.group = '';
      this.groupOnly = false;
      // The error below indicates that the condition gets an old model and will work incorrectly
      //console.error('Unsupported selection input', input);
    }
  }
}