import { NodeContent } from './node-content.model';
import { NodeSchedule } from './node-schedule.model';
import { EventConditions } from '@shared/models/event-conditions.model';
import { environment } from '@env/environment';

import * as uuid from 'uuid';


interface IInitialize<T> {
  initialize(input: any): T;
}

export class NodeTree {
  id: string;
  channel: string;
  name: string;
  label: string;
  children: NodeTree[];

  constructor(node: FlowNode, setChildren: boolean = true) {
    this.id = node.id;
    this.channel = node.channel;
    this.name = node.name;
    this.label = node.label;
    if (setChildren) {
      this.children = node.nodes.map(childNode => new NodeTree(childNode));
    }
  }
}

export class NodeAction {
  name: string;
  label: string;
  icon: string;
  has_permission: boolean;
  has_content: boolean;
  production: boolean;
}

export class NodeTest implements IInitialize<NodeTest> {
  name: string;
  versions: number;
  segment_winner: 'open' | 'click';
  segment_type: 'number' | 'pct';
  segment_size: number;

  initialize(input?) {
    input = input || {};

    this.name = input.name || 'no_test';
    this.versions = input.versions || 1;
    this.segment_winner = input.segment_winner || 'open';
    this.segment_type = input.segment_type || 'number';
    this.segment_size = input.segment_size || 1000;

    return this;
  }
}

export class NodeTestOption {
  name: string;
  label: string;
  icon: string;
}


/**
 * Defines FlowNode
 * id:          for referencing (e.g. view statistics)
 * active:      if activated in view (by user)
 * name:        action name to show in UI
 * description: NOT IN USE (could be used to describe instead of triggers)
 * channel:     email, sms, facebook, etc.
 * content:     see NodeContent
 * schedule:    see ISchedule
 * split:       see EventConditions
 * follow_up:   see EventConditions
 * express:     if communication must be sent no matter what
 * priority:    level of communication priority
 * nodes:       child FlowNodes
 */
export class FlowNode implements IInitialize<FlowNode> {
  id?: string;
  active?: boolean;
  name: string;
  description: string;
  channel: string;
  permission: string;
  numeration: string;
  test: NodeTest;
  content: NodeContent;
  schedule: NodeSchedule;
  split?: EventConditions;
  follow_up?: EventConditions;
  express: boolean;
  priority: number;
  nodes: FlowNode[];
  is_valid?: boolean;

  get level(): number {
    return this.numeration ? (this.numeration.match(/\./g) || []).length : 0;
  }

  get action(): string {
    const action = NODE_ACTIONS.find(action => action.name === this.channel);
    return action ? action.label : 'Undefined';
  }

  get short_label(): string {
    return `${this.action} ${this.numeration}`;
  }

  get label(): string {
    return `${this.action} ${this.level > 0 ? 'follow-up' : 'action'} ${this.numeration}`;
  }

  constructor(input: any = {}, numeration?: string) {
    const initInput = numeration ? Object.assign({}, input, {numeration: numeration}) : input;
    this.initialize(initInput);
  }

  static actions(contentOnly: boolean = true): NodeAction[] {
    const nodeActions = (contentOnly) ? NODE_ACTIONS.filter(action => action.has_content) : NODE_ACTIONS;
    const copiedActions = JSON.parse(JSON.stringify(nodeActions));
    return (environment.production)
      ? copiedActions.filter(action => action.production)
      : copiedActions;
  }

  initialize(input: any) {
    this.id = input.id || uuid.v1();
    this.active = (input.active === undefined) ? true : input.active;

    this.name = input.name;
    this.description = input.description;
    this.channel = input.channel;
    this.permission = 
      this.hasPermission()
      ? (input.permission || input.permission === '' ? input.permission : 'campaigns')
      : undefined;
    this.numeration = input.numeration;
    
    if (this.hasContent()) {
      // Remove assigning of test variable from input.content after delpoying and updating all nodes with A/B tests
      const contentTest = (input.content ? {name: input.content.test, versions: input.content.test_versions} : {});
      this.test = new NodeTest().initialize(input.test ? input.test : contentTest);
      this.content = new NodeContent().initialize(input.content ? input.content : {channel: input.channel});
      this.schedule = new NodeSchedule().initialize(input.schedule);

    } else {
      this.test = undefined;
      this.content = undefined;
      this.schedule = undefined;
    }

    if (input.split) {
      this.split = new EventConditions().initialize(Object.assign({}, input.split, {type: 'split'}));
    }
    if (input.follow_up) {
      this.follow_up = new EventConditions().initialize(Object.assign({}, input.follow_up, {type: 'follow_up'}));
    }

    // new nodes are never express
    this.express = (input.express === undefined) ? false : input.express;
    this.priority = input.priority || 100;

    // initialize child nodes, if any
    this.nodes = (!!input.nodes && input.nodes.length > 0)
      ? input.nodes.map((node: FlowNode, index: number) => new FlowNode(node, `${this.numeration}.${index + 1}`))
      : [];

    // validate if 'is_valid' is empty
    if (input.is_valid === undefined) {
      this.validate(true);
    } else {
      this.is_valid = input.is_valid;
    }

    return this;
  }

  regenerateId(): string {
    this.id = uuid.v1();
    return this.id;
  }


  validate(initial: boolean = false): void {
    if (initial) { 
      if (this.hasContent()) {
        this.content.validate();
        this.schedule.validate();
      }
      if (this.follow_up) {
        this.follow_up.validate();
      }
    }

    const hasChannel = !!this.channel;
    const validContent = this.hasContent() 
      ? (this.content.is_valid && this.schedule.is_valid) 
      : true;
    const validFollowUp = !!this.follow_up 
      ? (this.follow_up.is_valid && this.nodes.length > 0) 
      : true;
    this.is_valid = hasChannel && validContent && validFollowUp;
  }

  getIngredientIds(): number[] {
    if (!this.hasContent()) {
      return [];
    }
    const kinds: string[] = Object.keys(this.content.ingredient_ids);
    const ingredients: number[] = [];

    kinds.forEach((kind: string) => {
      ingredients.push(...this.content.ingredient_ids[kind]);
    });
    return ingredients;
  }


  /**
   * True if node has associated ingredients of node's kind (e.g. email)
   */
  hasIngredients(): boolean {
    const kinds: string[] = this.hasContent() ? Object.keys(this.content.ingredient_ids) : [];
    return kinds.length > 0 && kinds.every(kind => this.content.ingredient_ids[kind].length > 0);
  }

  /**
   * True if node should have permission for the action
   */
  hasPermission(): boolean {
    const nodeAction = NODE_ACTIONS.find(action => action.name === this.channel);
    return nodeAction ? nodeAction.has_permission : false;
  }

  /**
   * True if node should have content according to its action
   */
  hasContent(): boolean {
    const nodeAction = NODE_ACTIONS.find(action => action.name === this.channel);
    return nodeAction ? nodeAction.has_content : false;
  }


  getAllChildren(): FlowNode[] {
    const nodes: FlowNode[] = [];
    this.nodes.forEach(childNode => {
      nodes.push(childNode);
      nodes.push(...childNode.getAllChildren());
    });
    return nodes;
  }

  getTestOptions(): NodeTestOption[] {
    const allOptions: NodeTestOption[] = JSON.parse(JSON.stringify(NODE_TEST_OPTIONS));
    switch (this.channel) {
      case 'email':
      case 'internal_email':
        return allOptions;
      case 'sms':
        return allOptions.filter(option => option.name !== 'subject');
      case 'facebook':
      case 'list':
        return allOptions.filter(option => option.name === 'no_test');
    }
  }
}


const NODE_ACTIONS: NodeAction[] = [
  { 
    name: 'email',
    label: 'Email',
    icon: 'fas fa-envelope',
    has_permission: true,
    has_content: true,
    production: true
  },
  { 
    name: 'internal_email',
    label: 'Internal Email',
    icon: 'fas fa-envelope-open-text',
    has_permission: true,
    has_content: true,
    production: true
  },
  {
    name: 'sms', 
    label: 'SMS', 
    icon: 'fas fa-comment-alt', 
    has_permission: true, 
    has_content: true, 
    production: false 
  },
  { 
    name: 'facebook', 
    label: 'Facebook', 
    icon: 'fab fa-facebook', 
    has_permission: false, 
    has_content: true, 
    production: false 
  },
  { 
    name: 'list', 
    label: 'List', 
    icon: 'fas fa-list', 
    has_permission: false, 
    has_content: true, 
    production: true 
  },
  { 
    name: 'empty', 
    label: 'Empty', 
    icon: 'far fa-circle', 
    has_permission: false, 
    has_content: false, 
    production: true 
  }
];

const NODE_TEST_OPTIONS: NodeTestOption[] = [
  { name: 'no_test', label: 'No Test', icon: 'exposure_zero' },
  { name: 'subject', label: 'Subject line', icon: 'short_text' },
  { name: 'from', label: 'Sender name', icon: 'face' },
  { name: 'body', label: 'Body content', icon: 'assignment' }
];