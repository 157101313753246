import { UserRole, UserRolePermissionMap, UserRolePermission } from '@shared/models/user_permissions.model';

export class ViewAccess {
  id: string;
  string_id?: string; // remove after replacing it in all places
  role: UserRole;
  permissions: UserRolePermissionMap<UserRolePermission>;
  children: ViewAccess[]; // looks like deep levels are not used
  primary: boolean;
  
  get admin() {
    return this.role === 'platform_admin' || this.role === 'view_admin';
  }

  constructor(input: any = {}) {
    this.id = input.id || input.string_id;
    this.string_id = this.id;
    this.role = input.role || 'user';
    this.permissions = input.permissions;
    this.children = input.children
      ? input.children.map(child => new ViewAccess(child))
      : [];
    this.primary = input.primary || false;
  }
}

export class UsergroupAccess {
  id: string;
  children: ViewAccess[];
  primary: boolean;

  get admin() {
    return this.children.every(child => child.admin); // ???
  }

  constructor(input: any = {}) {
    this.id = input.id;
    this.children = input.children
      ? input.children.map(child => new ViewAccess(child))
      : [];
    this.primary = input.primary || false;
  }
}


/**
 * User's have access to Usergroups through UsergroupAccess
 * @admin Access to Innohead 'admin' section
 * @usergroups Access points to Usergroups
 */
export class User {
  id: number;
  name: string;
  email: string;
  usergroups: UsergroupAccess[];
  status: UserStatus;
  preferences: UserPreferences;
  admin?: boolean;
  view_admin?: boolean;
  deleted?: boolean;

  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  constructor(input: any = {}) {
    this.id = input.id;
    this.name = input.name || '';
    this.email = input.email || '';
    this.usergroups = input.usergroups
      ? input.usergroups.map(usergroup => new UsergroupAccess(usergroup))
      : [];
    this.status = input.status || {};
    if (this.status.last_login) {
      this.status.last_login = new Date(this.status.last_login);
    }
    this.preferences = new UserPreferences(input.preferences || {});
    this.admin = input.admin || false;
    this.view_admin = input.view_admin || false;

    this.created_by = input.created_by;
    this.created_at = input.created_at;
    this.modified_by = input.modified_by;
    this.modified_at = input.modified_at;
  }

  get views(): ViewAccess[] {
    return this.usergroups.reduce((views, usergroup) => [...views, ...usergroup.children], []);
  }

  isAdmin(): boolean {
    const admin = this.usergroups.find((item) => ['admin', 'admin_dev'].includes(item.id));
    return (admin !== undefined);
  }

  /**
   * returns a user's UsergroupAccess (if no id, returns primary or first UsergroupAccess)
   * @param id id of the usergroup
   */
  getUsergroupAccess(id?: string): UsergroupAccess {
    const lookup = id
      ? { property: 'id', value: id }
      : { property: 'primary', value: true };

    const usergroup = this.usergroups.find(usergroup => usergroup[lookup.property] === lookup.value);
    return usergroup ? new UsergroupAccess(usergroup) : new UsergroupAccess(this.usergroups[0]);
  }

  /**
   * returns a user's ViewAccess (if no viewId, returns primary or first ViewAccess)
   * @param usergroupId id of the usergroup (required)
   * @param viewId id of the view
   */
  getViewAccess(usergroupId: string, viewId?: string): ViewAccess {
    const lookup = viewId
      ? { property: 'id', value: viewId }
      : { property: 'primary', value: true };

    const usergroup = this.usergroups.find(usergroup => usergroup.id === usergroupId);
    if (usergroup) {
      const view = usergroup.children.find(child => child[lookup.property] === lookup.value);
      return view ? new ViewAccess(view) : new ViewAccess(usergroup.children[0]);
    } else {
      return undefined;
    }
  }
  
}

interface UserStatus {
  reset_password: boolean;
  accepted_terms: boolean;
  last_login?: any;
  received_welcome?: boolean;
  received_reset?: boolean;
}

export class UserPreferences {
  list_sorting: ListSorting;
  profile_search_fields: string[];
  product_search_fields: string[];
  statistic_filtering: StatisticFiltering;

  constructor(input: any = {}) {
    this.list_sorting = (input.list_sorting) ? input.list_sorting : DEFAULT_LIST_SORTING;
    this.profile_search_fields = (input.profile_search_fields) ? input.profile_search_fields : [];
    this.product_search_fields = (input.product_search_fields) ? input.product_search_fields : [];
    this.statistic_filtering = (input.statistic_filtering) ? input.statistic_filtering : DEFAULT_STATISTIC_FILTERING;
  }
}

export interface ItemSortOption {
  name: string;
  label: string;
  field: string;
  desc: boolean;
  icon?: string;
}

export interface ListSorting {
  audiences: string;
  campaigns: string;
  templates: string;
  usergroups: string;
  users: string;
}

export interface StatisticFiltering {
  top_limit: number;
  probability_limit: number;
  horizon: string;
}

export const BASE_SORT_OPTIONS: ItemSortOption[] = [
  { 
    name: 'name_asc',
    label: 'Name: A to Z',
    field: 'name',
    desc: false,
    icon: 'keyboard_arrow_down'
  },
  { 
    name: 'name_desc',
    label: 'Name: Z to A',
    field: 'name',
    desc: true,
    icon: 'keyboard_arrow_up'
  },
  { 
    name: 'modified_asc',
    label: 'Modified: old to new',
    field: 'modified_at',
    desc: false,
    icon: 'keyboard_arrow_down'
  },
  { 
    name: 'modified_desc',
    label: 'Modified: new to old',
    field: 'modified_at',
    desc: true,
    icon: 'keyboard_arrow_up'
  }
];

const DEFAULT_LIST_SORTING: ListSorting = {
  audiences: 'modified_desc',
  campaigns: 'modified_desc',
  templates: 'modified_desc',
  usergroups: 'modified_desc',
  users: 'modified_desc'
}

const DEFAULT_STATISTIC_FILTERING: StatisticFiltering = {
  top_limit: 5,
  probability_limit: 60,
  horizon: '30d'
}