<div mat-dialog-title>
  <ng-container *ngIf="namesSet === undefined; else selectMessage">
    {{ data.name ? ('Change ' + data.structure + ' name') : ('Enter name for new ' + data.structure) }}
  </ng-container>
  <ng-template #selectMessage>
    {{ 'Select ' + data.structure + ' name' }}
  </ng-template>  
</div>

<mat-dialog-content>
  <mat-form-field class="full-width">
    <ng-container *ngIf="namesSet === undefined; else selectField">
      <input matInput placeholder="Name" [(ngModel)]="name" type="text">
    </ng-container>

    <ng-template #selectField>
      <mat-select [(ngModel)]="name" placeholder="Name">
        <mat-option *ngFor="let item of namesSet; let index = index" [value]="item.id" [disabled]="data.name === item.id">
          {{ item.name | ihCapitalize }}
        </mat-option>
      </mat-select>
    </ng-template>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="transparent" fxLayout="row" fxLayoutAlign="start center">
  <button mat-button (click)="cancel()">
    Cancel
  </button>
  <span fxFlex></span>
  <button mat-raised-button color="primary" [disabled]="!name" (click)="selectName()">
    {{ data.name ? 'Update' : 'Create' }}
  </button>
</mat-dialog-actions>
