import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PublicComponent } from '@public/public.component';
import { HomeComponent } from '@public/home/home.component';
import { LoginComponent } from '@public/login/login.component';
import { PolicyComponent } from './public/legal/policy/policy.component';
import { LegalComponent } from './public/legal/legal.component';

import { AppCustomPreloader } from './app-preloading-strategy';

import { AdminGuard } from '@core/guards/admin.guard';
import { PermissionsGuard } from '@core/guards/permissions.guard';
import { PlatformGuard } from '@core/guards/platform.guard';

const app_routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full', },
      { path: 'home', component: HomeComponent, data: { header: 'Homepage' } },
      { path: 'login', component: LoginComponent, data: { header: 'Login' } },
      { 
        path: 'legal', 
        component: LegalComponent, 
        children: [
          { path: 'privacy', component: PolicyComponent },
          { path: 'terms', component: PolicyComponent },
          { path: 'security', component: PolicyComponent },
        ]
      }
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [ AdminGuard ]
  },
  {
    path: 'permissions',
    loadChildren: () => import('../app/permissions/permissions.module').then(m => m.PermissionsModule),
    canActivate: [ PermissionsGuard ]
  },
  {
    path: 'platform',
    loadChildren: () => import('../app/platform/platform.module').then(m => m.PlatformModule),
    data: { preload: true },
    canActivate: [ PlatformGuard ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(app_routes, { preloadingStrategy: AppCustomPreloader, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
