import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger.service';
import { PlatformDefault } from '@shared/models/platform-default.model';


@Injectable()
export class PlatformDefaultsService {
  private usergroup_id: string = 'platform';
  private endpoint: string = '/defaults/platform';
  private active_view: { section: number, collection: number };

  _default$: Subject<PlatformDefault> = new Subject<PlatformDefault>();
  _defaults$: Subject<PlatformDefault[]> = new Subject<PlatformDefault[]>();
  defaults: PlatformDefault[] = [];

  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService
  ) { }

  get usergroup() {
    return this.usergroup_id;
  }

  set usergroup(usergroup_id: string) {
    if (usergroup_id && this.usergroup_id !== usergroup_id) {
      console.log(`Configure service for "${usergroup_id}" usergroup`);
      this.usergroup_id = usergroup_id;
      this.endpoint = '/defaults/' + usergroup_id;
    }
  }

  get view() {
    const view = this.active_view;
    this.active_view = undefined;
    return view;
  }

  set view(active_view) {
    this.active_view = active_view;
  }

  get default$(): Observable<PlatformDefault> {
    return this._default$.asObservable();
  }


  loadAll(usergroup_id?: string) {
    this.usergroup = usergroup_id;
    this.httpClient.get(this.endpoint)
      .subscribe(
        (defs: any[]) => {
          this.defaults = defs.map(def => new PlatformDefault().initialize(def));
          this._defaults$.next(this.defaults);
        },
        (error) => this.logger.handleError('platform defaults load', error)
      );
  }

  load(kind: string, usergroup_id?: string) {
    this.usergroup = usergroup_id;
    this.httpClient.get(`${this.endpoint}/${kind}`)
      .subscribe(
        (def: any) => {
          def.usergroup_id = usergroup_id;
          def.kind = kind;
          const loadedDefault = new PlatformDefault().initialize(def);
          this._default$.next(loadedDefault);
        },
        (error) => this.logger.handleError('platform default load', error)
      );
  }

  load$(kind: string, usergroup_id: string): Observable<PlatformDefault> {
    return this.httpClient.get(`/defaults/${usergroup_id}/${kind}`)
      .pipe(
        map(def => new PlatformDefault().initialize(def)),
        catchError(error => this.logger.handleError('platform default load', error))
      );
  }

  save(platformDefault: PlatformDefault) {
    this.usergroup = platformDefault.usergroup_id;
    this.httpClient.post(`${this.endpoint}/${platformDefault.kind}`, platformDefault)
      .subscribe(
        (def: any) => {
          const savedDefault = new PlatformDefault().initialize(def);
          this._default$.next(savedDefault);

          this.defaults.push(savedDefault);
          this._defaults$.next(this.defaults);
        },
        (error) => this.logger.handleError('platform default save', error)
      );
  }

  update(platformDefault: PlatformDefault) {
    this.usergroup = platformDefault.usergroup_id;
    this.httpClient.put(`${this.endpoint}/${platformDefault.kind}`, platformDefault)
      .subscribe(
        (def: any) => this.updateDefaultList(new PlatformDefault().initialize(def)),
        (error) => this.logger.handleError('platform default update', error)
      );
  }

  create(platformDefault: PlatformDefault) {
    this.usergroup = platformDefault.usergroup_id;
    this.httpClient.post(`${this.endpoint}`, platformDefault)
      .subscribe(
        (def: any) => this.updateDefaultList(new PlatformDefault().initialize(def)),
        (error) => this.logger.handleError('platform default create', error)
      );
  }

  delete(platformDefault: PlatformDefault) {
    this.httpClient.delete(`/defaults/${platformDefault.usergroup_id}/${platformDefault.kind}`)
      .subscribe(
        (def: any) => {
          const deletedIndex = this.defaults.findIndex(currentDefault => currentDefault.equalId(platformDefault));
          if (deletedIndex > -1) {
            this.defaults.splice(deletedIndex, 1);
          }
          this._defaults$.next(this.defaults);
        },
        (error) => this.logger.handleError('platform default delete', error)
      );
  }


  addToCollection(platformDefault: PlatformDefault, section: string, collection: string, value: number | number[]) {
    this.usergroup = platformDefault.usergroup_id;
    const collectionPatch = {
      op: 'add_to_collection',
      section: section,
      collection: collection,
      value: value
    }
    this.httpClient.patch(`${this.endpoint}/${platformDefault.kind}`, [collectionPatch])
      .subscribe(
        (def: any) => this.updateDefaultList(new PlatformDefault().initialize(def)),
        (error) => this.logger.handleError('platform default patch', error)
      );
  }

  removeFromCollection(platformDefault: PlatformDefault, section: string, collection: string, value: number | number[]) {
    this.usergroup = platformDefault.usergroup_id;
    const collectionPatch = {
      op: 'remove_from_collection',
      section: section,
      collection: collection,
      value: value
    }
    this.httpClient.patch(`${this.endpoint}/${platformDefault.kind}`, [collectionPatch])
      .subscribe(
        (def: any) => this.updateDefaultList(new PlatformDefault().initialize(def)),
        (error) => this.logger.handleError('platform default patch', error)
      );
  }


  private updateDefaultList(updatedDefault: PlatformDefault) {
    this._default$.next(updatedDefault);
    const updatedIndex = this.defaults.findIndex(currentDefault => currentDefault.equalId(updatedDefault));
    (updatedIndex > -1)
      ? this.defaults[updatedIndex] = updatedDefault
      : this.defaults.push(updatedDefault);
    this._defaults$.next(this.defaults);
  }

}
