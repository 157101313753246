import { Component, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { filter, takeUntil, catchError } from 'rxjs/operators';

@Component({
  selector: 'ih-privacy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.sass']
})
export class PolicyComponent implements OnDestroy {
  public policy$: Observable<string>;
  
  public error: any;
  public errorMessage;

  private unsubscribed$ = new Subject();

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribed$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        const policyName = event.url.split('/').pop();
        this.loadPolicy(policyName);
      })
  }

  loadPolicy(policyName: string) {
    this.errorMessage = undefined;
    const assetUrl = `/assets/policies/${policyName}.md`;
    this.policy$ = this.http.get(assetUrl, { responseType: 'text'}).pipe(
      catchError(err => {
        switch (err.status) {
          case 404:
            this.errorMessage = getNotFound(policyName);
            break;
        
          default:
            this.errorMessage = getSomethingWrong(policyName);
            break;
        }
        this.error = err;
        return throwError(err);
      })
    );
  }


  ngOnDestroy(): void {
    this.unsubscribed$.next();
    this.unsubscribed$.complete();
  }

}

function getNotFound(policyName) {
  return `# We are sorry
  There was a problem loading our **${policyName}** policy.

  Please contact support@innohead.com for assistance.
  `;
}

function getSomethingWrong(policyName) {
  return `
    # We are sorry
    Something went wrong with loading our **${policyName}** policy.

    Please contact support@innohead.com for assistance.
  `
}