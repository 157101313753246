import { ConditionGroup } from '@shared/models/condition-group.model';
import { WAIT_FILTER_CONFIG, RESPONSE_FILTER_CONFIG } from '@shared/models/filters-selector-config.model';
import { IValidate, Requirement } from '@shared/models/requirements.model';

interface IInitialize<T> {
  initialize(input: any): T;
}

export class EventConditions implements IInitialize<EventConditions>, IValidate<EventConditions> {
  name?: string;
  type: string;
  // used in validation, and in the flow's inner components where only trigger/follow_up is passed into
  execution_type: string;

  events: ConditionGroup[];
  include: ConditionGroup[];
  exclude: ConditionGroup[];
  audiences: number[];
  is_valid?: boolean;

  constructor() {}

  initialize(input: any): EventConditions {
    if (input.name) {
      this.name = input.name;
    }
    this.type = input.type;
    this.execution_type = input.execution_type;

    this.events = [];
    if (!!input.events && input.events.length > 0) {
      input.events.forEach(group => this.events.push(new ConditionGroup(group)));
    }
    this.include = [];
    if (!!input.include && input.include.length > 0) {
      input.include.forEach(group => this.include.push(new ConditionGroup(group)));
    }
    this.exclude = [];
    if (!!input.exclude && input.exclude.length > 0) {
      input.exclude.forEach(group => this.exclude.push(new ConditionGroup(group)));
    }

    this.audiences = input.audiences || [];

    this.validate();
    return this;
  }

  /**
   * Validates event conditions and return main requirement
   * @param details include or not include detailed requirements
   */
  validate(details: boolean = false): Requirement {
    let requirements: Requirement[] = [];

    if (this.type === 'trigger') {
      requirements = requirements.concat(...this.eventRequirements() );
    }
    
    requirements = requirements.concat(...this.additionalRequirements('include'), ...this.additionalRequirements('exclude') );

    if (this.type === 'trigger' && this.execution_type === 'batch') {
      requirements.push(
        new Requirement(`batch_audiences_valid`, this.audiences.length >= 1, '', 'Audience', 'Must have audience')
      );
    }

    this.is_valid = requirements.every(requirement => requirement.is_valid);

    const mainRequirement = new Requirement(this.type, this.is_valid, this.type.replace('_', ' '));
    if (this.type === 'trigger') {
      mainRequirement.withData({link: 'root/trigger'})
    }
    if (details) {
      mainRequirement.addChildren(requirements);
    }
    return mainRequirement;
  }

  eventRequirements(): Requirement[] {
    const labels = LABELS[this.type];
    return [
      (!!this.events && this.events.length > 0)
      ? new Requirement(`${this.type}_events_valid`, this.events.every(item => item.is_valid), labels.events)
      : new Requirement(
        `${this.type}_events_empty`,
        (this.type === 'split' && this.audiences.length > 0),
        labels.events,
        `${labels.events} is empty`,
        (this.type === 'split' ? 'Conditions' : labels.events) + ` can't be empty`)
    ];
  }

  additionalRequirements(joining: 'include'|'exclude'): Requirement[] {
    const labels = LABELS[this.type];
    return [
      (!!this[joining] && this[joining].length > 0)
      ? new Requirement(`${this.type}_${joining}_valid`, this[joining].every(item => item.is_valid), labels[joining])
      : new Requirement(`${this.type}_${joining}_empty`, true, labels[joining], `${labels[joining]} are empty`)
    ];
  }

  followUp() {
    this.initialize({type: 'follow_up'});
    const followUpGroup = new ConditionGroup({
      categories: FOLLOW_UP_CATEGORIES,
      default: true,
      is_valid: true
    });
    followUpGroup.categories[0].conditions[0].is_valid = true;
    followUpGroup.categories[1].conditions[0].is_valid = true;

    // this.include = [followUpGroup];
    this.events = [followUpGroup]
    this.is_valid = true;

    console.log(this);
    return this;
  }

  followUpSplit() {
    this.initialize({type: 'split'});
    const followUpSplitGroup = new ConditionGroup({
      categories: FOLLOW_UP_SPLIT_CATEGORIES,
      default: true,
      is_valid: true
    });
    followUpSplitGroup.categories[0].conditions[0].is_valid = true;

    this.include = [followUpSplitGroup];
    this.is_valid = true;
    return this;
  }

  randomizedSplit(value: number) {
    this.initialize({type: 'split', name: 'random_split'});
    const splitGroup = new ConditionGroup({
      categories: RANDOM_SPLIT_CATEGORIES,
      is_valid: true
    });
    splitGroup.categories[0].conditions[0].selection.value = (!!value) ? value : 0;

    this.include = [splitGroup];
    this.is_valid = true;
    return this;
  }

  isRandomizedSplit() {
    return (
      this.include.length === 1 &&
      this.include[0].categories.length === 1 &&
      this.include[0].categories[0].conditions.length === 1 &&
      this.include[0].categories[0].name === 'randomized'
    )
  }

  isEmpty(checkAudiences: boolean = false) {
    const groups: boolean = this.events.length === 0 && this.include.length === 0 && this.exclude.length === 0;
    return checkAudiences ? (groups && this.audiences.length === 0) : groups;
  }

}


const LABELS = {
  trigger: { events: 'Flow trigger', include: 'Include contacts', exclude: 'Exclude contacts' },
  follow_up: { include: 'Follow up', exclude: 'Never follow up' },
  split: { include: 'Condition list', exclude: 'Exception list' }
}

const FOLLOW_UP_CATEGORIES = [
  {
    label: 'Waiting time',
    name: 'after_action',
    table: 'nodes',
    conditions: [ WAIT_FILTER_CONFIG ],
    position: 0,
    is_valid: true
  },
  {
    label: 'Target contacts',
    name: 'action_response',
    table: 'triggers',
    conditions: [ RESPONSE_FILTER_CONFIG ],
    position: 1,
    is_valid: true
  }
]

const FOLLOW_UP_SPLIT_CATEGORIES = [
  {
    label: 'Target contacts',
    name: 'action_response',
    table: 'triggers',
    conditions: [ RESPONSE_FILTER_CONFIG ],
    position: 0,
    is_valid: true
  }
]

const RANDOM_SPLIT_CATEGORIES = [
  {
    label: 'Randomized',
    name: 'randomized',
    conditions: [
      { kind: 'random', field: 'random', name: 'random', is_valid: true }
    ],
    is_valid: true
  }
]