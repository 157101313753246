/**
 * NodeContent
 * Contains all relevant channel ingredients for a node
 *
 * Ingredients are arrays such that the user has the ability to test
 * different item versions
 */

import { IIngredientIds } from '@core/services/ingredients.service';


interface IInitialize<T> {
  initialize(input: any): T;
}


export class NodeContent implements IInitialize<NodeContent> {
  //public validate: ValidationService;

  channel: string;
  ingredient_ids: IIngredientIds;
  // Remove test variables after delpoying and updating all nodes
  test: string;
  test_versions: number;

  is_valid?: boolean;
  validContent?: boolean;
  validSender?: boolean;
  validReceiver?: boolean;
  validSubject?: boolean;
  validVersions?: boolean;


  initialize(input?: any): NodeContent {
    input = input || {};
    this.channel = input.channel;
    this.ingredient_ids = input.ingredient_ids || this.freshIngredients();
    this.test = input.test || 'no_test';
    this.test_versions = input.test_versions || 1;
    if (input.is_valid === undefined) {
      this.validate();
    } else {
      this.is_valid = input.is_valid;
    }
    return this;
  }

  freshIngredients() {
    return CHANNEL_INGREDIENTS[this.channel]
      ? JSON.parse(JSON.stringify(CHANNEL_INGREDIENTS[this.channel]))
      : {}
  }


  /**
   * Removes a specific content item from a talking point's content.items array
   * @param kind e.g. 'from', 'subject', 'content'
   * @param targetId id of the ingredient to be removed
   */
  removeIngredient(kind: string, targetId): void {
    this.ingredient_ids[kind].forEach((ingredientId, index) => {
      if (ingredientId === targetId) { this.ingredient_ids[kind].splice(index, 1); }
    });
  }


  // VALIDATION -------------------------------------------------------

  validate() {
    // Do not re-validate content with undefined data:
    // need to move Sender/Subject/Content validation from NodeContentComponent to model
    // or store these properties
    if (this.is_valid !== undefined && this.validSender === undefined && this.validSubject === undefined && this.validContent === undefined) {
      return;
    }

    switch (this.channel) {
      case 'email':
        this.is_valid = 
          (this.validSender === true) &&
          (this.validSubject === true) &&
          (this.validContent === true);
        break;

      case 'internal_email':
        this.is_valid = 
          (this.validSender === true) &&
          (this.validReceiver === true) &&
          (this.validSubject === true) &&
          (this.validContent === true);
        break;

      case 'sms':
        this.is_valid = 
          (this.validSender === true) &&
          (this.validContent === true);
        break;

      case 'facebook':
        this.is_valid = 
          (this.validContent === true);
        break;

      case 'list':
        this.is_valid = 
          (this.validContent === true);
        break;

      default:
        this.is_valid = false;
        break;
    }
  }

}


const CHANNEL_INGREDIENTS = {
  email: { subject: [], preheader: [], from: [], body: [] },
  internal_email: { subject: [], preheader: [], from: [], to: [], body: [] },
  sms: { from: [], body: [] },
  facebook: { sync: [] },
  list: { filename: [] },
}