<mat-toolbar class="public-menu">
  <span><img [src]="innoheadLogo" width="35%" alt="Innohead"></span>
  <span fxFlex></span>
  <span>
    <ng-container *ngIf="isLoggedIn; else notLoggedIn">
      <a mat-icon-button matTooltip="Platform" matTooltip="Enter platform" (click)="goToPlatform()">
        <mat-icon *ngIf="!loadingPlatform">exit_to_app</mat-icon>
        <i *ngIf="loadingPlatform" class="fa fa-spinner fa-pulse"></i>
      </a>
    </ng-container>
    <ng-template #notLoggedIn>
      <a mat-button matTooltip="Login" (click)="login()">
        <span>Log in</span>
      </a>
    </ng-template>
  </span>
</mat-toolbar>

<div class="public-page">
  <router-outlet></router-outlet>
</div>

<footer class="public-footer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
  <a routerLink="/legal">Legal</a>
</footer>  
