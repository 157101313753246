import { IntegrationConfigs } from '@app/feature-modules/integration/integration.model';
import { HtmlSnippetKind } from './html_snippet.model';
import { MainModelTypes } from './index';

/**
 * ViewConfig:
 * Defines the configuration associated with a particular UserViewAccess
 * The configuration holds:
 * - Company name, address, contacts, phone, etc
 * - Company acceptance of Terms of Use
 * - Email (as the company's source email for marketing campaigns)
 * - Tags (available tags for Audiences, Campaigns and Templates)
 * - ... 
 */
export class ViewConfig {
  id?: number;
  usergroup_id: string;
  view_id: string;
  company: CompanyConfig;
  status: ViewStatus;
  domain: { name: string, verified: boolean };
  email: IEmailConfig;
  sms: ISmsConfig;
  editor: EditorConfig;
  folders: Folder[];
  test_contacts: any[];
  tags: ITagsConfig;
  permissions: PermissionConfig;
  integrations: IntegrationConfigs;
  verification_code?: string; // looks like unused, doesn't?
  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;

  constructor(input: any = {}) {
    if (input && input.id) {
      this.id = input.id;
      this.usergroup_id = input.usergroup_id;
      this.view_id = input.view_id;
    }

    this.company = new CompanyConfig(input.company);
    this.status = input.status ? input.status : { accepted_terms: false, contact_information: false };
    this.domain = input.domain ? input.domain : { name: '', verified: false };
    this.email = input.email ? input.email : { from_name: '', from_email: '', verified: false };
    this.sms = input.sms ? input.sms : undefined;
    this.editor = initEditor(input.editor);
    this.folders = 
      (!!input.folders && input.folders.length > 0)
      ? input.folders
      : JSON.parse(JSON.stringify(DEFAULT_FOLDERS));
    this.tags = input.tags ? input.tags : { audiences: [], templates: [], campaigns: [] };
    this.permissions = new PermissionConfig(input.permissions);
    this.integrations = input.integrations || {};
    this.test_contacts = input.test_contacts || [];

    this.verification_code = input.verification_code;
  }

  getFolders(kind: MainModelTypes): Folder[]  {
    const defaultFolders = this.folders.filter(folder => folder.kind === kind && folder.default === true) || [];

    const userFolders = this.folders
      .filter(folder => folder.kind === kind && !folder.default)
      .sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (b.label > a.label) { return 1; }
        return 0;
      });

    return [...defaultFolders, ...userFolders];
  }

  getPermissions(channel: string|string[]): PermissionGroup[] {
    const channels = ['all'].concat(channel);

    return this.permissions.groups.filter(permission => {
      if (!permission.channels || permission.channels.length === 0) {
        return false;
      }

      if (channel === 'internal_email') {
        return permission.channels.indexOf(channel) >= 0;
      }
      return permission.channels.some(item => channels.includes(item));
      // return permission.channels.indexOf(channel) >= 0 || permission.channels.indexOf('all') >= 0;
    });
  }

  getPermissionDefault(channel: string): PermissionGroup {
    return this.getPermissions(channel).find(item => item.default === true);
  }

}

interface ViewStatus {
  accepted_terms: boolean;
  contact_information: boolean;
}

export interface ItemGroup {
  id?: any;
  kind: any;
  label: string;
}


// PERMISSIONS ==================================================================

export class PermissionPage {
  name: string;
  label: string;
  content: string;
  confirm_button?: string;
  cancel_button?: string;
}

export class PermissionGroup {
  name: string;
  label: string;
  description: string;
  default: boolean;
  channels: string[];
  pages: PermissionPage[];
}

export class PermissionConfig {
  language: string;
  center_content: string;
  include_content: boolean;
  include_logo: boolean;
  include_link: boolean;
  center_link: string;
  groups: PermissionGroup[];

  constructor(input: any = {}) {
    this.language = input.language || 'en';
    this.center_content = input.center_content || JSON.parse(JSON.stringify(DEFAULT_CENTER_CONTENT));
    this.include_content = input.include_content || false;
    this.include_logo = input.include_logo || false;
    this.include_link = input.include_link || false;
    this.center_link = input.center_link || 'Permission center';
    this.groups = getPermissionGroups(input.groups);
  }
}


// COMPANY ====================================================================

export class CompanyConfig {
  name?: string;
  street1: string;
  street2: string;
  city: string;
  zip: string;
  country: string;
  website: string;
  logo: string;
  primary_phone: string;
  second_phone: string;
  contact_name: string;
  contact_email: string;
  tax_number: string;

  get address(): string {
    const parts = [this.street1, this.city, this.zip, this.country];
    const setParts = parts.filter(part => !!part);
    return setParts.length > 0 ? setParts.join(', ') : 'Unknown';
  }

  constructor(input: any = {}) {
    this.name = input.name || '';
    this.street1 = input.street1 || '';
    this.street2 = input.street2 || '';
    this.city = input.city || '';
    this.zip = input.zip || '';
    this.country = input.country || '';
    this.website = input.website || '';
    this.logo = input.logo || '';
    this.primary_phone = input.primary_phone || '';
    this.second_phone = input.second_phone || '';
    this.contact_name = input.contact_name || '';
    this.contact_email = input.contact_email || '';
    this.tax_number = input.tax_number || '';
  }
}


// EMAIL & SMS =============================================================

export interface IEmailConfig {
  from_name: string;
  from_email: string;
  verified: boolean;
  verified_by?: string;
  verified_at?: string;
  hash?: string;
  salt?: string;
}

export interface ISmsConfig {
  sender_id: string;
  sender_name: string;
  modified_at: string | Date;
}


// EDITOR ==================================================================

const DEFUALT_EDITOR_FONTS: EditorFontsConfig = { showDefaultFonts: true, customFonts: [] };

function initEditor(input): EditorConfig {
  input = input || {};
  const editor: EditorConfig = {
    fonts: input.fonts || DEFUALT_EDITOR_FONTS,
    snippetCategories: input.snippetCategories || DEFAULT_SNIPPET_CATEGORIES
  };
  return editor;
}

export interface EditorConfig {
  fonts: EditorFontsConfig;
  snippetCategories: SnippetCategoryConfig[];
}

export interface EditorFontsConfig {
  showDefaultFonts: boolean;
  customFonts: FontConfig[];
}

export interface FontConfig {
  name: string;
  fontFamily: string;
  url?: string;
}

export interface SnippetCategoryConfig extends ItemGroup {
  id: string;
  kind: HtmlSnippetKind,
  label: string;
}

const DEFAULT_SNIPPET_CATEGORIES: SnippetCategoryConfig[] = [
  { id: 'header', kind: 'email', label: 'Header' },
  { id: 'body', kind: 'email', label: 'Body' },
  { id: 'footer', kind: 'email', label: 'Footer' },
  { id: 'image', kind: 'email', label: 'Image' },
  { id: 'text', kind: 'email', label: 'Text' },
  { id: 'call_to_action', kind: 'email', label: 'Call To Action'},
  { id: 'misc', kind: 'email', label: 'Misc' }
];


// FOLDERS =================================================================

export interface Folder extends ItemGroup {
  id?: number;
  kind: MainModelTypes;
  label: string;
  default?: boolean;
}

const DEFAULT_FOLDERS: Folder[] = [
  { id: 1, kind: 'audience', label: 'Unfiled', default: true },
  { id: 1, kind: 'campaign', label: 'Unfiled', default: true },
  { id: 1, kind: 'template', label: 'Unfiled', default: true }
];


// TAGS ======================================================================

export interface ITagsConfig {
  audiences: string[];
  templates: string[];
  campaigns: string[];
}

export interface IViewConfigIds {
  usergroup_id: string;
  view_id: string;
}


// CONSTANTS ==================================================================

declare const require: any;
const DEFAULT_PERMISSIONS: any = require('@app/configs/default_permissions.json');
const DEFAULT_CENTER_CONTENT: string = DEFAULT_PERMISSIONS.center_content;
const DEFAULT_PERMISSION_GROUPS: PermissionGroup[] = DEFAULT_PERMISSIONS.groups;

export const DEFAULT_PERMISSION_PAGES: PermissionPage[] = [
  {
    name: 'confirmed_subscription',
    label: 'Confirmed Subscription Page',
    content: '<h2>Welcome</h2><p>Thank you for subscribing</p>'
  },
  {
    name: 'unsubscribe',
    label: 'Unsubscribe Page',
    content: '<h2>You are about to unsubscribe</h2><p>Do you really want to unsubscribe?</p>',
    confirm_button: 'Yes, unsubscribe',
    cancel_button: 'No, keep me posted'
  }
];

/**
 * Sets DEFAULT groups (if no groups exist)
 * Otherwise set saved permission groups (and make sure all defaults permissions groups are present)
 * @param inputGroups saved permission groups
 */
const getPermissionGroups = (groups?: PermissionGroup[]): PermissionGroup[] => {
  const defaultGroups = getDefaultPermissionGroups();

  if (!groups) {
    return defaultGroups;
  } else {
    defaultGroups.forEach(defaultGroup => {
      if (!groups.find(group => group.name === defaultGroup.name)) {
        groups.push(defaultGroup);
      }
    });

    return groups;
  }
}


export const getDefaultPermissionGroups = (): PermissionGroup[] => {
  return JSON.parse(JSON.stringify(DEFAULT_PERMISSION_GROUPS));
}

export const getDefaultPermissionPages = (): PermissionPage[] => {
  return JSON.parse(JSON.stringify(DEFAULT_PERMISSION_PAGES));
}

