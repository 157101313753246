import * as crypto from 'randombytes';
import { Component, OnInit, OnDestroy, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppStateService } from '@app/app.service';
import { ViewConfigService } from '@core/services/view-config.service';
import { MainModelTypes } from '@shared/models/index';
import { ViewConfig, Folder, SnippetCategoryConfig, ItemGroup } from '@shared/models/view-config.model';

@Component({
  selector: 'ih-manage-folders-dialog',
  templateUrl: './manage-folders-dialog.component.html',
  styleUrls: ['./manage-folders-dialog.component.sass']
})
export class ManageFoldersDialogComponent implements OnInit, OnDestroy {
  kind: MainModelTypes;
  view: ViewConfig;
  folderEntity: 'folder' | 'category';
  folders: ItemGroup[];
  unfiledFolder: ItemGroup;
  deletedFolders: ItemGroup[] = [];
  validFolders: boolean = false;

  private unsubscribe$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { kind: MainModelTypes },
    public dialogRef: MatDialogRef<ManageFoldersDialogComponent>,
    private el: ElementRef,
    private state: AppStateService,
    private viewConfigService: ViewConfigService
  ) { }


  addFolder() {
    const newFolder: Folder = {
      id: parseInt(crypto(5).join(''), 10),
      kind: this.kind,
      label: ''
    };
    this.validFolders = false;
    this.folders.push(newFolder);
    this.view.folders.push(newFolder);

    this.activateLast();
  }

  removeFolder(index: number) {
    this.deletedFolders.push(this.folders[index]);
    const globalIndex = this.view.folders.findIndex(folder => 
      this.folders[index].id === folder.id && this.folders[index].kind === folder.kind
    );
    this.folders.splice(index, 1);
    this.view.folders.splice(globalIndex, 1);
  }


  uniqueCategoryId(label: string, categories: SnippetCategoryConfig[]) {
    let uniqueId = label.toLowerCase().replace(' ', '_');
    for (let i = 1; categories.findIndex(category => category.id === uniqueId) > -1; i++) {
      uniqueId = label.toLowerCase().replace(' ', '_') + `_${i}`;
    }
    return uniqueId;
  }

  addCategory() {
    const categories: SnippetCategoryConfig[] = this.view.editor.snippetCategories
    const newCategory: SnippetCategoryConfig = {
      id: this.uniqueCategoryId('cat', categories),
      kind: (this.kind.replace('_snippet', '') === 'email') ? 'email' : 'webpage',
      label: ''
    };
    this.validFolders = false;
    this.folders.push(newCategory);
    this.view.editor.snippetCategories.push(newCategory);
    
    this.activateLast();
  }

  removeCategory(index: number) {
    this.deletedFolders.push(this.folders[index]);
    const globalIndex = this.view.editor.snippetCategories.findIndex(category => 
      this.folders[index].id === category.id && this.folders[index].kind === category.kind
    );
    this.folders.splice(index, 1);
    this.view.editor.snippetCategories.splice(globalIndex, 1);
  }


  activateLast() {
    // activate newly added folder/category input field
    const folderIndex = this.folders.length - 1;
    const queryClass = `.folder${folderIndex}`;

    setTimeout(() => {
      const input = this.el.nativeElement.querySelector(queryClass);
      input.focus();
      input.select();
    }, 1);
  }

  validate() {
    this.validFolders = this.folders.every(folder => !!folder.label);
  }

  cancel() {
    this.dialogRef.close();
  }

  update() {
    const message = (this.folderEntity === 'category')
      ? `Snippet categories are updated`
      : `Folders of ${this.kind}s are updated`;
    this.viewConfigService.update(this.view, message);
    this.dialogRef.close(this.deletedFolders);
  }


  subscribeToFolders() {
    this.state.state$('view_config').pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((viewConfig: ViewConfig) => {
        if (viewConfig) {
          this.view = new ViewConfig(JSON.parse(JSON.stringify(viewConfig)));

          if (this.folderEntity == 'folder') {
            this.folders = this.view.getFolders(this.kind);
            this.unfiledFolder = this.folders.find(folder => folder.id === 1);
          } else {
            const snippetType = this.kind.replace('_snippet', '');
            this.folders = (this.view.editor.snippetCategories)
              ? this.view.editor.snippetCategories.filter(category => category.kind == snippetType)
              : [];
            this.unfiledFolder = { id: 'uncategorized', kind: 'email', label: 'Uncategorized' };
          }
          
          this.validate();
        }
      });
  }

  ngOnInit() {
    this.kind = this.data.kind;
    this.folderEntity = (this.kind == 'email_snippet') ? 'category' : 'folder';
    this.subscribeToFolders();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
