import { AppStateService } from '@app/app.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ViewConfigService } from '@core/services/view-config.service';
import { ViewConfig } from '@shared/models/view-config.model';

export class ITags {
  audiences: string[];
  templates: string[];
  campaigns: string[];
}

@Injectable()
export class TagsService implements OnInit, OnDestroy {
  private viewConfig: ViewConfig;
  private tagsBehaviorSubject = new BehaviorSubject<any>(undefined);
  private subs: any[] = [];

  constructor(
    private state: AppStateService,
    private viewConfigService: ViewConfigService
  ) {
    this.ngOnInit();
  }

  /**
   * Returns available tags for a model ()
   *
   * Example:
   *   this.tagsService.getTags( (tags: string[]) => {
   *     this.audiencesTags = tags;
   *   }, "audiences");
   *
   * @param fn
   * @param modelName takes "audiences", "templates", "campaigns"
   */
  getTags(fn, modelName?: string) {
    const sub = this.tagsBehaviorSubject.subscribe((tags: ITags) => {
      if (tags) {
        if (modelName) {
          fn(tags[modelName]);
        } else {
          fn(tags);
        }
      }
    });
    this.subs.push(sub);
  }

  /**
   * Update tags for a particular model
   * New tags (if any) will be saved to the particular ViewConfig
   *
   * Example:
   *   this.tagsService.updateTags(['tag1','tag2'], 'audiences');
   *
   * @param tags array of strings (tags) to add
   * @param modelName name of model to add tags to ("audiences", "templates", "campaigns")
   */
  updateTags(tags: string[], modelName: string): void {
    const newTags = tags
      .map((tag) => tag.toLowerCase())
      .filter((tag) => this.viewConfig.tags[modelName].indexOf(tag.toLowerCase()) < 0);

    if (newTags.length > 0) {
      this.viewConfig.tags[modelName] = this.viewConfig.tags[modelName].concat(newTags).sort();
      this.viewConfigService.update(this.viewConfig);
    }
  }

  /**
   * Saves a NEW list of tags to model
   * @param tags array of strings to be saved as new tags
   * @param modelName name of model to save to (audiences, templates, campaigns)
   */
  saveTags(tags: string[], modelName: string): void {
    const newTags: string[] = tags.map((tag: string) => tag.toLowerCase());
    this.viewConfig.tags[modelName] = newTags;
    this.viewConfigService.update(this.viewConfig);
  }

  /**
   * Initializes tags
   * Subscribes to user's ViewConfig (which contains settings, configs, tags, etc.)
   */
  ngOnInit(): void {
    this.state.state$('view_config')
      .subscribe((viewConfig) => {
        if (viewConfig) {
          this.viewConfig = viewConfig;
          this.tagsBehaviorSubject.next(this.viewConfig.tags);
        }
      });
  }

  ngOnDestroy() {
    this.subs.forEach( (sub: any) => {
      sub.unsubscribe();
    });
  }

}
