import { HttpErrorResponse } from '@angular/common/http';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';

export class IhApiError {
  status: number;
  url: string;
  title: string;
  message: string;
  operation?: string;

  constructor(response: IhApiError | HttpErrorResponse) {
    this.status = response.status || 0;
    this.url = response.url;

    if (response instanceof IhApiError) {
      this.title = response.title;
      this.message = response.message;
      if (response.operation) {
        this.operation = response.operation;
      }

    } else {
      this.title = response.statusText ? response.statusText : 'UNKNOWN SERVER ERROR';
      this.message = (!!response.error && !!response.error.message)
        ? new CapitalizePipe().transform(response.error.message)
        : (response.message 
          ? new CapitalizePipe().transform(response.message)
          : (response.status
            ? `Error ${response.status} - ${this.title.toLowerCase()}`
            : 'Something went wrong')
          );
    }
  }
}