import { Directive, ElementRef, Input, OnInit, OnChanges, Renderer2 } from '@angular/core';
@Directive({
  selector: '[ihMatIcon]'
})
export class IconDirective implements OnChanges {
  @Input() nameId: string;
  @Input() iconName: string;
  @Input() size: number;
  @Input() background: string;

  private iconType: string;
  private iconParts: string[];

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    // this is crap and is only used in admin campaigns which we do not use
    // we should avoid this part
    if (!!this.nameId) {
      this.iconName = this.getIconNameByNameId(this.nameId);
    } 
    
    if (this.iconType == undefined) {
      this.setNewIcon();
    } else {
      this.updateExistingIcon();
    }
  }


  getIconType(iconParts) {
    switch (iconParts[0]) {
      case 'fa':
      case 'fab':
      case 'fas':
      case 'far': 
        return 'fa';
        
      case 'mat':
        return 'mat';

      case 'status': 
        return 'status';
    }
  }


  setNewIcon(iconParts?) {
    let iconType: string;

    if (!!iconParts) {
      iconType = this.getIconType(iconParts);
    } else {
      iconParts = this.iconName.split(' ');
      iconType = this.getIconType(iconParts);
    }
  
    switch (iconType) {
      case 'fa':
        this.newFontAwesomeIcon(iconParts);
        break;

      case 'mat':
        this.newMaterialIcon(iconParts);
        break;

      case 'status':
        iconParts[1] = FLOW_STATUS_ICONS[iconParts[1]];
        this.newMaterialIcon(iconParts);
  
    }

    // set existing iconType and iconParts (in case we need to update the icon)
    this.iconType = iconType;
    this.iconParts = iconParts;

    this.setIconSize();
    this.setColors();

  }


  updateExistingIcon() {
    const iconParts = this.iconName.split(' ');
    const newIconType = this.getIconType(iconParts);

    switch (true) {
      case this.iconType === 'fa':
        this.removeFontAwesomeIcon();
        break;
    
      case ['mat', 'status'].includes(this.iconType):
        this.removeMaterialIcon();
        break;
    }

    this.setNewIcon(iconParts);
  }



  // new icons

  newFontAwesomeIcon(iconParts) {
    this.renderer.setAttribute(this.el.nativeElement, 'fontSet', iconParts[0]);
    this.renderer.setAttribute(this.el.nativeElement, 'fontIcon', iconParts[1]);
    this.renderer.removeClass(this.el.nativeElement, 'material-icons');
    this.renderer.addClass(this.el.nativeElement, 'font-awesome-icons');
    this.renderer.addClass(this.el.nativeElement, iconParts[0]);
    this.renderer.addClass(this.el.nativeElement, iconParts[1]);
  }

  newMaterialIcon(iconParts) {
    this.renderer.addClass(this.el.nativeElement, 'material-icons');
    this.el.nativeElement.innerHTML = iconParts[1];
  }

  // update icons (only for changes)

  updateFontAwesomeIcon(iconParts) {
    this.renderer.removeClass(this.el.nativeElement, this.iconParts[0]);
    this.renderer.removeClass(this.el.nativeElement, this.iconParts[1]);
    this.renderer.addClass(this.el.nativeElement, iconParts[0]);
    this.renderer.addClass(this.el.nativeElement, iconParts[1]);
  }

  updateMaterialIcon(iconParts) {
    this.el.nativeElement.innerHTML = iconParts[1];
  }

  // remove icons (only for changes)

  removeFontAwesomeIcon() {
    this.renderer.setAttribute(this.el.nativeElement, 'fontSet', null);
    this.renderer.setAttribute(this.el.nativeElement, 'fontIcon', null);
    this.renderer.removeClass(this.el.nativeElement, 'font-awesome-icons');
    this.renderer.removeClass(this.el.nativeElement, this.iconParts[0]);
    this.renderer.removeClass(this.el.nativeElement, this.iconParts[1]);
  }

  removeMaterialIcon() {
    this.renderer.removeClass(this.el.nativeElement, 'material-icons');
    this.el.nativeElement.innerHTML = '';
  }

  getIconNameByNameId(nameId) {
    return getFlowIcon(nameId);
  }

  setIconSize() {
    if (!!this.size) {
      switch (this.iconType) {
        case 'fa':
          this.renderer.addClass(this.el.nativeElement, `fa-${this.size}`);    
          break;
        case 'mat':
        case 'status':
          this.renderer.addClass(this.el.nativeElement, `mat-${this.size}`);
          break;
      }
    }
  }

  setColors() {
    if (!!this.background) {
      let color;
      let bgColor;
      switch (this.background) {
        case 'green':
          bgColor = 'bgc-green';
          break;
        case 'white':
          bgColor = 'bgc-page';
          color = '#3B2C36';
          break;
        case 'sidenav':
          bgColor = 'bgc-sidenav-dark';
          break;  
      }
      this.renderer.setStyle(this.el.nativeElement, 'color', color);
      this.renderer.addClass(this.el.nativeElement, bgColor);
    }
  }
}


const FLOW_STATUS_ICONS = {
  draft: 'edit',
  active: 'settings_input_antenna',
  running: 'settings',
  paused: 'pause',
  completed: 'check'
}


export const IconsMap = {
  empty: { label: 'Empty', iconName: 'mat chat_bubble' },
  exec_streaming: { label: 'Behavior', iconName: 'fas fa-user-circle' },
  exec_batch: { label: 'Date and Time', iconName: 'fas fa-clock' },
  abandoned_basket: { label: 'Abandoned basket', iconName: 'mat remove_shopping_cart' },
  product_recommendations: { label: 'All products', iconName: 'mat playlist_add_check' },
  anti_churn: { label: 'Anti churn', iconName: 'far fa-life-ring' },
  giftcard: { label: 'Award giftcard', iconName: 'mat card_giftcard' },
  christmas: { label: 'Christmas', iconName: 'fas fa-tree' },
  cold_lead: { label: 'Cold lead', iconName: 'far fa-meh' },
  competition: { label: 'Competition', iconName: 'fas fa-trophy' },
  customer_activation: { label: 'Customer activation', iconName: 'mat weekend' },
  customer_behaviour: { label: 'Customer behaviour', iconName: 'mat account_box' },
  specific_date: { label: 'Specific date', iconName: 'mat alarm' },
  first_purchase: { label: 'First purchase', iconName: 'mat trending_flat' },
  sale: { label: 'General sale', iconName: 'mat monetization_on' },
  high_inventory: { label: 'High inventory', iconName: 'mat dashboard' },
  new_category: { label: 'New category', iconName: 'mat loyalty' },
  new_event: { label: 'New event', iconName: 'mat event seat' },
  new_lead: { label: 'New lead', iconName: 'far fa-handshake' },
  new_products: { label: 'New products', iconName: 'mat low_priority' },
  product_recommendations_short: { label: 'Post sale', iconName: 'mat attachment' },
  purchase_amount: { label: 'Value purchase', iconName: 'far fa-star' },
  revived_lead: { label: 'Revived lead', iconName: 'fas fa-heartbeat' },
  replenish: { label: 'Replenishment', iconName: 'mat cached' },
  reward: { label: 'Reward loyalty', iconName: 'mat loyalty'},
  signup: { label: 'Welcome', iconName: 'far fa-handshake' },
  tor: { label: 'Terms & conditions', iconName: 'mat gavel' },
}

const iconsMap: Map<string, any> = new Map(Object.entries(IconsMap));

export function getFlowIcon(nameId: string) {
  const icon = iconsMap.get(nameId).iconName;
  return `${icon}`;
}

export function getFlowIconsMap() {
  return new Map(iconsMap);
}

export class IconsSet {
  private static _icons = [
    { label: 'Empty', name: 'empty', icon: 'mat chat_bubble' },
    { label: 'Behavior', name: 'exec_streaming', icon: 'fas fa-user-circle' },
    { label: 'Date and Time', name: 'exec_batch', icon: 'fas fa-clock' },
    { label: 'Abandoned basket', name: 'abandoned_basket', icon: 'mat remove_shopping_cart' },
    { label: 'All products', name: 'product_recommendations', icon: 'mat playlist_add_check' },
    { label: 'Anti churn', name: 'anti_churn', icon: 'far fa-life-ring' },
    { label: 'Award giftcard', name: 'giftcard', icon: 'mat card_giftcard' },
    { label: 'Christmas', name: 'christmas', icon: 'fas fa-tree' },
    { label: 'Cold lead', name: 'cold_lead', icon: 'far fa-meh' },
    { label: 'Competition', name: 'competition', icon: 'fas fa-trophy' },
    { label: 'Customer activation', name: 'customer_activation', icon: 'mat weekend' },
    { label: 'Customer behaviour', name: 'customer_behaviour', icon: 'mat account_box' },
    { label: 'Specific date', name: 'specific_date', icon: 'mat alarm' },
    { label: 'First purchase', name: 'first_purchase', icon: 'mat trending_flat' },
    { label: 'General sale', name: 'sale', icon: 'mat monetization_on' },
    { label: 'High inventory', name: 'high_inventory', icon: 'mat dashboard' },
    { label: 'New category', name: 'new_category', icon: 'mat loyalty' },
    { label: 'New event', name: 'new_event', icon: 'mat event seat' },
    { label: 'New lead', name: 'new_lead', icon: 'far fa-handshake' },
    { label: 'New products', name: 'new_products', icon: 'mat low_priority' },
    { label: 'Post sale', name: 'product_recommendations_short', icon: 'mat attachment' },
    { label: 'Value purchase', name: 'purchase_amount', icon: 'far fa-star' },
    { label: 'Revived lead', name: 'revived_lead', icon: 'fas fa-heartbeat' },
    { label: 'Replenishment', name: 'replenish', icon: 'mat cached' },
    { label: 'Reward loyalty', name: 'reward', icon: 'mat loyalty'},
    { label: 'Welcome', name: 'signup', icon: 'far fa-handshake' },
    { label: 'Terms & conditions', name: 'tor', icon: 'mat gavel' },
  ];

  static get icons() {
    return JSON.parse(JSON.stringify(this._icons));
  }
}
