import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SwitchableItem {
  id: string,
  label: string,
  sublabel?: string,
  description: string | string[],
  icon: string
}

export interface SwitchDialogData {
  title: string,
  info?: string,
  items: SwitchableItem[]
}


@Component({
  selector: 'ih-switch-dialog',
  templateUrl: './switch-dialog.component.html',
  styleUrls: ['./switch-dialog.component.sass']
})
export class SwitchDialogComponent implements OnInit {
  title: string;
  info: string;
  items: SwitchableItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SwitchDialogData,
    public dialogRef: MatDialogRef<SwitchDialogComponent>,
  ) { }

  cancel() {
    this.dialogRef.close();
  }

  selectItem(item: SwitchableItem) {
    this.dialogRef.close(item.id);
  }

  multiline(description: string | string[]): boolean {
    return typeof(description) !== 'string';
  }

  ngOnInit() {
    this.title = this.data.title;
    this.info = this.data.info;
    this.items = this.data.items;
  }

}
