
import { AbilityBuilder, Ability } from '@casl/ability'
import { User, UsergroupAccess, ViewAccess } from '@shared/models/user';
import { UserRolePermission, UserRolePermissionMap, UserRole } from '@shared/models/user_permissions.model';


export function defineAbilitiesFor(user: User, usergroupAccess: UsergroupAccess, viewAccess: ViewAccess) {
  Ability.addAlias('view', ['read']);

  const { can, cannot, rules } = AbilityBuilder.extract();

  let role: string;
  if (!!user && !!viewAccess) {
    role = (user.admin === true) ? 'platform_admin' : viewAccess.role || 'user';
  }

  switch (role) {
    case 'platform_admin':
      can('manage', 'all');
      break;

    case 'view_admin':
      can('manage', 'all');
      cannot('manage', 'PlatformAdmin').because('User is not a platform admin');
      break;

    case 'user':
      setUserAbilities(viewAccess, can, cannot);
      cannot('manage', 'config');
      cannot('manage', 'PlatformAdmin').because('User is not a platform admin');
      break;

    case 'user_restricted_data':
      const restricted_fields = { fields: [{ sales_rep_id: user.email }] };
      can('view', 'profiles', restricted_fields);
      can('view', 'dashboard', restricted_fields);
      cannot('manage', 'config');
      cannot('manage', 'PlatformAdmin').because('User is not a platform admin');
      break;
  }

  return rules;
}


export function createAbility() {
  return new Ability(defineAbilitiesFor(undefined, undefined, undefined), {
    subjectName(subject) {
      if (!subject || typeof subject === 'string') {
        return subject;
      }

      return subject.__typename;
    }
  });
}


/**
 * Sets user's  based on the user's ViewAccess
 * If the user has 'default' permissions (or undefined permissions) 
 */
function setUserAbilities(viewAccess: ViewAccess, can, cannot) {
  if (hasDefaultPermissions(viewAccess.role, viewAccess.permissions)) {
    console.log('set DEFAULT abilities for', viewAccess.role);
    can('manage', 'all');
  } else {
    console.log('set SPECIFIC permissions for', viewAccess.role);
    const defaultPermissions = getDefaultPermissions('user');

    Object.keys(defaultPermissions).forEach(subject => {
      const permission = (!!viewAccess.permissions[subject])
        ? viewAccess.permissions[subject]
        : defaultPermissions[subject];

      can(permission.ability, subject);
      permission.restrictions.forEach(restriction => cannot(restriction, subject));
    });
  }
}


// UTILITY FUNCTIONS

/**
 * Checks if a user's viewAccess has DEFAULT role permissions, meaning that
 * - permissions are undefined
 * - permissions correspond to set DEFAULT_PERMISSIONS
 * @param viewAccess
 */
export function hasDefaultPermissions(role: UserRole, permissions: UserRolePermissionMap<UserRolePermission>): boolean {
  if (!permissions) {
    return true;
  } else if (!!permissions && !!permissions.default) {
    return true;
  } else {
    const isDifferentFromDefault = Object.keys(permissions).some((key: string) => {
      const permission = permissions[key];
      const defaultPermission = getDefaultPermissions(role)[key];
      const isDefault = isDefaultPermission(permission, defaultPermission);
      return (isDefault === false);
    });

    return isDifferentFromDefault === false;
  }
}


/**
 * Checks if a given ability corresponds to the 'defualt' ability
 * @param actual
 * @param defaultAbility
 */
export const isDefaultPermission = (actual: UserRolePermission, defaultPermission: UserRolePermission) => {
  return (actual.ability === defaultPermission.ability && isEqual(actual.restrictions, defaultPermission.restrictions));
};

function isEqual(arr1, arr2) {
  arr1.sort();
  arr2.sort();
  return (
    arr1.length === arr2.length &&
    arr1.every((element, index) => element === arr2[index]));
}


export function getDefaultPermissions(role): UserRolePermissionMap<UserRolePermission> {
  return JSON.parse(JSON.stringify(DEFAULT_PLATFORM_PERMISSIONS[role]));
}


const DEFAULT_USER_PERMISSIONS: UserRolePermissionMap<UserRolePermission> = {
  audiences: { ability: 'manage', restrictions: [] },
  campaigns: { ability: 'manage', restrictions: [] },
  dashboard: { ability: 'manage', restrictions: [] },
  overview: { ability: 'manage', restrictions: [] },
  profiles: { ability: 'manage', restrictions: [] },
  reports: { ability: 'manage', restrictions: [] },
  templates: { ability: 'manage', restrictions: [] },
  snippets: { ability: 'manage', restrictions: [] }
};

// Temporary values
// TODO: Consider and provide correct defaults
const DEFAULT_RESTRICTED_USER_PERMISSIONS: UserRolePermissionMap<UserRolePermission> = {
  audiences: { ability: 'view', restrictions: [] },
  campaigns: { ability: 'none', restrictions: [] },
  dashboard: { ability: 'view', restrictions: [] },
  overview: { ability: 'view', restrictions: [] },
  profiles: { ability: 'manage', restrictions: [] },
  reports: { ability: 'view', restrictions: [] },
  templates: { ability: 'none', restrictions: [] },
  snippets: { ability: 'none', restrictions: [] }
};

const DEFAULT_PLATFORM_PERMISSIONS = {
  user: DEFAULT_USER_PERMISSIONS,
  user_restricted_data: DEFAULT_RESTRICTED_USER_PERMISSIONS
};

export const DEFAULT_GENERAL_PERMISSION: UserRolePermissionMap<UserRolePermission> = {
  default: { ability: 'manage', restrictions: [] }
};
