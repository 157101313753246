import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  session: string;

  setItem(key, property, value) {
    const item = localStorage.getItem(key);

    if (item) { // parse to json if item exists
      const json_item = JSON.parse(item);
      json_item[property] = value;
      localStorage.setItem(key, JSON.stringify(json_item));
    } else { // otherwise add a new item
      const new_item = {};
      new_item[property] = value;
      localStorage.setItem(key, JSON.stringify(new_item));
    }
  }

  getItemProperty(key, property) {
    const item = localStorage.getItem(key);

    if (item) {
      const json_item = JSON.parse(item);
      return json_item[property] || null;
    } else {
      return null;
    }
  }

  getItem(key) {
    const item = localStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }


  deleteItem(key) {
    localStorage.removeItem( key );
    return true;
  }

  deleteItemProperty(key, property) {
    const item = localStorage.getItem(key);
    if (item) {
      const json_item = JSON.parse(item);
      delete json_item[property];
      localStorage.setItem(key, JSON.stringify(json_item));
      return true;
    } else {
      return false;
    }
  }

}
