import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';

const capitalize = new CapitalizePipe();

export class Requirement {
  id: string;
  label: string;
  message_valid: string;
  message_invalid: string;
  is_valid: boolean;
  children: Requirement[];
  data?: any;

  constructor(id: string, validity: boolean, label?: string, message_valid?: string, message_invalid?: string) {
    this.id = id;
    this.label = (label) ? label : id;
    this.message_valid = (message_valid) ? message_valid : (capitalize.transform(this.label));
    this.message_invalid = (message_invalid) ? message_invalid : ('Check ' + this.label);
    this.is_valid = validity;
    this.children = [];
  }

  withData(data: any): Requirement {
    this.data = data;
    return this;
  }

  setValidity(validity: boolean) {
    this.is_valid = validity;
  }

  validate() {
    if (this.children.length > 0) {
      this.is_valid = this.children.every(child => child.is_valid);
    }
  }

  reset() {
    this.is_valid = undefined;
    this.children = [];
  }

  addChild(requirement: Requirement) {
    this.children.push(requirement);
  }

  addChildren(requirements: Requirement[]) {
    this.children.push(...requirements);
  }
}

export interface IValidate<T> {
  /**
   * Validates object and return its requirement
   */
  validate(): Requirement;
}
