import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { TdDialogService } from '@covalent/core/dialogs';
import { Observable } from 'rxjs';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';

@Injectable()
export class DialogService {
  private _entity: string;
  private capitalize = new CapitalizePipe();

  constructor(
    private matDialog: MatDialog,
    private tdDialogService: TdDialogService
  ) {}

  get entity() {
    return this._entity;
  }

  set entity(entity: string) {
    this._entity = entity;
  }

  /**
   * Shows custom alert to user
   * @param title short alert title
   * @param message detailed alert message
   */
  alert(title: string, message: string, closeButtonText: string = 'OK'): Observable<any> {
    return this.tdDialogService
      .openAlert({
        title: title,
        message: message,
        closeButton: closeButtonText
      })
      .afterClosed();
  }

  /**
   * Shows denial alert to user
   * @param action action which is not possible
   * @param reason short message about reason of deny
   * @param item name of item on which action is performed
   * @param explanation additional explanation of deny
   */
  alertDenial(action: string, reason: string, item?: string, explanation?: string): Observable<any> {
    let message = this.entity ? this.capitalize.transform(this.entity) : '';
    message += (item) ? ` "${item}" ` : ` `;
    message += reason;
    message += (explanation) ? `. ${explanation}.` : `.`;
    return this.alert(`Cannot ${action}`, message);
  }

  /**
   * Shows custom confirmation dialog to user
   * @param title short confirmation title
   * @param message detailed confirmation message
   * @param accept label for accept button, "OK" by default
   * @param cancel label for cancel button, "Cancel" by default
   */
  confirm(title: string, message: string, accept: string = 'OK', cancel: string = 'Cancel'): Observable<any> {
    return this.tdDialogService
      .openConfirm({
        title: title,
        message: message,
        acceptButton: accept,
        cancelButton: cancel
      })
      .afterClosed();
  }

  /**
   * Shows confirmation dialog for removing item
   * @param item name of item which will be removed
   * @param completely true if delete completely, false if move to trash
   * @param relative_data data which will be automatically removed with item
   * @param additional_info additional information about removing
   */
  confirmRemoveItem(item: string, completely: boolean, relative_data?: string, additional_info?: string): Observable<any> {
    let message = (completely)
      ? `Are you sure you want to DELETE this ${this.entity}`
      : `Do you want to move this ${this.entity} to trash`;
    message += (relative_data) ? ` and ${relative_data}?` : `?`;
    message += (additional_info) ? ` ${additional_info}.` : ``;

    return (completely)
      ? this.confirm(`Delete "${item}" completely?`, message, 'DELETE')
      : this.confirm(`Move "${item}" to trash?`, message, 'MOVE TO TRASH')
  }

  /**
   * Shows confirmation dialog for array removing
   * @param count number of items to remove
   * @param completely true if delete completely, false if move to trash
   * @param additional_info additional information about removing
   */
  confirmRemoveArray(count: number, completely: boolean, additional_info?: string): Observable<any> {
    let message = (completely) ? `Are you sure you want to DELETE ` : `Do you want to move `;
    message += (count === 1) ? `this ${this.entity} ` : `the selected ${this.entity}s `;
    message += (completely) ? `completely?` : `to trash?`;
    message += (additional_info) ? ` ${additional_info}.` : ``;

    if (count === 1) {
      return (completely)
        ? this.confirm(`Delete 1 ${this.entity}?`, message, 'DELETE')
        : this.confirm(`Move 1 ${this.entity} to trash?`, message, 'MOVE TO TRASH');
    } else {
      return (completely)
        ? this.confirm(`Delete ${count} ${this.entity}s?`, message, 'DELETE ALL')
        : this.confirm(`Move ${count} ${this.entity}s to trash?`, message, 'MOVE TO TRASH');
    }
  }

  /**
   * Shows common delete confirmation dialog to user
   * @param title short confirmation title
   * @param message detailed confirmation message
   */
  confirmDelete(title: string, message: string): Observable<any> {
    return this.confirm(
      title, 
      message, 
      'DELETE'
    );
  }

  /**
   * Shows confirmation dialog for item deletion
   * @param item name of item which will be deleted
   * @param relative_data data which will be automatically deleted with item
   * @param additional_info additional information about deletion
   */
  confirmDeleteItem(item: string, relative_data?: string, additional_info?: string): Observable<any> {
    let message = `Are you sure you want to delete this ${this.entity}`;
    message += (relative_data) ? ` and ${relative_data}?` : `?`;
    message += (additional_info) ? ` ${additional_info}.` : ``;
    return this.confirm(
      `Delete "${item}" ${this.entity}`,
      message, 
      'DELETE'
    );
  }

  /**
   * Shows confirmation dialog for array deletion
   * @param count number of items to delete
   * @param additional_info additional information about deletion
   */
  confirmDeleteArray(count: number, additional_info?: string): Observable<any> {
    if (count === 1) {
      return this.confirm(
        `Delete 1 ${this.entity}`,
        `Are you sure you want to delete this ${this.entity} completely?` + (additional_info ? ` ${additional_info}.` : ``), 
        'DELETE'
      );
    } else {
      return this.confirm(
        `Delete ${count} ${this.entity}s`,
        `Are you sure you want to delete all selected ${this.entity}s completely?` + (additional_info ? ` ${additional_info}.` : ``), 
        'DELETE ALL'
      );
    }
  }

  /**
   * Shows confirmation dialog for trash deleting
   * @param trash_count total number of items in trash
   * @param shown_count number of filtered items shown to the user
   */
  confirmEmptyTrash(trash_count: number, shown_count: number): Observable<any> {
    let message = `Are you sure? `;
    if (trash_count > shown_count) {
      message += (trash_count > 1) ? `Here are ${trash_count} ${this.entity}s ` : `Here is 1 ${this.entity} `;
      message += `in trash (remove filters to see). `;
      message += (trash_count > 1) ? `All of them ` : `It `
    } else {
      message += (trash_count > 1) ? `All these ${trash_count} ${this.entity}s ` : `This ${this.entity} `
    }
    message += `will be deleted irreversibly!`;

    return this.confirm('Empty Trash', message, 'EMPTY')
  }

  /**
   * Shows custom dialog component
   * @param type type of dialog component
   * @param data object with specific data for the dialog
   * @param width dialog width in valid css format, e.g. '100px' or '75%'
   * @param heigth dialog height in valid css format, e.g. '100px' or '75%'
   */
  custom(type: ComponentType<any>, data: any, width?: string, height?: string): Observable<any> {
    const config: MatDialogConfig = {
      data: data,
      panelClass: 'ih-dialog'
    }
    if (width) {
      config.width = width;
    }
    if (height) {
      config.height = height;
    }
    return this.matDialog.open(type, config).afterClosed();
  }
}