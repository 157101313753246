interface IInitialize<T> {
  initialize(input: any): T;
}

export interface PlatformDefaultCollection {
  name: string;
  ids: number[];
}

export interface PlatformDefaultSection {
  name: string;
  collection: PlatformDefaultCollection[];
}


export class PlatformDefault implements IInitialize<PlatformDefault> {
  id: number;
  usergroup_id: string;
  kind: string;
  sections: PlatformDefaultSection[];

  initialize(input?: any) {
    input = input || {};

    if (input && input.id) {
      this.id = input.id;
    }

    this.usergroup_id = input.usergroup_id || 'platform';
    this.kind = input.kind || '';
    this.sections = [];
    if (input.sections && input.sections.length >= 1) {
      input.sections.forEach(section => {
        this.sections.push(<PlatformDefaultSection>section);
      });
    }

    return this;
  }

  equalId(otherDefault: PlatformDefault) {
    return this.usergroup_id === otherDefault.usergroup_id && this.kind === otherDefault.kind;
  }

  findSection(sectionName: string) {
    return (sectionName)
      ? this.sections.find(section => section.name === sectionName)
      : undefined;
  }

  findCollection(sectionName: string, collectionName: string) {
    const section = this.findSection(sectionName);
    return (section && collectionName)
      ? section.collection.find(collection => collection.name === collectionName)
      : undefined;
  }

  getUsedIds(): number[] {
    const usedIds = [];
    this.sections.forEach(section => {
      section.collection.forEach(collection => {
        collection.ids.forEach(id => {
          usedIds.push(id);
        })
      });
    });
    return usedIds;
  }

  /** 
   * Deletes specific item
   * @return true if deleted, false if not found
   */
  deleteItem(itemId: number) {
    let deleted = false;
    this.sections.forEach((section, sectionIndex) => {
      section.collection.forEach((collection, collectionIndex) => {
        const idIndex = collection.ids.findIndex(id => id === itemId);
        if (idIndex > -1) {
          this.sections[sectionIndex].collection[collectionIndex].ids.splice(idIndex, 1);
          deleted = true;
        }
      });
    });
    return deleted;
  }
}
