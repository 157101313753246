import { AppState, INITIAL_APP_STATE } from '@store/app-state.model';
import { createReducer, on, Action } from '@ngrx/store';
import * as Actions from '@app/store/app-state.actions';


export const StateReducer = createReducer(
  INITIAL_APP_STATE,

  on(Actions.setUser, (state, {user}) => newState('user', state, user)),
  on(Actions.setUserPreferences, (state, {preferences}) => newState(['user', 'preferences'], state, preferences)),
  on(Actions.setUsergroupAccess, (state, usergroup) => newState('usergroup', state, usergroup)),
  on(Actions.setViewAccess, (state, view) => newState('view', state, view)),
  on(Actions.setLoginStatus, (state, {login}) => newState('loggedIn', state, login)),
  on(Actions.setLoginGroups, (state, {groups}) => newState('login_groups', state, groups)),

  on(Actions.setViewConfig, (state, {config}) => newState('view_config', state, config)),
  on(Actions.setListSorting, (state, {list_name, option_name}) => reduceListSorting(state, list_name, option_name)),
  on(Actions.setTables, (state, {config}) => newState('tables', state, config)),

  on(Actions.setActivePage, (state, page) => newState('activePage', state, page)),
  on(Actions.setActiveMenu, (state, menu) => newState('activeMenu', state, menu)),
  on(Actions.setActiveFlow, (state, {flow}) => newState('activeFlow', state, flow)),
  on(Actions.setActiveNode, (state, {node}) => newState('activeNode', state, node)),
  on(Actions.registerLoader, (state, {name, id}) => reduceLoader(state, name, id, true)),
  on(Actions.resolveLoader, (state, {name, id}) => reduceLoader(state, name, id, false)),

  // new configs
  on(Actions.setAudienceCategories, (state, {categories}) => newState('audience_categories', state, categories)),
  on(Actions.setAudienceFilters, (state, {filters}) => newState('audience_filters', state, filters)),
  on(Actions.setTriggerCategories, (state, {categories}) => newState('trigger_categories', state, categories)),
  on(Actions.setTriggerFilters, (state, {triggers}) => newState('trigger_filters', state, triggers)),
  on(Actions.setProductCategories, (state, {categories}) => newState('product_categories', state, categories)),
  on(Actions.setProductFilters, (state, {filters}) => newState('product_filters', state, filters))
  
);

// Function calls are not supported in decorators so we use a reducer function.
export function AppStateReducer(state: AppState | undefined, action: Action) {
  return StateReducer(state, action);
}

function reduceListSorting(state: AppState, list_name: string, option_name: string): AppState {
  const listSorting = Object.assign({}, state.list_sorting);
  listSorting[list_name] = option_name;
  return newState('list_sorting', state, listSorting);
}

function reduceLoader(state: AppState, name: string, id: any, loading: boolean): AppState {
  const loaders = Object.assign({}, state.loaders);
  if (id) {
    loaders[name] = Object.assign({}, loaders[name]);
    loaders[name][id] = loading;
  } else {
    loaders[name] = loading;
  }
  return newState('loaders', state, loaders);
}

function newState(property: string | string[], prevState: AppState, payload: any) {
  const nextState = Object.assign({}, prevState);
  if (typeof property === 'string') {
    nextState[property] = payload;
    switch (property) {
      case 'user': nextState.list_sorting = payload.preferences.list_sorting; break;
      case 'usergroup': nextState.usergroupId = payload.id; break;
      case 'view': nextState.userviewId = payload.id; break;
    }
  } else {
    nextState[property[0]][property[1]] = payload;
  }
  return nextState;
}
