import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard'

import { JsonViewerComponent } from './json-viewer.component';
import { JsonViewerDialogComponent } from './json-viewer-dialog/json-viewer-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule
  ],
  declarations: [
    JsonViewerComponent,
    JsonViewerDialogComponent
  ],
  exports: [
    JsonViewerComponent
  ]
})
export class JsonViewerModule { }
