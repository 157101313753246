
import {takeUntil} from 'rxjs/operators';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { AppStateService } from '@app/app.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '@core/services/users.service';
import { User } from '@shared/models/user';
import { LOADERS } from '@shared/models/loaders.model';


@Component({
  selector: 'ih-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.sass']
})
export class ResetPasswordDialogComponent implements OnInit, OnDestroy {
  development: boolean;

  user: User;
  input: any = { password: '', confirm: '' };
  loadingUser: boolean;
  passwordPattern = '^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  private unsubscribe$ = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private state: AppStateService,
    private usersService: UsersService
  ) { }


  togglePasswordPattern() {
    if (this.development) {
      this.passwordPattern === '^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        ? this.passwordPattern = ''
        : this.passwordPattern = '^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
    }
  }

  changePassword() {
    const updates = [{ op: 'replace', field: 'password', value: this.input.password }];
    const userLoader = LOADERS.user;
    this.state.registerLoader(userLoader);
    this.usersService.patch$(this.user.id, updates)
      .subscribe(
        (user: User) => {
          this.state.setUser(user);
          this.dialogRef.close(true);
        },
        (error: any) => console.error(error),
        () => this.state.resolveLoader(userLoader)
      );
  }

  subscribeToLoaders() {
    this.state.state$('loaders').pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((loading) => this.loadingUser = loading[LOADERS.user]);
  }


  ngOnInit() {
    this.development = (environment.production === false);

    this.subscribeToLoaders();
    this.user = this.data.user;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
