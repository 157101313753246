import { Condition } from '@shared/models/condition.model';

export class ConditionCategory {
  name: string;
  table: string;
  table_link: string;
  label: string;
  position: number;
  relation: 'and'|'or';
  is_valid: boolean;
  conditions: Condition[];
  subcategories?: ConditionCategory[];
  subcategories_join?: 'include'|'exclude';
  remove?: boolean;

  constructor(input: any = {}) {
    this.name = input.name;
    this.table = input.table;
    this.table_link = input.table_link;
    this.label = input.label;
    this.position = input.position || 0;
    this.relation = input.relation || 'and';
    this.is_valid = input.is_valid || false;

    this.conditions = 
      (input.conditions)
      ? input.conditions.map(condition => new Condition(condition))
      : [];
    if (input.subcategories) {
      this.subcategories = input.subcategories.map(sub => new ConditionCategory(sub));
      this.subcategories_join = input.subcategories_join || 'include';
    }
  }

  toggleRelation() {
    this.relation = this.relation === 'and' ? 'or' : 'and';
  }

  toggleJoining() {
    this.subcategories_join = this.subcategories_join === 'include' ? 'exclude' : 'include';
  }

  validate() {
    const validConditions = this.conditions.every(condition => condition.is_valid === true);
    const validSubcategories = 
      (!!this.subcategories && this.subcategories.length > 0)
      ? this.subcategories.every(subcategory => subcategory.is_valid === true)
      : true;
    this.is_valid = validConditions && validSubcategories;
  }

  addCondition(input: any, after?: number): Condition {
    const condition = new Condition(input);
    (after !== undefined && this.conditions.length > after + 1)
      ? this.conditions.splice(after + 1, 0, condition)
      : this.conditions.push(condition);
    this.updatePositions();
    return condition;
  }

  removeCondition(position: number) {
    if (position !== undefined && this.conditions.length > position) {
      this.conditions.splice(position, 1);
    }
    this.updatePositions();
  }

  addSubcategory(input: any, after?: number): ConditionCategory {
    if (!this.subcategories) {
      this.subcategories = [];
      this.subcategories_join = 'include';
    }

    const subcategory = new ConditionCategory(input);
    (after !== undefined && this.subcategories.length > after + 1)
      ? this.subcategories.splice(after + 1, 0, subcategory)
      : this.subcategories.push(subcategory);
    this.updatePositions();
    return subcategory;
  }

  removeSubcategory(position: number) {
    if (position !== undefined && !!this.subcategories && this.subcategories.length > position) {
      this.subcategories.splice(position, 1);
    }
    this.updatePositions();
  }

  cleanSubcategories() {
    this.subcategories = undefined;
    this.subcategories_join = undefined;
    this.validate();
  }

  private updatePositions() {
    this.conditions.forEach((condition, index) => condition.position = index);
    if (!!this.subcategories) {
      this.subcategories.forEach((subcategory, index) => subcategory.position = index);
    }
    this.validate();
  }

}
