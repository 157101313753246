import { NgModule } from '@angular/core';

import { DraggableDirective } from './draggable.directive';
import { FlowBackgroundDirective } from './flow-background.directive';
import { IconDirective } from './icon.directive';
import { PluralizeDirective } from './pluralize.directive';
import { TemplateBindDirective } from './template-bind.directive';

@NgModule({
  declarations: [
    DraggableDirective,
    FlowBackgroundDirective,
    IconDirective,
    PluralizeDirective,
    TemplateBindDirective
  ],

  exports: [
    DraggableDirective,
    FlowBackgroundDirective,
    IconDirective,
    PluralizeDirective,
    TemplateBindDirective
  ]
})
export class DirectivesModule{}
