import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app-state.model';
import { setLoginStatus, setLoginGroups } from '@store/app-state.actions';

// import { StorageService } from '@core/services/storage.service';
import { LoggerService } from '@core/services/logger.service';
import { AuthService, Authentication } from '@core/services/auth.service';
import { Ability } from '@casl/ability';
import { defineAbilitiesFor } from '@app/core/guards/ability.service';
import { Usergroup } from '@app/shared/models/usergroup';

@Injectable()
export class PlatformGuard implements CanActivate {

  constructor(
    private ability: Ability,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    // private storageService: StorageService,
    private logger: LoggerService,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {

    return this.authService.authenticateUser().pipe(
      map((response: Authentication) => {
        if (response.authenticated) {
          // if we get response from backend it has authorized
          // usergroup_id and view_id and we need to activate these
          if (response.usergroup_id && response.view_id) {
            this.activateUser(response);
            this.redirectIfAdmin(response);
          }

          const groups = response.usergroups
            ? response.usergroups.map(usergroup => new Usergroup(usergroup))
            : [];
          this.store.dispatch(setLoginGroups(groups));
          this.store.dispatch(setLoginStatus({ login: true }));

          return true;
        }

        // this.deactivateUser();
        // this.router.navigateByUrl('/login');
        this.authService.logout();

        return false;
      }));
  }


  activateUser(response) {
    this.logger.log('Activate user', response.user, response.usergroup_id, response.view_id);
    const user = this.authService.setUser(response.user);
    const usergroup = this.authService.setCurrentUsergroup(response.usergroup_id);
    const view = this.authService.setCurrentUserView(response.usergroup_id, response.view_id);
    this.ability.update(defineAbilitiesFor(user, usergroup, view));
    console.log('Abilities', this.ability.rules);
  }

  redirectIfAdmin(response) {
    if (['admin', 'admin_dev'].includes(response.usergroup_id)) {
      this.router.navigateByUrl('/admin');
    }
  }

}

