import { PLATFORM_ROUTES, PlatformRoute } from '@shared/constants/platform_routes';
import { ViewAccess } from '@shared/models/user';
import { UserRolePermission, UserRolePermissionMap, PermissionRestrictionItem } from '@shared/models/user_permissions.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';

import { getDefaultPermissions, hasDefaultPermissions, isDefaultPermission } from '@app/core/guards/ability.service';


@Component({
  selector: 'ih-user-role-ability-dialog',
  templateUrl: './user-role-ability-dialog.component.html',
  styleUrls: ['./user-role-ability-dialog.component.sass']
})
export class UserRoleAbilityDialogComponent implements OnInit, AfterViewInit {
  disableAnimation = true;

  routes: PlatformRoute[] = [];
  currentRoute: PlatformRoute;

  view: ViewAccess;
  defaultPermissions: UserRolePermissionMap<UserRolePermission>;
  permissions: UserRolePermissionMap<UserRolePermission>;
  permissionOptions = PERMISSION_OPTIONS;
  permissionLabels = PERMISSION_LABELS;
  restrictionOptions: PermissionRestrictionItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { view: ViewAccess },
    public dialogRef: MatDialogRef<UserRoleAbilityDialogComponent>
  ) { }


  setCurrentRoute(route: PlatformRoute) {
    this.currentRoute = route;
    this.restrictionOptions = RESTRICTION_OPTIONS[route.value];
    this.updatePermission(route);
  }


  /**
   * Checks the updated permission
   * If 'none' was chosen we reset the 'restrictions' array
   */
  updatePermission(route: PlatformRoute) {
    if (this.permissions[route.value].ability === 'none') {
      this.permissions[route.value].restrictions = [];
      this.restrictionOptions = undefined;
    } else {
      this.restrictionOptions = RESTRICTION_OPTIONS[route.value];
    }
  }

  /**
   * adds a 'restriction' value to user's permission restrictions if slider is checked and
   */
  updateRestrictions(slider: MatSlideToggleChange, route: PlatformRoute, item) {
    if (slider.checked) {
      if (!this.permissions[route.value].restrictions.includes(item.ability)) {
        this.permissions[route.value].restrictions.push(item.ability);
      }
    } else {
      this.permissions[route.value].restrictions = this.permissions[route.value].restrictions.filter(x => x !== item.ability);
    }

    this.permissions[route.value].restrictions = this.permissions[route.value].restrictions.filter(x => ![undefined, null, ''].includes(x));
  }

  /**
   * checks chosen permissions with default permissions for the given user/view role
   * returns only permissions that differ from default
   */
  getFinalPermissions() {
    if (hasDefaultPermissions(this.view.role, this.permissions)) {
      return { default: { ability: 'default', restrictions: [] } };
    } else {
      const finalPermissions = {};

      Object.keys(this.defaultPermissions).forEach(key => {
        if (!!this.permissions[key] && isDefaultPermission(this.permissions[key], this.defaultPermissions[key])) {
          finalPermissions[key] = this.defaultPermissions[key];
        } else {
          finalPermissions[key] = this.permissions[key];
        }
      });

      return finalPermissions;
    }
  }


  close() {
    this.dialogRef.close();
  }


  select() {
    const finalPermissions = this.getFinalPermissions();
    console.log('returning permissions', finalPermissions);
    this.dialogRef.close(finalPermissions);
  }


  /**
   * Sets permission to 'default' unless it is already set for given key
   */
  initializePermissions(view: ViewAccess): UserRolePermissionMap<UserRolePermission> {
    const permissions = view.permissions || {};
    delete permissions['default'];

    Object.keys(this.defaultPermissions).forEach(key => {
      if (!permissions[key]) {
        permissions[key] = this.defaultPermissions[key];
      }
    });

    return permissions;
  }

  // workaround for initially expanded panels
  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
  }


  ngOnInit() {
    this.view = JSON.parse(JSON.stringify(this.data.view));
    this.defaultPermissions = getDefaultPermissions(this.view.role);
    this.permissions = this.initializePermissions(this.view);
    this.routes = PLATFORM_ROUTES.filter(route => !!this.defaultPermissions[route.value]);
    
    console.log(`Permissions for ${this.view.role}`, this.permissions);
  }

}

const PERMISSION_LABELS = {
  manage: 'Full access',
  view: 'View access',
  none: 'No access'
};

const PERMISSION_OPTIONS = [
  { ability: 'manage', label: 'Full access' },
  { ability: 'view', label: 'View acces'},
  { ability: 'none', label: 'No access'},
];

const RESTRICTION_OPTIONS = {
  audiences: [
    { ability: 'download', label: 'Prevent user from downloading audience data' },
    { ability: 'view_data', label: 'Prevent user from viewing audeince data' }
  ]
};
