import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RawUsergroupConfig } from '@shared/models/usergroup-config.model';
import { AppStateService } from '@app/app.service';
import { Observable } from 'rxjs';


@Injectable()
export class UsergroupConfigService {

  constructor(
    private state: AppStateService,
    private http: HttpClient
  ) { }

  getAsync(): Promise<RawUsergroupConfig> {
    const ids = this.state.getIds();
    return this.http.get<RawUsergroupConfig>(`/usergroup_config/${ids.usergroup}`).toPromise();
  }

  config$(usergroupId: string): Observable<RawUsergroupConfig> {
    return this.http.get<RawUsergroupConfig>(`/usergroup_config/${usergroupId}`);
  }

  configValue$(kind: string, config: string, key: string): Observable<any> {
    return this.http.get(`/usergroup_config/${kind}/${config}/${key}`);
  }
}