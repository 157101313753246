import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService } from '@app/app.service';
import { ViewConfigService } from '@core/services/view-config.service';
import { StorageService } from '@core/services/storage.service';
import { LoggerService } from '@core/services/logger.service';
import { LOADERS } from '@shared/models/loaders.model';
import { User, UsergroupAccess, ViewAccess } from '@shared/models/user';
import { Usergroup } from '@shared/models/usergroup';


export interface Authentication {
  user: User;
  authenticated: boolean;
  usergroup_id?: string;
  view_id?: string;
  usergroups?: Usergroup[];
}

@Injectable()
export class AuthService {
  _currentUser: User;
  _currentUsergroup: UsergroupAccess;
  _currentView: ViewAccess;

  _isLoggedIn = new BehaviorSubject<boolean>(false);

  loggedIn = false;

  get currentUser() { return this._currentUser; }
  get currentUsergroup() { return this._currentUsergroup; }
  get currentUsergroupId() { return this._currentUsergroup.id; }
  get currentView() { return this._currentView; }
  get currentViewId() { return this._currentView.id; }
  get isLoggedIn() { return this._isLoggedIn.asObservable(); }

  constructor(
    private state: AppStateService,
    private httpClient: HttpClient,
    private logger: LoggerService,
    private storageService: StorageService,
    private viewConfigService: ViewConfigService,
    private router: Router
  ) { }

  setUser(user: User): User {
    this._currentUser = new User(user);
    this.logger.log('User', this._currentUser);
    this.storageService.setItem('innohead_login', 'user', this.currentUser.email);
    this.state.setUser(this.currentUser);
    this._isLoggedIn.next(true);
    return this.currentUser;
  }

  /**
   * Set user's current usergroup access
   * @param id of usergroup (if no id set primary or first)
   */
  setCurrentUsergroup(id?: string): UsergroupAccess {
    this._currentUsergroup = this._currentUser.getUsergroupAccess(id);
    this.storageService.setItem('innohead_access', 'usergroup', this.currentUsergroup.id);
    this.state.setUsergroupAccess(this.currentUsergroup);
    this.logger.info('Usergroup set', id, this.currentUsergroup);
    return this.currentUsergroup;
  }

  /**
   * Sets user's current UserView Access by id (in dim_user_1)
   * if no id, the id is obtained from the user's primary (or first) UserView
   * the currentView's id and "name" is always set,
   * and then re-populated with fresh data (uncommented for now)
   */
  setCurrentUserView(usergroupId?: string, stringId?: string): ViewAccess {
    usergroupId = usergroupId || this.currentUsergroupId;
    this._currentView = this._currentUser.getViewAccess(usergroupId, stringId);
    this.storageService.setItem('innohead_access', 'userview', this.currentView.id);
    this.state.setViewAccess(this.currentView);
    this.logger.info('Userview set', usergroupId, stringId, this.currentView);
    this.setViewConfig();
    return this.currentView;
  }

  setViewConfig() {
    this.viewConfigService.get(this.currentUsergroupId, this.currentViewId);
  }


  /**
   * Checks if a user is logged with previous session
   */
  checkIfLoggedIn() {
    const login = this.storageService.getItem('innohead_login');
    const access = this.storageService.getItem('innohead_access');

    if (login && access && Object.keys(access).length !== 0) {
      this._isLoggedIn.next(true);
    } else {
      this._isLoggedIn.next(false);
    }
  }

  /**
   * Login user
   * @param userInput email and password
   */
  login(userInput): Observable<User> {
    return this.httpClient
      .post('/login', userInput).pipe(map((response: any) => {
        if (response.user) {
          this.state.registerLoader(LOADERS.setupPlatform);

          // this.loggedIn = true;
          const changePass = response.changePass;
          const token = response.token;
          const user = response.user;
          this.storageService.setItem('innohead_login', 'change_pass', changePass);
          this.storageService.setItem('innohead_login', 'token', token);

          this.setUser(user);
          this.setCurrentUsergroup();
          this.setCurrentUserView();

          return this.currentUser;
        } else {
          return undefined;
        }
      }));
  }

  resetPassword(email: string): Observable<boolean> {
    const payload = { 'email': email, 'op': 'reset_password' };
    return this.httpClient.patch<boolean>('/login', payload);
  }

  authenticateUser(): Observable<Authentication> {
    // standard response
    const login: Authentication = {
      authenticated: false,
      user: undefined
    };

    // user is already logged in
    if (this.state.getState('loggedIn')) {
      login.authenticated = true;
      login.user = new User(this.state.getState('user'));
      login.usergroups = <Usergroup[]>this.state.getState('login_groups')
        .map(group => new Usergroup(group));
      return new Observable(observer => observer.next(login));

    // user is not logged in, but may have a user session
    } else {
      const user = this.getStoredUser();
      const usergroupId = this.getStoredUsergroup();
      const viewId = this.getStoredUserView();

      // require login if either user, usergroupId or viewId is missing
      const requireLogin = (!user || !usergroupId || !viewId);
      if (requireLogin) {
        return new Observable(observer => observer.next(login));
      }

      // user has session, but we need to check it against backend
      return this.httpClient.get<Authentication>(`/login?usergroup_id=${usergroupId}&view_id=${viewId}`);
    }
  }


  getToken() {
    return this.storageService.getItemProperty('innohead_login', 'token');
  }

  getStoredUser() {
    return this.storageService.getItemProperty('innohead_login', 'user');
  }

  getStoredUsergroup() {
    return this.storageService.getItemProperty('innohead_access', 'usergroup');
  }

  getStoredUserView() {
    return this.storageService.getItemProperty('innohead_access', 'userview');
  }


  logout(): void {
    this.logger.log('Loggin out user');
    this._isLoggedIn.next(false);
    this.state.setLoginStatus(false);
    this.router.navigate(['login']);
    this.storageService.deleteItem('innohead_login');
    this.storageService.deleteItem('innohead_access');
  }
}

