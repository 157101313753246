<section class="page-content">
  <ng-container *ngIf="policy$ | async as policy; else loadingOrError">
    <div class="markdown" [innerHTML]="policy | markdown"></div>
  </ng-container>
  <ng-template #loadingOrError>

    <ng-container *ngIf="error; else loading">
      <div class="markdown" [innerHTML]="errorMessage | markdown"></div>
    </ng-container>
    
    <ng-template #loading>
      Loading ...
    </ng-template>

  </ng-template>
</section>
