<div mat-dialog-title>
  Password Reset
</div>

<mat-dialog-content class="no-actions">
  <h3 class="mat-title space-top-0">
    <strong>Please reset your password</strong>
  </h3>
  <p>Choose a new password with at least 8 characters and containing a number (0-9) and a special character (e.g. !@#$%)</p>
  <p *ngIf="development && user.admin">
    <mat-slide-toggle color="primary" [checked]="true" (click)="togglePasswordPattern()">
      Pattern is {{ passwordPattern === '' ? 'off' : 'on' }}
    </mat-slide-toggle>
  </p>

  <form class="login-form" #passwordForm="ngForm">
    <mat-form-field class="full-width">
      <input id="password" matInput #password="ngModel"
             placeholder="New password"
             type="password"
             name="password"
             focused required
             [(ngModel)]="input.password"
             validateEqual="confirmPassword"
             reverse="true"
             [pattern]="passwordPattern">
      <mat-hint align="end">
        <span class="tc-red-600" [hidden]="!password.errors?.required || password.pristine">
          Passworld required
        </span>
        <span class="tc-red-600" [hidden]="!password.errors?.pattern || password.pristine">
          Must have at least 8 characters, one number and one special character
        </span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input id="password-confirm" matInput #confirmPassword="ngModel"
             placeholder="Confirm new password"
             type="password"
             name="confirmPassword"
             [(ngModel)]="input.confirm"
             required
             validateEqual="password"
             reverse="false">
      <mat-hint align="end">
        <span class="tc-red-600" [hidden]="!confirmPassword.errors?.required || confirmPassword.pristine">
          Required
        </span>
        <span class="tc-red-600 space-left-5" [hidden]="confirmPassword.pristine || (input.password === input.confirm)">
          Password mismatch
        </span>
      </mat-hint>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="end center" class="space-top-10">
      <button mat-raised-button color="primary" type="button" (click)="changePassword()" [disabled]="!passwordForm.form.valid || (input.password !== input.confirm) || loadingUser">
        Change password
      </button>
      <span *ngIf="loadingUser">
        <i class="fa fa-spinner fa-pulse space-right-10"></i>
        Please wait
      </span>
    </div>
  </form>
</mat-dialog-content>
