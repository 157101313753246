// CATEGORY CONFIG

export class CategoryConfig {
  name: string;
  execution_type?: string;
  icon: string;
  table?: string;
  table_link?: string;
  label: string;
  filters: {name: string}[];
  expanded?: boolean;

  constructor(config: any = {}, key?: string) {
    this.name = key || config.name;
    this.execution_type = config.execution_type;
    this.icon = config.icon;
    this.table = config.table;
    this.table_link = config.table_link;
    this.label = (!!config.label)
      ? config.label
      : this.name.replace(/^\w/, c => c.toUpperCase()).replace(/_/g, ' ');
    this.filters = config.filters;
  }

}


// FILTER TYPES & INTERFACES

export type FilterFieldType = 'float' | 'integer' | 'string' | 'boolean' | 'date';

export type FilterFieldUnit = 'dkk' | 'items' | 'days' | 'year';

export interface FilterGroups { 
  values?: { label: string, value: any, independent?: boolean }[];
  load?: { source: string, value: string, dest?: string };
}

export interface FilterOptions {
  list_options?: { value: string, label?: string }[]; // somehow refactor this (used only for lists)
  values?: { value: string, label?: string }[];
  dates?: { date?: boolean, past?: boolean, future?: boolean, default?: '<' | '<=' | '=' | '>=' | '>' |'<=' | 'between' | '!beteeen' };
  range?: { min: number, max: number, unit: string };
  match?: { regex?: string };
  load?: { source: string, value: string, dest?: string };
  generate?: any;
  defaults?: any;
}

export interface FilterSearch {
  start: string;
  query: string;
  end: string;
}


// FILTER CONFIG

export class FilterConfig {
  name: string;
  field: string;
  field_type: FilterFieldType;
  field_unit: FilterFieldUnit;
  kind: string;
  label: string;
  location: string;
  pattern?: string[];
  affix?: string;
  options?: FilterOptions;
  groups?: FilterGroups;
  refinements?: {name: string}[];
  dependencies?: {[key: string]: any[]};
  search?: FilterSearch;
  event_type?: string;

  constructor(config: any = {}, key?: string, location?: string) {
    this.name = key || config.name;
    this.field = config.field || config.name;
    this.field_type = config.field_type || 'string';
    this.field_unit = config.field_unit;
    this.kind = this.getKind(config);

    this.label = !!config.label
      ? config.label
      : this.name.replace(/^\w/, c => c.toUpperCase()).replace(/_/g, ' ');
    this.location = location || config.location;
    
    this.pattern = config.pattern,
    this.affix = config.affix,
    this.options = config.options;
    this.groups = config.groups;

    this.refinements = config.refinements;
    this.dependencies = config.dependencies;
    this.event_type = config.event_type;
  }

  /**
   * If kind is given, return kind
   * If kind is not given, base kind on field_type
   */
  getKind(config): string {
    if (!!config.kind) {
      return config.kind;
    }

    switch (this.field_type) {
      case 'date': return 'date';
      case 'string': return 'string';
      case 'integer': return 'number';
      case 'float': return 'number';
    }
  }

  getGroups(): FilterGroups {
    return this.groups ? JSON.parse(JSON.stringify(this.groups)) : undefined;
  }

  getOptions(): FilterOptions {
    return this.options ? JSON.parse(JSON.stringify(this.options)) : undefined;
  }

  getFocusField(): string {
    if (this.pattern) {
      const focus = this.pattern.join('_');
      return focus.replace('group', 'horizon').replace('affix', this.affix).replace('field', this.field)
    } else {
      return this.field;
    }
  }

  getDefaultOperator() {
    const standard = DEFAULT_OPERATOR[this.kind];
    const operator = 
      (!!this.options && !!this.options.defaults && !!this.options.defaults.operator)
      ? this.options.defaults.operator
      : standard || '';
    return JSON.parse(JSON.stringify(operator));
  }

  getDefaultValue() {
    const standard = DEFAULT_VALUE[this.kind];
    const value = 
      (!!this.options && !!this.options.defaults && !!this.options.defaults.value)
      ? this.options.defaults.value
      : (!!standard && typeof standard === 'function') ? standard(this.options) : (standard || '');
    return JSON.parse(JSON.stringify(value));
  }

  getDefaultGroup(): string | number {
    return (!!this.groups && !!this.groups.values && this.groups.values.length > 0)
      ? this.groups.values[0].value
      : '';
  }

  isDefaultGroupIndependent(): boolean {
    return (!!this.groups && !!this.groups.values && this.groups.values.length > 0)
      ? this.groups.values[0].independent || false
      : false;
  }

  searchLabel(query: string): boolean {
    const start = this.label.toLowerCase().search(query.toLowerCase());
    if (start > -1) {
      const end = start + query.length;
      this.search = {start: this.label.substring(0, start), query: this.label.substring(start, end), end: this.label.substring(end)}
      return true;
    }
    this.search = undefined;
    return false;
  }

}


// CONSTANTS

const DEFAULT_OPERATOR = {
  'date': { value: '>', time: 'past' },
  'calendar_trigger': 'is',
  'category': 'any',
  'number': '>',
  'threshold_trigger': '>',
  'range': 'values',
  'string': 'contains',
  'wait': 'send_delay',
  'response': 'triggered'
}

const DEFAULT_VALUE = {
  'boolean': { checked: true, match: '' },
  'date': { min: '', max: '', time: 'past', time_unit: 'days' },
  'calendar_trigger': (options: FilterOptions) => {
    const initDate = new Date();
    initDate.setDate(initDate.getDate() + 1);
    initDate.setHours(15, 0, 0, 0);
    return !!options && !!options.values && options.values.length > 0
      ? { date: initDate, time_value: '15:00', time_zone: '', repeat: { every: 1, freq: 'day' } }
      : { date: initDate, time_value: '15:00', time_zone: '' }
  },
  'campaign': { campaign: undefined, action: { specified: false, type: '', id: '' } },
  'number': { min: '', max: '', unit: '' },
  'threshold_trigger': { min: '', max: '', unit: '' },
  'random': 0,
  'range': { min: '', max: '' },
  'wait': { delay_value: '3', delay_unit: 'days' },
  'response': 'triggered'
}

export const WAIT_FILTER_CONFIG = new FilterConfig({ 
  name: 'wait_action', 
  kind: 'wait', 
  field: 'action', 
  field_type: 'integer', 
  label: 'Wait' 
});

export const RESPONSE_FILTER_CONFIG = new FilterConfig({ 
  name: 'response_action', 
  kind: 'response', 
  field: 'action', 
  field_type: 'string', 
  label: 'Action'
});
