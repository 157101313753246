import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, INITIAL_APP_STATE, ActivePage } from '@app/store/app-state.model';

import { INITIAL_LOADERS } from '@shared/models/loaders.model';

import { User, UserPreferences, UsergroupAccess, ViewAccess } from '@shared/models/user';
import { TablesConfig } from '@shared/models/usergroup-config.model';
import { ViewConfig } from '@shared/models/view-config.model';
import { Flow } from '@shared/models/flow.model';
import { FlowNode } from '@shared/models/node.model';
import { PlatformIds } from '@shared/models/platform.model';
import { PlatformUsergroupConfig } from '@shared/models/usergroup-config.model';

import * as Actions from '@app/store/app-state.actions';


@Injectable()
export class AppStateService {
  private appState$: BehaviorSubject<AppState> = new BehaviorSubject<AppState>(INITIAL_APP_STATE);

  constructor(private store: Store<AppState>) {
    this.store.dispatch(Actions.setLoginStatus({login: false}));
    this.store.select(state => state);
    this.store.subscribe((appstate) => {
      this.appState$.next(appstate);
    });
  }

  getStoreSubject(): BehaviorSubject<AppState> {
    return this.appState$;
  }

  /**
   * Returns an observable of the uiState
   * @param property of uiState item
   *
   * Example:
   * state$('activeNode').subscribe((activeNode) => ...)
   */
  state$(property?: string) {
    return this.store.select((state: AppState) =>
      property
         ? state['AppState'][property]
         : state['AppState']
    );
  }

  /**
   * Returns an observable of filtered non-empty values
   * @param property of uiState item
   */
  definedState$(property?: string) {
    return this.state$(property).pipe(filter(value => value !== undefined && value !== null));
  }

  /**
   * Returns an observable of specific loader
   * @param name loader name
   */
  loader$(name: string, hasId: boolean = false) {
    return this.store.select((state: AppState) => state['AppState']['loaders'][name])
      .pipe(startWith(hasId ? {} : true));
  }

  /**
   * Returns current State value of property
   * @param property e.g. loggedIn, user, usergroupId, userviewId
   */
  getState(property: string) {
    return this.appState$.value['AppState'][property];
  }

  /**
   * Get usergroupId, viewId and user ids for api calls
   */
  getIds(): PlatformIds {
    const usergroupId: string = this.getState('usergroupId');
    const viewId: string = this.getState('userviewId');
    const user: User = this.getState('user');
    return { usergroup: usergroupId, view: viewId, user: (user ? user.id : '') };
  }

  getViewConfig(): ViewConfig {
    return this.getState('view_config');
  }


  // login functions

  setUser(user: User) {
    this.store.dispatch(Actions.setUser(user));
  }

  setUserPreferences(preferences: UserPreferences) {
    this.store.dispatch(Actions.setUserPreferences(preferences));
  }

  setUsergroupAccess(usergroup: UsergroupAccess) {
    this.store.dispatch(Actions.setUsergroupAccess(usergroup));
  }

  setViewAccess(view: ViewAccess) {
    this.store.dispatch(Actions.setViewAccess(view));
  }

  setLoginStatus(status: boolean) {
    this.store.dispatch(Actions.setLoginStatus({login: status}));
  }


  // config functions

  setViewConfig(config: ViewConfig) {
    this.store.dispatch(Actions.setViewConfig(config));
  }

  

  setPlatformConfig(configName: string, config: PlatformUsergroupConfig ) {
    switch (configName) {
      case 'audiences':
        this.store.dispatch(Actions.setAudienceCategories(config.categories));
        this.store.dispatch(Actions.setAudienceFilters(config.filters));
        break;

      case 'flows':
        this.store.dispatch(Actions.setTriggerCategories(config.categories));
        this.store.dispatch(Actions.setTriggerFilters(config.filters));
        break;
      
      case 'products':
        this.store.dispatch(Actions.setProductCategories(config.categories));
        this.store.dispatch(Actions.setProductFilters(config.filters));
        break;
    }
  }

  setListSorting(payload: { list_name: string, option_name: string }) {
    this.store.dispatch(Actions.setListSorting(payload));
  }

  setTables(config: TablesConfig) {
    this.store.dispatch(Actions.setTables(config));
  }


  // platform functions

  setActivePage(page: ActivePage) {
    this.store.dispatch(Actions.setActivePage(page));
  }

  setActiveMenu(context: string, item: string, index?: number) {
    const menu = (index === undefined) 
      ? { context: context, item: item } 
      : { context: context, item: item, index: index };
      this.store.dispatch(Actions.setActiveMenu(menu));
  }

  setActiveFlow(flow: Flow) {
    this.store.dispatch(Actions.setActiveFlow(flow));
  }

  setActiveNode(node: FlowNode) {
    this.store.dispatch(Actions.setActiveNode(node));
  }

  registerLoader(name: string, id?: any) {
    console.assert(Object.keys(INITIAL_LOADERS).indexOf(name) >= 0, `Registration of loader '${name}' not allowed`);
    this.store.dispatch(Actions.registerLoader({ name: name, id: id }));
    return name;
  }

  resolveLoader(name: string, id?: any) {
    console.assert(Object.keys(INITIAL_LOADERS).indexOf(name) >= 0, `Registration of loader '${name}' not allowed`);
    this.store.dispatch(Actions.resolveLoader({ name: name, id: id }));
  }


  // other helpers

  clone(input): any {
    return JSON.parse(JSON.stringify(input));
  }

}