import { ValidationService } from '@core/services/validation.service';


interface IInitialize<T> {
  initialize(input: any): T;
}


export class NodeSchedule implements IInitialize<NodeSchedule> {
  operator: 'send_now'|'send_at'|'send_delay';
  time_value: string;
  time_zone: string;
  delay_value: string;
  delay_unit: 'minutes'|'hours'|'days';
  days: string[];
  months: string[];
  is_valid?: boolean;

  checks = [];


  initialize(input?: any) {
    input = input || {};

    this.operator = input.operator || 'send_now';
    this.time_value = input.time_value || '15:00';
    this.time_zone = '';
    this.delay_value = input.delay_value || '3';
    this.delay_unit = input.delay_unit || 'days';
    this.days = input.days || [];
    this.months = input.months || [];

    input.is_valid === undefined
      ? this.validate()
      : this.is_valid = input.is_valid;

    return this;
  }

  validate() {
    this.checks = [];
    const validator = new ValidationService();

    switch (this.operator) {
      case 'send_now':
        this.is_valid = true;
        break;

      case 'send_at':
        this.checks.push(validator.isValidTime(this.time_value));
        break;

      case 'send_delay':
        if (this.delay_unit === 'days') {
          this.checks.push(validator.isPositiveInteger(this.delay_value));
          this.checks.push(validator.isValidTime(this.time_value));
        } else {
          this.checks.push(validator.isValidInteger(this.delay_value));
        }
        break;

      default:
        this.is_valid = false;
        break;
    }

    this.is_valid = this.checks.every(item => item === true);
  }

}

