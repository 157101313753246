import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface NamedItem {
  id: string,
  name: string
}

@Component({
  selector: 'ih-name-dialog',
  templateUrl: './name-dialog.component.html',
  styleUrls: ['./name-dialog.component.sass'],
})
export class NameDialogComponent implements OnInit {
  name: string;
  namesSet: NamedItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {structure: string, name: string, names_set?: NamedItem[]},
    public dialogRef: MatDialogRef<NameDialogComponent>,
  ) { }

  cancel() {
    this.dialogRef.close();
  }

  selectName() {
    this.dialogRef.close(this.name);
  }

  ngOnInit() {
    this.name = this.data.name;
    console.log(this.data);
    if (this.data.names_set !== undefined) {
      this.namesSet = this.data.names_set;
    }
  }

}
