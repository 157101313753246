import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

// user roles and permissions
import { Ability } from '@casl/ability';
import { createAbility } from '@app/core/guards/ability.service';

import { AppCustomPreloader } from './app-preloading-strategy';
import { AppStateService } from './app.service';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PublicComponent } from '@public/public.component';
import { LoginComponent } from '@public/login/login.component';
import { HomeComponent } from '@public/home/home.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from '@shared/shared.module';
import { StoreModule, RuntimeChecks } from '@ngrx/store';
import { AppStateReducer } from '@app/store/app-state.reducer';
import { ResetPasswordDialogComponent } from '@shared/components/reset-password-dialog/reset-password-dialog.component';
import { ForgotPasswordDialogComponent } from '@public/login/forgot-password-dialog/forgot-password-dialog.component';
import { SelectViewDialogComponent } from '@shared/components/select-view-dialog/select-view-dialog.component';
import { LegalComponent } from './public/legal/legal.component';
import { PolicyComponent } from './public/legal/policy/policy.component';


const checks: RuntimeChecks = {
  strictStateImmutability: false,
  strictActionImmutability: false,
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictActionWithinNgZone: false
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    MatMomentDateModule,
    RouterModule,
    StoreModule.forRoot({AppState: AppStateReducer}, {runtimeChecks: checks}),
    SharedModule,
    NgxMaterialTimepickerModule
  ],
  declarations: [
    AppComponent,
    ForgotPasswordDialogComponent,
    HomeComponent,
    LoginComponent,
    PublicComponent,
    LegalComponent,
    PolicyComponent,
    ResetPasswordDialogComponent,
    SelectViewDialogComponent,
  ],
  providers: [
    Title,
    AppCustomPreloader,
    AppStateService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: Ability, useFactory: createAbility }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
